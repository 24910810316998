import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalId } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { journalTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { journalChildEditDatesErrorParser } from 'utils/errorHandlers/journalChild/journalChildEditDatesErrorParser';

type ChildrenTabNetworkManageProps = {
    journalId: JournalId;
    onChildPatchDatesRequestFinish: () => void;
};

export const useJournalChildrenPageNetworkManage = ({
    journalId,
    onChildPatchDatesRequestFinish,
}: ChildrenTabNetworkManageProps) => {
    const { displaySuccess, displayError } = useSimpleNotification();

    const {
        data: fetchedChildrenData,
        isInitialLoading: isChildrenDataInitialLoading,
        isRefetching: isChildrenDataRefetching,
        isError: isChildrenDataError,
    } = useAppQuery(
        'JOURNAL_CHILDREN',
        [journalId],
        () => StaffHTTPService.journals.children.getJournalChildren(journalId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { mutate: patchChildDates, isLoading: isPatchChildDatesLoading } = useAppMutation(
        StaffHTTPService.journals.children.patchJournalChildDates,
        {
            key: ['CHANGE_JOURNAL_CHILD_DATES'],
            onSuccess: () => {
                onChildPatchDatesRequestFinish();
                displaySuccess(
                    journalTexts.journalDetails.common.children.successfullyChangeChildDates,
                );
            },
            onError: (error) => {
                onChildPatchDatesRequestFinish();
                displayError(journalChildEditDatesErrorParser(error));
            },
            invalidateQueryKeys: [
                ['JOURNAL_CHILDREN', journalId],
                ['JOURNAL_CHILDREN_PARENTS', journalId],
                ['CHILDREN'],
                ['GROUPS'],
            ],
        },
    );

    return {
        childrenData: fetchedChildrenData || [],
        isChildrenDataInitialLoading,
        isChildrenDataRefetching,
        isPatchChildDatesLoading,
        isChildrenDataError,
        patchChildDates,
    };
};
