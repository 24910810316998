import { AxiosError } from 'axios';
import { AppForm, AppFormProps } from 'components/organisms';
import { CommonFormProps, FormModeDependedTexts, QualificationFormFields } from 'types';
import useForm from 'antd/es/form/hooks/useForm';
import { commonTexts, journalTexts } from 'consts/text';
import { IconDeviceFloppy } from '@tabler/icons-react';
import {
    AttachmentsUploadElement,
    DescriptionInputElement,
    FormNavButtons,
    PsychologicalHelpBasisPickerElement,
    SimpleTextAreaElement,
} from 'components/molecules';
import { formFields } from 'consts/form/formFields';
import { AppFormDetails } from 'components/atoms';
import { max3000LengthRule } from 'consts/rules/textRules';
import { QUALIFICATION_BASIS_ATTACHMENT_FORMATS } from 'consts/file/fileFormats';

type QualificationAddEditFormProps = Omit<
    AppFormProps<QualificationFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        childFullName?: string;
        onFinish: (qualificationData: QualificationFormFields) => void;
    };
const addModeTexts: FormModeDependedTexts = {
    name: 'qualificationAdd',
    title: journalTexts.journalDetails.specialJournal.qualifications.addQualificationTitle,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'qualificationEdit',
    title: journalTexts.journalDetails.specialJournal.qualifications.editQualificationTitle,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};
export const QualificationAddEditForm = ({
    form,
    mode,
    axiosError,
    childFullName,
    ...props
}: QualificationAddEditFormProps) => {
    const [formInstance] = useForm(form);
    const isAddMode = mode === 'add';
    const qualificationAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    return (
        <AppForm<QualificationFormFields>
            form={formInstance}
            name={name}
            title={title}
            error={qualificationAddEditError}
            withGoBack
            maxWidth={440}
            {...props}
        >
            <AppFormDetails
                label={
                    journalTexts.journalDetails.specialJournal.qualifications
                        .qualificationFullNameInputLabel
                }
                value={childFullName}
            />
            <PsychologicalHelpBasisPickerElement />
            <DescriptionInputElement maxLengthRule={max3000LengthRule} />
            <AttachmentsUploadElement
                uploadDirectory="QUALIFICATION_BASIS"
                accept={QUALIFICATION_BASIS_ATTACHMENT_FORMATS}
            />
            <SimpleTextAreaElement
                label={
                    journalTexts.journalDetails.specialJournal.qualifications
                        .individualTherapyPlanInputLabel
                }
                name={formFields.therapyProgram}
                rules={[max3000LengthRule]}
            />
            <SimpleTextAreaElement
                label={commonTexts.dataLabels.comments}
                name={formFields.notes}
                rules={[max3000LengthRule]}
            />
            <SimpleTextAreaElement
                label={
                    journalTexts.journalDetails.specialJournal.qualifications
                        .analysisOfProgressInputLabel
                }
                name={formFields.progressAnalysis}
                rules={[max3000LengthRule]}
            />

            <FormNavButtons buttonText={buttonText} leftIcon={buttonIcon} withPlus={isAddMode} />
        </AppForm>
    );
};
