import { AxiosError } from 'axios';
import { IconDeviceFloppy } from '@tabler/icons-react';
import {
    AttachmentsUploadElement,
    FormNavButtons,
    SimpleDatePicker,
    SimpleInputElement,
    SimpleTextAreaElement,
} from 'components/molecules';
import { AppForm, AppFormProps, ReceiversPicker } from 'components/organisms';
import { CommonFormProps, FormModeDependedTexts, RemoteLessonsFormFields } from 'types';
import { commonTexts, remoteLessonsTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { AppFormBanner } from 'components/atoms';
import { max2000LengthRule, max300LengthRule } from 'consts/rules/textRules';
import { MESSAGE_ATTACHMENT_FORMATS } from 'consts/file/fileFormats';

export type RemoteLessonAddEditFormProps = Omit<
    AppFormProps<RemoteLessonsFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (remoteLessonData: RemoteLessonsFormFields) => void;
    };

const addModeTexts: FormModeDependedTexts = {
    name: 'remoteLessonAdd',
    title: remoteLessonsTexts.addRemoteLesson,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'remoteLessonEdit',
    title: remoteLessonsTexts.editRemoteLesson,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const RemoteLessonAddEditForm = ({
    mode,
    axiosError,
    ...props
}: RemoteLessonAddEditFormProps) => {
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const journalAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<RemoteLessonsFormFields>
            title={title}
            maxWidth={480}
            name={name}
            error={journalAddEditError}
            withGoBack
            {...props}
        >
            {!isAddMode && (
                <AppFormBanner mode="WARNING" text={remoteLessonsTexts.editRemoteLessonWarning} />
            )}
            <SimpleInputElement
                label={remoteLessonsTexts.lessonFormLabels.name}
                name={formFields.name}
                rules={[max300LengthRule]}
            />
            <SimpleDatePicker
                formItemLabel={remoteLessonsTexts.lessonFormLabels.date}
                name={formFields.date}
                isHalfParentWidth
            />
            <ReceiversPicker />
            <SimpleTextAreaElement
                label={remoteLessonsTexts.lessonFormLabels.description}
                name={formFields.description}
                rules={[max2000LengthRule]}
            />
            <SimpleTextAreaElement
                label={remoteLessonsTexts.lessonFormLabels.homeworkDescription}
                name={formFields.homeworkDescription}
                rules={[max2000LengthRule]}
                optional
                hasOptionalText
            />
            <SimpleDatePicker
                optional
                formItemLabel={remoteLessonsTexts.lessonFormLabels.homeworkDateTo}
                name={formFields.homeworkDeadline}
                isHalfParentWidth
            />
            <AttachmentsUploadElement
                uploadDirectory="MESSAGE"
                accept={MESSAGE_ATTACHMENT_FORMATS}
                appFormItemLabel={commonTexts.dataLabels.attachmentsToDownload}
            />
            <FormNavButtons buttonText={buttonText} leftIcon={buttonIcon} withPlus={isAddMode} />
        </AppForm>
    );
};
