import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { useCurrentChild } from 'jotaiAtoms/currentChild';
import {
    commonIdsIndependentQueryKeys,
    institutionIdIndependentQueryKeys,
} from 'services/reactQuery/idParamIndependentQueryKeys';
import { QueryMainKeysType } from './queryMainKeysType';
import { ApiErrorType } from './apiErrorType';

type UseQueryOptionsEx = {
    invalidateQueryKeys?: QueryKey[];
};

type QueryOptions<TData> = UseQueryOptionsEx & UseQueryOptions<TData, ApiErrorType>;

export const useAppQuery = <ExpectedQueryResponse extends AxiosResponse>(
    mainKey: QueryMainKeysType,
    additionalKeys: QueryKey,
    queryFn: QueryFunction<ExpectedQueryResponse, QueryKey>,
    options?: QueryOptions<ExpectedQueryResponse['data']>,
): UseQueryResult<ExpectedQueryResponse['data'], ApiErrorType> => {
    const { id: currentInstitutionId } = useCurrentInstitution();
    const { id: childId, institutionAddress: childInstitutionAddress } = useCurrentChild();

    const { institutionId: childInstitutionId } = childInstitutionAddress;

    const queryKey = useMemo(() => {
        if (currentInstitutionId) {
            const isInstitutionIdIndependentQuery =
                mainKey === institutionIdIndependentQueryKeys.GUS_INFORMATION ||
                mainKey === commonIdsIndependentQueryKeys.USER_ACTIVE;
            return isInstitutionIdIndependentQuery
                ? [mainKey, ...additionalKeys]
                : [currentInstitutionId, mainKey, ...additionalKeys];
        }
        if (childId) {
            const isIdsIndependentQuery = mainKey === commonIdsIndependentQueryKeys.USER_ACTIVE;
            if (isIdsIndependentQuery) {
                return [mainKey, ...additionalKeys];
            }
            return [childInstitutionId, childId, mainKey, ...additionalKeys];
        }
        return [mainKey, ...additionalKeys];
    }, [additionalKeys, childId, currentInstitutionId, mainKey, childInstitutionId]);

    const wrapFunc: QueryFunction<ExpectedQueryResponse, QueryKey> = (props) => queryFn(props);

    return useQuery<ExpectedQueryResponse, ApiErrorType>(queryKey, wrapFunc, {
        ...options,
        // Commented after caching was added
        // refetchOnWindowFocus: import.meta.env.VITE_APP_ENV !== 'DEV',
        select(response) {
            return response.data;
        },
        // Retry only once on failure
        retry: options?.retry ?? 1,
    });
};
