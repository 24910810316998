import { AdditionalLessonsFormFields, AdditionalLessonDTO } from 'types';

export const parseAdditionalLessonDTOIntoAdditionalLessonsFormFields = (
    additionalLessonDTO: AdditionalLessonDTO,
): AdditionalLessonsFormFields => {
    const { paymentType, free, settlementType, price, vatRateType } = additionalLessonDTO;

    return {
        additionalLessonPaymentType: paymentType,
        free: free || false,
        settlementType,
        price,
        vatRateType,
    };
};
