import { settlementTexts } from 'consts/text';

export enum VerifySettlementsModalMode {
    ONE_SETTLEMENT = 'ONE_SETTLEMENT',
    MULTIPLE_SETTLEMENTS = 'MULTIPLE_SETTLEMENTS',
}

type VerifySettlementsModalTexts = {
    titleFirstPart: string;
    description: string;
};

export const ONE_SETTLEMENT_TEXTS: VerifySettlementsModalTexts = {
    titleFirstPart:
        settlementTexts.children.verifySettlements.singleSettlement.verifySettlementTitleFirstPart,
    description: settlementTexts.children.verifySettlements.singleSettlement.verifySettlementText,
};

export const MULTIPLE_SETTLEMENTS_TEXTS: VerifySettlementsModalTexts = {
    titleFirstPart:
        settlementTexts.children.verifySettlements.multipleSettlements
            .verifySettlementsTitleFirstPart,
    description:
        settlementTexts.children.verifySettlements.multipleSettlements.verifySettlementsText,
};
