import { FunctionComponent, SVGProps } from 'react';
import { SelectProps } from 'antd';
import { useTheme } from 'styled-components';
import { IconChevronDown } from '@tabler/icons-react';
import { StyledXIcon } from 'components/atoms/StyledIcons';
import { AppText } from 'components/atoms/CommonAppComponents/AppText/AppText';
import { InnerContainer, GlobalStyle, StyledSelect, OuterContainer } from './AppSelect.styled';
import { TagRender } from './components/TagRender/TagRender';
import { AppLoader } from '../AppLoader/AppLoader';

export type AppSelectProps = SelectProps & {
    label?: string;
    isFitContentWidth?: boolean;
    Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
};

export const AppSelect = ({
    label,
    Icon,
    loading,
    isFitContentWidth,
    optionFilterProp = 'label',
    mode,
    suffixIcon,
    ...props
}: AppSelectProps) => {
    const theme = useTheme();

    const parsedSuffixIcon = loading ? (
        <AppLoader size={20} />
    ) : (
        suffixIcon || <IconChevronDown color={theme.colors.grayscale.gray8} />
    );

    const selectBase = (
        <InnerContainer>
            <GlobalStyle />
            {Icon && <Icon width={20} height={20} />}
            <StyledSelect
                tagRender={TagRender}
                maxTagCount="responsive"
                optionFilterProp={optionFilterProp}
                suffixIcon={parsedSuffixIcon}
                allowClear={{ clearIcon: <StyledXIcon /> }}
                loading={loading}
                isFitContentWidth={isFitContentWidth}
                mode={mode}
                {...props}
            />
        </InnerContainer>
    );

    return label ? (
        <OuterContainer isFitContentWidth={isFitContentWidth}>
            <AppText margin={0} textType="BodyMSSemiBold">
                {label}
            </AppText>
            {selectBase}
        </OuterContainer>
    ) : (
        selectBase
    );
};
