import { Navigate, RouteObject } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import {
    DietsAddPage,
    DietsEditPage,
    DietsPage,
    PrincipalEditPage,
    RolesAndPermissionsEditPage,
    RolesAndPermissionsPage,
    SettlementMealsEditPage,
    RolesAndPermissionsAddPage,
    SettlementOverallEditPage,
    HistoryOfChangesPage,
    InstitutionDetailsPage,
    SettlementStaysEditPage,
    InvoiceDetailsPage,
    AccountPackagePage,
    PaymentsPage,
    SettlementVatRatesEditPage,
} from 'pages';
import { SettingsLayout } from 'components/layouts/SettingsLayout/SettingsLayout';
import { STAFF_APP_MODULES } from 'consts/modules/staffModules';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { AbilityRoute } from 'navigation/AbilityRoute/AbilityRoute';
import { SettingsSettlementLayout } from 'components/layouts';

export const settingsRoutes: RouteObject[] = [
    {
        path: appPaths.app.settings.base,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.VIEW_MODULE,
                    subject: STAFF_APP_MODULES.SETTINGS,
                }}
            >
                <SettingsLayout />
            </AbilityRoute>
        ),
        children: [
            {
                path: appPaths.app.settings['institution-details'].base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.INSTITUTION_DATA,
                        }}
                    >
                        <InstitutionDetailsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings['principal-details'],
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.PRINCIPAL_SETTINGS,
                        }}
                    >
                        <PrincipalEditPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings['invoice-details'].base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.PAYMENTS_SETTINGS,
                        }}
                    >
                        <InvoiceDetailsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings.settlements.base,
                element: <SettingsSettlementLayout />,
                children: [
                    {
                        path: appPaths.app.settings.settlements.general.base,
                        element: <SettlementOverallEditPage />,
                    },
                    {
                        path: appPaths.app.settings.settlements.stays.base,
                        element: <SettlementStaysEditPage />,
                    },
                    {
                        path: appPaths.app.settings.settlements.meals.base,
                        element: <SettlementMealsEditPage />,
                    },
                    {
                        path: appPaths.app.settings.settlements['vat-rates'].base,
                        element: <SettlementVatRatesEditPage />,
                    },
                ],
            },
            {
                path: appPaths.app.settings.diets.base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.DIET_SETTINGS,
                        }}
                    >
                        <DietsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings['roles-and-permissions'].base,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.VIEW,
                            subject: PERMISSIONS_NAME.ROLES_AND_PERMISSIONS,
                        }}
                    >
                        <RolesAndPermissionsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings['history-of-changes'],
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.CHANGES_HISTORY,
                        }}
                    >
                        <HistoryOfChangesPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings.payments,
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.PAYMENTS_SETTINGS,
                        }}
                    >
                        <PaymentsPage />
                    </AbilityRoute>
                ),
            },
            {
                path: appPaths.app.settings['your-account-type'],
                element: (
                    <AbilityRoute
                        can={{
                            action: AppActions.DO_ACTION,
                            subject: PERMISSIONS_NAME.PAYMENTS_SETTINGS,
                        }}
                    >
                        <AccountPackagePage />
                    </AbilityRoute>
                ),
            },
        ],
    },
    {
        path: appPaths.app.settings['roles-and-permissions'].edit.pickedRole,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.ROLES_AND_PERMISSIONS,
                }}
            >
                <RolesAndPermissionsEditPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.settings['roles-and-permissions'].add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.ROLES_AND_PERMISSIONS,
                }}
            >
                <RolesAndPermissionsAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.settings['roles-and-permissions'].edit.base,
        element: <Navigate to={appPaths.app.settings['roles-and-permissions'].base} />,
    },
    {
        path: appPaths.app.settings.diets.add,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.DIET_SETTINGS,
                }}
            >
                <DietsAddPage />
            </AbilityRoute>
        ),
    },
    {
        path: appPaths.app.settings.diets.edit.pickedDiet,
        element: (
            <AbilityRoute
                can={{
                    action: AppActions.EDIT_AND_ADD,
                    subject: PERMISSIONS_NAME.DIET_SETTINGS,
                }}
            >
                <DietsEditPage />
            </AbilityRoute>
        ),
    },
];
