import { GUSInformationDTO } from 'types';
import { InvoiceEditFormFields } from 'types/forms/invoice';
import { parseGUSStreetDataIntoStreetFormField } from 'utils/parsers/gus/parseGUSStreetDataIntoStreetFormField';

export const parseGUSInformationDTOIntoIInvoiceEditFormFields: (
    gusData: GUSInformationDTO,
) => Partial<InvoiceEditFormFields> = (gusData) => {
    const { name, mailCity, city, zipCode, street, buildingNumber, premisesNumber } = gusData;

    const parsedStreetFormFieldValue = parseGUSStreetDataIntoStreetFormField({
        street,
        buildingNumber,
        premisesNumber,
    });

    return {
        name,
        city: city || mailCity,
        zipCode,
        street: parsedStreetFormFieldValue,
    };
};
