import { ChildBillComponent, MonthlySettlementSimpleDTO, StaySettlement } from 'types';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { mealMapper } from 'consts/settlementChildren/mealMapper';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { settlementTexts } from 'consts/text';
import { getSettlementStaysHoursAmountString } from 'utils/getSettlementStaysHoursAmountString';
import { getFormattedGrossCostWithVatAmount } from 'utils/getFormattedGrossCostWithVatAmount';

const parseMealSettlement = ({
    mealSettlement,
}: Pick<MonthlySettlementSimpleDTO, 'mealSettlement'>): ChildBillComponent[] => [
    {
        id: 'meal',
        name: settlementTexts.children.columnLabels.cateringAndDiet,
        amountString: getFormattedGrossCostWithVatAmount(
            mealSettlement.grossCost,
            mealSettlement.costVatAmount,
        ),
        discountString: mealSettlement.discounts
            .map((discount) => `${discount.name} ${getFormattedPrice(discount.amount)}`)
            .join(', '),
        children: mealSettlement.mealCosts.length
            ? mealSettlement.mealCosts.map((settlement, index) => ({
                  id: settlement.mealType + index,
                  name: mealMapper[settlement.mealType],
                  amountString: `${settlement.count} x ${getFormattedPrice(
                      settlement.grossPrice,
                  )} (${getFormattedPrice(settlement.totalGrossPrice)})`,
                  discountString: settlement.discounts
                      .map((value) => `${value.name} ${value.amount}%`)
                      .join(', '),
              }))
            : undefined,
    },
];

const FREE_HOURS_KEY = 'freeHours';
const PAID_HOURS_KEY = 'paidHours';
const FREE_HOURS_PRICE = 0;

const parseStaySettlementPriceSummariesIntoChildBillComponent: (
    staySettlementPriceSummaries: Pick<
        StaySettlement,
        'daysPriceSummaries' | 'hoursPriceSummaries' | 'freeHours'
    >,
) => ChildBillComponent[] | undefined = (staySettlementPriceSummaries) => {
    const { hoursPriceSummaries, daysPriceSummaries, freeHours } = staySettlementPriceSummaries;
    if (daysPriceSummaries?.length) {
        return daysPriceSummaries.map((settlement, index) => ({
            id: `${settlement.fromDate}-${settlement.toDate}${index}`,
            name: `${parseStringIntoFormattedStringDate(
                settlement.fromDate,
                'YYYY-MM-DD',
                'DD/MM/YYYY',
            )} - ${parseStringIntoFormattedStringDate(
                settlement.toDate,
                'YYYY-MM-DD',
                'DD/MM/YYYY',
            )}`,
            amountString: getFormattedPrice(settlement.grossPriceWithPercentageDiscounts),
            discountString: settlement.staySettlementPercentageDiscounts
                .map((discount) => `${discount.discountName} ${discount.percentage}%`)
                .join(', '),
        }));
    }
    if (hoursPriceSummaries?.length) {
        const paidChildBillsComponents = hoursPriceSummaries.map((settlement, index) => ({
            id: `${PAID_HOURS_KEY}-${index}`,
            name: settlementTexts.stays.paidHours,
            amountString: getSettlementStaysHoursAmountString(
                settlement.paidHours,
                settlement.pricePerHourWithDiscounts,
                settlement.totalPriceWithDiscounts,
            ),
            discountString: settlement.discounts
                .map((discount) => `${discount.discountName} ${discount.percentage}%`)
                .join(', '),
        }));
        if (!freeHours) {
            return paidChildBillsComponents;
        }
        const freeChildBillsComponent = {
            id: FREE_HOURS_KEY,
            name: settlementTexts.stays.freeHours,
            amountString: getSettlementStaysHoursAmountString(
                freeHours,
                FREE_HOURS_PRICE,
                FREE_HOURS_PRICE,
            ),
        };
        return [freeChildBillsComponent].concat(paidChildBillsComponents);
    }
    return undefined;
};

const parseStaySettlement = ({
    staySettlement,
}: Pick<MonthlySettlementSimpleDTO, 'staySettlement'>): ChildBillComponent[] => [
    {
        id: 'stay',
        name: settlementTexts.children.columnLabels.stay,
        amountString: getFormattedGrossCostWithVatAmount(
            staySettlement.grossCost,
            staySettlement.costVatAmount,
        ),
        discountString: staySettlement.staySettlementNotPercentageDiscounts
            .map((discount) => `${discount.discountName} ${getFormattedPrice(discount.amount)}`)
            .join(', '),
        children: parseStaySettlementPriceSummariesIntoChildBillComponent({
            hoursPriceSummaries: staySettlement.hoursPriceSummaries,
            daysPriceSummaries: staySettlement.daysPriceSummaries,
            freeHours: staySettlement.freeHours,
        }),
    },
];

const parseLessonSettlement = ({
    lessonSettlement,
}: Pick<MonthlySettlementSimpleDTO, 'lessonSettlement'>): ChildBillComponent[] => [
    {
        id: 'lesson',
        name: settlementTexts.children.columnLabels.additionalLessons,
        amountString: getFormattedGrossCostWithVatAmount(
            lessonSettlement.grossCost,
            lessonSettlement.costVatAmount,
        ),
        discountString: lessonSettlement.discounts
            .map((discount) => `${discount.name} ${getFormattedPrice(discount.amount)}`)
            .join(', '),
        children: lessonSettlement.lessonCosts.length
            ? lessonSettlement.lessonCosts.map((settlement, index) => ({
                  id: settlement.courseId + index,
                  name: settlement.count
                      ? settlement.name
                      : `${settlement.name}: ${parseStringIntoFormattedStringDate(
                            settlement?.startDate as string,
                            'YYYY-MM-DD',
                            'DD/MM/YYYY',
                        )} - ${parseStringIntoFormattedStringDate(
                            settlement?.endDate as string,
                            'YYYY-MM-DD',
                            'DD/MM/YYYY',
                        )}`,
                  amountString: settlement?.count
                      ? `${settlement?.count} x ${getFormattedPrice(
                            settlement.grossPrice,
                        )} (${getFormattedPrice(settlement.totalGrossPrice)})`
                      : getFormattedPrice(settlement.totalGrossPrice),
                  discountString: settlement.discounts
                      .map((discount) => `${discount.name} ${discount.amount}%`)
                      .join(', '),
              }))
            : undefined,
    },
];

export const parseObjectsIntoSettlementArray = ({
    mealSettlement,
    lessonSettlement,
    staySettlement,
}: Pick<MonthlySettlementSimpleDTO, 'lessonSettlement' | 'mealSettlement' | 'staySettlement'>):
    | ChildBillComponent[]
    | null => {
    if (
        mealSettlement.mealCosts.length ||
        lessonSettlement.lessonCosts.length ||
        staySettlement.daysPriceSummaries?.length ||
        staySettlement.hoursPriceSummaries?.length
    ) {
        const mealSettlementArray = parseMealSettlement({ mealSettlement });
        const lessonSettlementArray = parseLessonSettlement({ lessonSettlement });
        const staySettlementArray = parseStaySettlement({ staySettlement });
        return [...mealSettlementArray, ...lessonSettlementArray, ...staySettlementArray];
    }
    return null;
};
