import { QueryMainKeysType } from 'services/reactQuery/queryMainKeysType';

type CommonIdIndependentQueryKey = Extract<QueryMainKeysType, 'USER_ACTIVE'>;

type InstitutionIdIndependentQueryKey = Extract<QueryMainKeysType, 'GUS_INFORMATION'>;

export const commonIdsIndependentQueryKeys: Record<
    CommonIdIndependentQueryKey,
    CommonIdIndependentQueryKey
> = {
    USER_ACTIVE: 'USER_ACTIVE',
};

export const institutionIdIndependentQueryKeys: Record<
    InstitutionIdIndependentQueryKey,
    InstitutionIdIndependentQueryKey
> = {
    GUS_INFORMATION: 'GUS_INFORMATION',
};
