import { AppFormItem, AppFormItemProps, AppInput } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { commonTexts } from 'consts/text';
import { nurseryRegisterNumberRegExp } from 'consts/regexps';

export const NurseryRegisterNumberInputElement = (props: AppFormItemProps) => (
    <AppFormItem
        label={commonTexts.dataLabels.nurseryRegistryNumber}
        name={formFields.nurseryRegistryNumber}
        rules={[
            {
                pattern: nurseryRegisterNumberRegExp,
                message: commonTexts.errorMessages.invalidNurseryRegistryNumber,
            },
        ]}
        {...props}
    >
        <AppInput autoComplete="off" />
    </AppFormItem>
);
