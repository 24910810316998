import { useEffect } from 'react';
import { appPaths } from 'consts/paths/paths';
import { InstitutionAddEditForm } from 'components/organisms';
import { ErrorDisplay, FormNavButtons } from 'components/molecules';
import { InstitutionAddEditFormFields } from 'types';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { parseInstitutionAddFormFieldsIntoInstitutionCreateData } from 'utils/parsers/institution/parseInstitutionAddFormFieldsIntoInstitutionCreateData';
import { useCurrentInstitution } from 'jotaiAtoms/currentInstitution';
import { institutionAddErrorHandler } from 'utils/errorHandlers/institution/institutionAdd';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { parseInstitutionCreateDataIntoInstitutionAddFormFields } from 'utils/parsers/institution/parseInstitutionCreateDataIntoInstitutionAddFormFields';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts, settingsTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const InstitutionDetailsEditPage = () => {
    const { id: currentInstitutionId } = useCurrentInstitution();
    const { form, formInitialData, setFormData } = useEditForm<InstitutionAddEditFormFields>();

    const { statefulNavigate } = useNavigateWithState();
    const { displaySuccess } = useSimpleNotification();

    const onSuccessNavigate = () =>
        statefulNavigate(appPaths.app.settings['institution-details'].base);

    const onSuccess = () => {
        onSuccessNavigate();
        displaySuccess(settingsTexts.institutionDetails.successfullyEditedInstitutionMessage);
    };

    const {
        data: institutionDetailsData,
        isFetching: isInstitutionDetailsDataFetching,
        isError: isInstitutionDetailsDataError,
    } = useAppQuery(
        'INSTITUTION',
        [currentInstitutionId],
        () => StaffHTTPService.institutions.getInstitutionDetails(currentInstitutionId),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        if (institutionDetailsData) {
            setFormData(
                parseInstitutionCreateDataIntoInstitutionAddFormFields(institutionDetailsData),
            );
        }
    }, [institutionDetailsData, setFormData]);

    const {
        mutate: editInstitutionDetails,
        error: editErrorInstitutionDetailsError,
        isLoading: isLoadingEditInstitutionDetails,
    } = useAppMutation(StaffHTTPService.institutions.updateInstitutionDetails, {
        key: ['UPDATE_INSTITUTION'],
        onSuccess,
        invalidateQueryKeys: [['INSTITUTION', currentInstitutionId], ['USER_DETAILS']],
    });

    const onFinish = (fields: InstitutionAddEditFormFields) =>
        editInstitutionDetails({
            institutionId: currentInstitutionId,
            updateInstitutionDTO: parseInstitutionAddFormFieldsIntoInstitutionCreateData(fields),
        });

    const parsedInstitutionDetailsEditError = editErrorInstitutionDetailsError
        ? institutionAddErrorHandler(editErrorInstitutionDetailsError)
        : undefined;

    if (isInstitutionDetailsDataError) {
        return <ErrorDisplay />;
    }

    return (
        <InstitutionAddEditForm
            isLoading={isLoadingEditInstitutionDetails || isInstitutionDetailsDataFetching}
            error={parsedInstitutionDetailsEditError}
            onFinish={onFinish}
            withGoBack
            name="institutionEdit"
            formNavButtons={
                <FormNavButtons
                    formValuesBeforeChanges={formInitialData}
                    buttonText={commonTexts.actionLabels.save}
                    withPlus
                />
            }
            mode="edit"
            form={form}
            initialValues={formInitialData}
        />
    );
};
