import { useMemo } from 'react';
import { JournalId, ParentId, SelectOptionType } from 'types';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { commonTexts } from 'consts/text';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { getParentsSelectOptionsFromJournalChildren } from 'utils/getParentsSelectOptionsFromJournalChildren';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

export type ParentsPickerDataProps = {
    journalId: JournalId;
    date?: string;
};

export const useParentsPickerData = ({ journalId, date }: ParentsPickerDataProps) => {
    const { data: childrenData, isFetching: isChildrenDataFetching } = useAppQuery(
        'JOURNAL_CHILDREN_PARENTS',
        [journalId, date, date],
        () =>
            StaffHTTPService.journals.children.getChildrenParents(journalId, {
                dateFrom: date,
                dateTo: date,
            }),
        {
            enabled: !!date,
            staleTime: FIVE_MINS_IN_MILLIS,

            meta: {
                errorMessage: commonTexts.errorMessages.parentsFetching,
            },
        },
    );

    const parentsOptions: SelectOptionType<ParentId>[] = useMemo(() => {
        if (!childrenData) {
            return [];
        }
        return getParentsSelectOptionsFromJournalChildren(childrenData);
    }, [childrenData]);

    return { childrenData, parentsOptions, isChildrenDataFetching };
};
