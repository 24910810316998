export type InstitutionAffiliation = 'public' | 'private';
export type InstitutionType = 'KINDERGARTEN' | 'NURSERY' | 'OTHER';

export type InstitutionId = string & { readonly type: unique symbol };
export type InstitutionDietId = string & { readonly type: unique symbol };

export type InstitutionBaseData = {
    id: InstitutionId;
    name: string;
};

export type InstitutionDiet = {
    id: InstitutionDietId;
    name: string;
};

export type InstitutionBasicDto = {
    id: InstitutionId;
    regon: string;
    name: string;
    rspo: string | null;
    nurseryRegistryNumber: string | null;
    street: string;
    zipCode: string;
    city: string;
    publicInstitution: boolean;
    type: InstitutionType;
};

export type UpdateInstitutionDto = Omit<
    InstitutionBasicDto,
    'id' | 'nurseryRegistryNumber' | 'rspo'
> & {
    rspo?: string | null;
    nurseryRegistryNumber?: string | null;
};

export enum InstitutionIdentifier {
    NURSERY_REGISTER_NUMBER = 'NURSERY_REGISTER_NUMBER',
    RSPO = 'RSPO',
}
