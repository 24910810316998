import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconEye, IconCalendarEvent } from '@tabler/icons-react';
import { AppTable } from 'components/organisms';
import { JournalChildDTO } from 'types';
import { AppModal } from 'components/molecules';
import { appPaths } from 'consts/paths/paths';
import { journalChildDTOFields } from 'consts/child/journalChildDTOFields';
import { Option } from 'components/atoms';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { journalTexts, commonTexts } from 'consts/text';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { JournalChildDatesFormFields } from 'types/forms/journalChildDates';
import { parseJournalChildDatesFormFieldsIntoUpdateJournalChildDatesDTO } from 'utils/parsers/journal/parseJournalChildDatesFormFieldsIntoUpdateJournalChildDatesDTO';
import { JournalChildDatesForm } from 'components/templates/journal';
import { Form } from 'antd';
import { parseJournalChildDTOIntoJournalChildDatesFormFields } from 'utils/parsers/journal/parseJournalChildDTOIntoJournalChildDatesFormFields';
import { CalendarIcon } from 'assets';
import { useChildAbilities, useJournalAbilities } from 'abilities';
import dayjs from 'dayjs';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useJournalChildrenPageNetworkManage } from './hooks/useJournalChildrenPageNetworkManage';
import { columnsWithoutSensitiveData, columnsWithSensitiveData } from './JournalChildrenPage.utils';

export const JournalChildrenPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const [form] = Form.useForm();
    const [selectedToPatchChild, setSelectedToPatchChild] = useState<JournalChildDTO | null>(null);
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const { genericAbilities, groupJournalsAbilities, specialJournalsAbilities } =
        useJournalAbilities();
    const { childAbilities } = useChildAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType,
    });

    const isGroupJournal = journalType === 'GROUP';

    const canManageChildren = childAbilities.checkIfCanManageChildrenList();
    const canChangeChildDates = genericAbilities.checkIfCanChangeChildDates();
    const canViewChildDetails = childAbilities.checkIfCanViewChildrenList();
    const canSeeSensitiveData = isGroupJournal
        ? groupJournalsAbilities.checkIfCanSeeChildrenSensitiveData()
        : specialJournalsAbilities.checkIfCanSeeChildrenSensitiveData();

    const handlePatchChildDatesFinishOrCancel = () => setSelectedToPatchChild(null);

    const {
        childrenData,
        isChildrenDataInitialLoading,
        isChildrenDataRefetching,
        isPatchChildDatesLoading,
        isChildrenDataError,
        patchChildDates,
    } = useJournalChildrenPageNetworkManage({
        journalId,
        onChildPatchDatesRequestFinish: handlePatchChildDatesFinishOrCancel,
    });

    const handlePatchChildDatesConfirm = (formValues: JournalChildDatesFormFields) => {
        if (!selectedToPatchChild) {
            return;
        }
        patchChildDates({
            journalId,
            childId: selectedToPatchChild.id,
            values: parseJournalChildDatesFormFieldsIntoUpdateJournalChildDatesDTO(formValues),
        });
    };

    const handlePatchChildDatesClick = ({ id: childId }: JournalChildDTO) => {
        const child = childrenData.find((childData) => childData.id === childId);
        if (child) {
            setSelectedToPatchChild(child);
            form.setFieldsValue(parseJournalChildDTOIntoJournalChildDatesFormFields(child));
        }
    };

    const navigateToChildEditPage = ({ id: childId }: JournalChildDTO) =>
        statefulNavigate(
            generatePath(
                isGroupJournal
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.children.edit
                          .pickedChild
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.children.edit
                          .pickedChild,
                {
                    journalId,
                    childId,
                },
            ),
        );

    const navigateToChildDetailsPage = ({ id: childId }: JournalChildDTO) =>
        statefulNavigate(
            generatePath(
                isGroupJournal
                    ? appPaths.app.journals.groupJournalDetails.pickedJournal.children.details
                          .pickedChild.base
                    : appPaths.app.journals.specialJournalDetails.pickedJournal.children.details
                          .pickedChild.base,
                {
                    journalId,
                    childId,
                },
            ),
        );

    const options: Option<JournalChildDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToChildDetailsPage,
            Icon: <IconEye />,
            checkIfVisible: () => canViewChildDetails,
        },
        {
            label: journalTexts.journalDetails.common.children.editChild,
            onClick: navigateToChildEditPage,
            Icon: <IconEdit />,
            checkIfVisible: () => canManageChildren,
        },
        {
            label: journalTexts.journalDetails.common.children.changeDates,
            onClick: handlePatchChildDatesClick,
            checkIfVisible: () => canChangeChildDates,
            Icon: <IconCalendarEvent />,
        },
    ];

    const columns = canSeeSensitiveData ? columnsWithSensitiveData : columnsWithoutSensitiveData;

    return (
        <>
            <AppModal
                width={400}
                open={!!selectedToPatchChild}
                title={journalTexts.journalDetails.common.children.changeDates}
                onCancel={handlePatchChildDatesFinishOrCancel}
                icon={<CalendarIcon />}
                isLoading={isPatchChildDatesLoading}
            >
                <JournalChildDatesForm
                    child={selectedToPatchChild!}
                    form={form}
                    onFinish={handlePatchChildDatesConfirm}
                />
            </AppModal>
            <AppTable
                title={journalTexts.journalDetails.common.children.childList}
                emptyInfo={journalTexts.journalDetails.common.children.emptyChildList}
                options={options}
                columns={columns}
                dataSource={childrenData}
                rowKey={({ id }) => id}
                isError={isChildrenDataError}
                isOptionsLoading={isPatchChildDatesLoading}
                isDataInitialLoading={isChildrenDataInitialLoading}
                isDataRefetching={isChildrenDataRefetching}
                isTableManageUtilsHidden
                onRow={(journalChild) => ({
                    style: {
                        opacity:
                            journalChild[journalChildDTOFields.leaveDate] &&
                            dayjs(journalChild[journalChildDTOFields.leaveDate]).isBefore(dayjs())
                                ? 0.6
                                : 1,
                    },
                })}
            />
        </>
    );
};
