import { AppTable, AppTableProps } from 'components/organisms';
import { commonTexts, reportTexts, timetableTexts } from 'consts/text';
import { TeacherWorkingTimeSummaries } from 'types';
import { evidenceWorkingTimeFields } from 'consts/evidenceWorkingTime/evidenceWorkingTime';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { getFullName } from 'utils/getFullName';
import debounce from 'lodash/debounce';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { useSearchFilter } from 'hooks/useSearchFilter/useSearchFilter';
import { ColumnTitleWithQuestionMarkTooltip, DatePickerWithArrows } from 'components/molecules';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import dayjs from 'dayjs';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { parseDurationIntoHourString } from 'utils/parsers/dateTime/parseDurationIntoHourString';
import { AppButton } from 'components/atoms';
import { IconFileExport } from '@tabler/icons-react';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { fileDownload } from 'utils/fileDownload';
import { handleNetworkError } from 'utils/handleNetworkError';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useState } from 'react';

const columns: AppTableProps<TeacherWorkingTimeSummaries>['columns'] = [
    {
        title: timetableTexts.workingTimeEvidence.columnLabels.firstNameAndLastName,
        dataIndex: evidenceWorkingTimeFields.teacherId,
        key: evidenceWorkingTimeFields.teacherId,
        render: (_, { firstName, lastName }) => getFullName(firstName, lastName),
        width: '20%',
        align: 'center',
        mobileWidth: '100%',
    },
    {
        title: timetableTexts.workingTimeEvidence.columnLabels.workingDays,
        dataIndex: evidenceWorkingTimeFields.workingDays,
        key: evidenceWorkingTimeFields.workingDays,
        width: '10%',
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: (
            <ColumnTitleWithQuestionMarkTooltip
                title={timetableTexts.workingTimeEvidence.columnLabels.unjustifiedAbsenceDays}
                tooltip={timetableTexts.workingTimeEvidence.tooltipsTexts.unjustifiedAbsenceDays}
            />
        ),
        dataIndex: evidenceWorkingTimeFields.unjustifiedAbsences,
        key: evidenceWorkingTimeFields.unjustifiedAbsences,
        width: '10%',
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: (
            <ColumnTitleWithQuestionMarkTooltip
                title={timetableTexts.workingTimeEvidence.columnLabels.leaveDays}
                tooltip={timetableTexts.workingTimeEvidence.tooltipsTexts.leaveDays}
            />
        ),
        dataIndex: evidenceWorkingTimeFields.paidLeaves,
        key: evidenceWorkingTimeFields.paidLeaves,
        width: '10%',
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: (
            <ColumnTitleWithQuestionMarkTooltip
                title={timetableTexts.workingTimeEvidence.columnLabels.unpaidLeaveDays}
                tooltip={timetableTexts.workingTimeEvidence.tooltipsTexts.unpaidLeaveDays}
            />
        ),
        dataIndex: evidenceWorkingTimeFields.unpaidLeaves,
        key: evidenceWorkingTimeFields.unpaidLeaves,
        width: '10%',
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: (
            <ColumnTitleWithQuestionMarkTooltip
                title={timetableTexts.workingTimeEvidence.columnLabels.sickLeaveDays}
                tooltip={timetableTexts.workingTimeEvidence.tooltipsTexts.sickLeaveDays}
            />
        ),
        dataIndex: evidenceWorkingTimeFields.sickLeaves,
        key: evidenceWorkingTimeFields.sickLeaves,
        width: '10%',
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: timetableTexts.workingTimeEvidence.columnLabels.otherDays,
        dataIndex: evidenceWorkingTimeFields.otherLeaves,
        key: evidenceWorkingTimeFields.otherLeaves,
        width: '10%',
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: timetableTexts.workingTimeEvidence.columnLabels.plannedWorkingTime,
        dataIndex: evidenceWorkingTimeFields.scheduledWorkingTime,
        key: evidenceWorkingTimeFields.scheduledWorkingTime,
        width: '10%',
        align: 'center',
        render: (value) => parseDurationIntoHourString(value),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: timetableTexts.workingTimeEvidence.columnLabels.workingTime,
        dataIndex: evidenceWorkingTimeFields.workingTime,
        key: evidenceWorkingTimeFields.workingTime,
        width: '10%',
        align: 'center',
        render: (value) => parseDurationIntoHourString(value),
        isVisibleAsExtendableInMobile: true,
    },
];

export const EvidenceWorkingTimePage = () => {
    const isMobile = useIsMobile();
    const { displaySuccess, displayError } = useSimpleNotification();
    const [isReportLoading, setIsReportLoading] = useState<boolean>(false);

    const { handleSearchInput, searchFilterValue } = useSearchFilter({});
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });

    const parsedDateFilterValue = dateFilterValue
        ? parseStringIntoDayjsDate(dateFilterValue)
        : dayjs();
    const selectedYear = parsedDateFilterValue.year();
    const selectedMonth = getMonthFromDayjsMonth(parsedDateFilterValue.month());

    const {
        data: evidenceWorkingTimeData,
        isRefetching: isEvidenceWorkingTimeDataRefetching,
        isInitialLoading: isEvidenceWorkingTimeDataInitialLoading,
        isError: isEvidenceWorkingTimeDataError,
    } = useAppQuery('EVIDENCE_WORKING_TIME', [selectedMonth, selectedYear, searchFilterValue], () =>
        StaffHTTPService.employeeSchedule.getEvidenceWorkingTime(
            selectedMonth,
            selectedYear,
            searchFilterValue,
        ),
    );

    const onGenerateReport = async () => {
        try {
            setIsReportLoading(true);
            const response = await StaffHTTPService.reports.getEvidenceWorkingTimeReport(
                selectedMonth,
                selectedYear,
            );
            displaySuccess(reportTexts.successfullyCreatedEmployeesReport);
            const binaryData = [];
            binaryData.push(response.data);
            const blob = new Blob(binaryData, { type: 'application/pdf' });
            const href = URL.createObjectURL(blob);
            fileDownload(href, `Raport kadrowy ${selectedMonth}.${selectedYear}.pdf`);
            URL.revokeObjectURL(href);
        } catch (error) {
            displayError(reportTexts.unableToFetchJournalReportError);
            handleNetworkError(error);
        } finally {
            setIsReportLoading(false);
        }
    };

    const filters = (
        <DatePickerWithArrows
            picker="month"
            format="MMMM"
            label={commonTexts.actionLabels.selectMonth}
            value={parsedDateFilterValue}
            onChange={handleChangeDateFilter}
            onArrowButtonClick={(arrowButtonType) =>
                handleClickNextOrPrevDate(arrowButtonType, 'month')
            }
        />
    );

    return (
        <AppTable<TeacherWorkingTimeSummaries>
            title={timetableTexts.workingTimeEvidence.workingTimeEvidenceTableTitle}
            columns={columns}
            dataSource={evidenceWorkingTimeData?.teacherWorkingTimeSummaries}
            isError={isEvidenceWorkingTimeDataError}
            emptyInfo={commonTexts.emptyInfo.noTeachers}
            isDataInitialLoading={isEvidenceWorkingTimeDataInitialLoading}
            isDataRefetching={isEvidenceWorkingTimeDataRefetching}
            tableManageUtilsProps={{
                searchInputProps: {
                    defaultValue: searchFilterValue,
                    onInput: debounce(handleSearchInput, DEBOUNCE_DELAY),
                    width: '25%',
                },
                additionalFilters: filters,
                isDisplayedAsSeparateMenuOnMobile: true,
            }}
            additionalRightTableHeaderContent={
                <AppButton
                    type="default"
                    fullWidth={isMobile}
                    onClick={onGenerateReport}
                    icon={<IconFileExport width={20} height={20} />}
                    margin={0}
                    marginRight={isMobile ? 0 : 16}
                    marginBottom={isMobile ? 12 : 0}
                    loading={isReportLoading}
                >
                    {commonTexts.actionLabels.createReport}
                </AppButton>
            }
            rowKey={({ teacherId }) => teacherId}
        />
    );
};
