import { generatePath, useParams } from 'react-router-dom';
import { ChildDetails } from 'components/templates';
import { ChildId } from 'types';
import { appPaths, appRoutes } from 'consts/paths/paths';
import { JournalPagePropsWithJournalType } from 'pages/app/staff/journal/journalPagePropsWithJournalType';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalAbilities } from 'abilities';

export const JournalChildDetailsPage = ({ journalType }: JournalPagePropsWithJournalType) => {
    const { journalId } = useJournalId();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType,
    });

    const { childId: childIdParam } = useParams();

    const { groupJournalsAbilities, specialJournalsAbilities } = useJournalAbilities();

    const isGroupJournal = journalType === 'GROUP';
    const childId = (childIdParam || '') as ChildId;
    const childEditPagePath = generatePath(
        isGroupJournal
            ? appPaths.app.journals.groupJournalDetails.pickedJournal.children.edit.pickedChild
            : appPaths.app.journals.specialJournalDetails.pickedJournal.children.edit.pickedChild,
        { childId, journalId },
    );
    const pageRoute = isGroupJournal
        ? appRoutes.app.journals.groupJournalDetails.pickedJournal.children.details.pickedChild.base
        : appRoutes.app.journals.specialJournalDetails.pickedJournal.children.details.pickedChild
              .base;

    const canSeeSensitiveData = isGroupJournal
        ? groupJournalsAbilities.checkIfCanSeeChildrenSensitiveData()
        : specialJournalsAbilities.checkIfCanSeeChildrenSensitiveData();

    return (
        <ChildDetails
            pageRoute={pageRoute}
            childEditPagePath={childEditPagePath}
            canSeeSensitiveData={canSeeSensitiveData}
        />
    );
};
