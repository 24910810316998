import { AxiosError } from 'axios';
import { CommonFormProps } from 'types';
import { AppForm, AppFormProps } from 'components/organisms';
import {
    CityInputElement,
    FormNavButtons,
    NipInputWithFetchElement,
    SimpleInputElement,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { commonTexts, settingsTexts } from 'consts/text';
import { InvoiceEditFormFields } from 'types/forms/invoice';
import { formFields } from 'consts/form/formFields';
import { Col, Row } from 'antd';
import { parseGUSInformationDTOIntoIInvoiceEditFormFields } from 'utils/parsers/gus/parseGUSInformationDTOIntoIInvoiceEditFormFields';

type InvoiceEditFormProps = Omit<
    AppFormProps<InvoiceEditFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (invoiceData: InvoiceEditFormFields) => void;
    };
export const InvoiceEditForm = ({ initialValues, axiosError, ...props }: InvoiceEditFormProps) => {
    const inspectionAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<InvoiceEditFormFields>
            name="invoiceEdit"
            title={settingsTexts.invoiceData.form.invoiceDataTitle}
            error={inspectionAddEditError}
            withGoBack
            {...props}
        >
            <NipInputWithFetchElement
                parseInstitutionGusDataIntoFormData={
                    parseGUSInformationDTOIntoIInvoiceEditFormFields
                }
            />
            <SimpleInputElement
                name={formFields.name}
                label={settingsTexts.invoiceData.form.companyNameLabel}
            />
            <StreetAddressInputElement />
            <Row style={{ gap: 15 }}>
                <Col flex={1}>
                    <ZipCodeInputElement />
                </Col>
                <Col flex={1}>
                    <CityInputElement />
                </Col>
            </Row>
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText="Zapisz"
                withDeviceFloppy
            />
        </AppForm>
    );
};
