import { AxiosError } from 'axios';
import { AppForm, AppFormProps, MailThreadRecipientsPicker } from 'components/organisms';
import { MailThreadFormFields } from 'types';
import {
    AttachmentsUploadElement,
    FormNavButtons,
    SimpleInputElement,
    SimpleTextAreaElement,
} from 'components/molecules';
import { messagesTexts } from 'consts/text';
import { StyledSendIcon } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { mailThreadAddEditErrorParser } from 'utils/errorHandlers/mailThread/mailThreadAddEditErrorParser';
import { max2000LengthRule, max300LengthRule } from 'consts/rules/textRules';
import { MESSAGE_ATTACHMENT_FORMATS } from 'consts/file/fileFormats';

type MailThreadAddFormProps = Omit<
    AppFormProps<MailThreadFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack' | 'mode'
> & {
    axiosError?: AxiosError | null;
    isLoading?: boolean;
    mode?: 'add' | 'reply';
    onFinish: (mailThreadData: MailThreadFormFields) => void;
};

const FORM_MAX_WIDTH = 600;

export const MailThreadAddForm = ({
    axiosError,
    mode = 'add',
    ...restProps
}: MailThreadAddFormProps) => {
    const mailThreadAddError = axiosError ? mailThreadAddEditErrorParser(axiosError) : undefined;

    return (
        <AppForm<MailThreadFormFields>
            title={messagesTexts.newMessage}
            name="mailThreadAdd"
            error={mailThreadAddError}
            withGoBack
            maxWidth={FORM_MAX_WIDTH}
            {...restProps}
        >
            <MailThreadRecipientsPicker disabled={mode === 'reply'} />
            <SimpleInputElement
                name={formFields.topic}
                label={messagesTexts.messageTopic}
                rules={[max300LengthRule]}
            />
            <SimpleTextAreaElement
                name={formFields.content}
                rules={[max2000LengthRule]}
                label={messagesTexts.messageContent}
                rows={8}
                resizable={false}
            />
            <AttachmentsUploadElement
                uploadDirectory="MESSAGE"
                accept={MESSAGE_ATTACHMENT_FORMATS}
            />
            <FormNavButtons buttonText={messagesTexts.sendMessage} leftIcon={<StyledSendIcon />} />
        </AppForm>
    );
};
