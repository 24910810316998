import { IconEdit, IconEye, IconFilePlus } from '@tabler/icons-react';
import { AppTable } from 'components/organisms';
import { commonTexts, journalTexts } from 'consts/text';
import { Option } from 'components/atoms';
import { QualificationDTO } from 'types/qualifications';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { useJournalAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useSpecialJournalQualificationsPageNetworkManage } from './hooks/useSpecialJournalQualificationsPageNetworkManage';
import {
    columnsWithoutSensitiveData,
    columnsWithSensitiveData,
} from './SpecialJournalQualificationPage.utils';

export const SpecialJournalQualificationPage = () => {
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const {
        specialJournalsAbilities: {
            checkIfCanManageQualificationBasis,
            checkIfCanSeeChildrenSensitiveData,
        },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const canManageQualificationBasis = checkIfCanManageQualificationBasis();
    const canSeeChildrenSensitiveData = checkIfCanSeeChildrenSensitiveData();

    const isQualificationFilled = (qualification: QualificationDTO) => qualification.filled;

    const navigateToQualificationDetails = (qualification: QualificationDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.details
                    .pickedQualification,
                {
                    qualificationId: qualification.id,
                    journalId,
                },
            ),
        );

    const navigateToQualificationAdd = (qualification: QualificationDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.add,
                {
                    journalId,
                },
            ),
            {
                state: {
                    childId: qualification.childId,
                },
            },
        );

    const navigateToQualificationEdit = (qualification: QualificationDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.qualifications.edit
                    .pickedQualification,
                {
                    journalId,
                    qualificationId: qualification.id,
                },
            ),
            {
                state: {
                    childId: qualification.childId,
                },
            },
        );

    const options: Option<QualificationDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToQualificationDetails,
            Icon: <IconEye />,
            checkIfVisible: (qualification) => isQualificationFilled(qualification),
        },
        {
            label: journalTexts.journalDetails.specialJournal.qualifications.addQualification,
            onClick: navigateToQualificationAdd,
            Icon: <IconFilePlus />,
            checkIfVisible: (qualification) =>
                canManageQualificationBasis && !isQualificationFilled(qualification),
        },
        {
            label: journalTexts.journalDetails.specialJournal.qualifications.editQualification,
            onClick: navigateToQualificationEdit,
            Icon: <IconEdit />,
            checkIfVisible: (qualification) =>
                canManageQualificationBasis && isQualificationFilled(qualification),
        },
    ];

    const {
        qualificationsData,
        isQualificationsDataInitialLoading,
        isQualificationsDataError,
        isQualificationsDataRefetching,
    } = useSpecialJournalQualificationsPageNetworkManage({ journalId });

    const columns = canSeeChildrenSensitiveData
        ? columnsWithSensitiveData
        : columnsWithoutSensitiveData;

    return (
        <AppTable
            title={commonTexts.dataLabels.childList}
            emptyInfo={
                journalTexts.journalDetails.specialJournal.qualifications.emptyQualificationList
            }
            options={options}
            columns={columns}
            dataSource={qualificationsData}
            rowKey={({ childId }) => childId}
            isOptionsLoading={isQualificationsDataInitialLoading}
            isError={isQualificationsDataError}
            isDataInitialLoading={isQualificationsDataInitialLoading}
            isDataRefetching={isQualificationsDataRefetching}
            isTableManageUtilsHidden
        />
    );
};
