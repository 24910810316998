import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, settingsTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const settlementOverallEditErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.WORKING_DAY_LESSONS_CONFLICT) {
        return settingsTexts.settlements.overall.workingDayLessonsConflictErrorMessage;
    }

    return commonTexts.errorMessages.unknown;
};
