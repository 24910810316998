import styled, { useTheme } from 'styled-components';
import { AppRadioGroup } from 'components/atoms/CommonAppComponents/AppRadioGroup/AppRadioGroup';
import { commonTexts, timetableTexts } from 'consts/text';
import { RemoveModal } from 'components/organisms/ModalVariations/RemoveModal/RemoveModal';
import { ModalButton } from 'components/organisms/AppConfirmModal/AppConfirmModal.styled';
import { AppRadio } from 'components/atoms/CommonAppComponents/AppRadio/AppRadio';
import { useState } from 'react';
import { Space } from 'antd';
import { TrashIcon } from 'assets';
import { LessonDTO } from 'types';
import { AppModal } from 'components/molecules/AppModal/AppModal';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';

const IconContainer = styled.div`
    margin-block-end: 16px;
`;

type LessonRemoveModalsProps = {
    inProgress: boolean;
    onCancel: () => void;
    onFinish: () => void;
    lesson: LessonDTO;
};

enum RemoveLessonsSteps {
    CHOOSE_LESSON_REMOVE_TYPE = 'CHOOSE_LESSON_REMOVE_TYPE',
    CONFIRM_LESSON_REMOVE = 'CONFIRM_LESSON_REMOVE',
}

export const LessonRemoveModals = ({
    lesson,
    inProgress,
    onCancel,
    onFinish,
}: LessonRemoveModalsProps) => {
    const theme = useTheme();
    const { displaySuccess, displayError } = useSimpleNotification();

    const [removeStep, setRemoveStep] = useState<RemoveLessonsSteps>(
        RemoveLessonsSteps.CHOOSE_LESSON_REMOVE_TYPE,
    );
    const [affectNext, setAffectNext] = useState(false);

    const goToConfirmRemoveStep = () => {
        setRemoveStep(RemoveLessonsSteps.CONFIRM_LESSON_REMOVE);
    };

    const resetSteps = () => {
        setRemoveStep(RemoveLessonsSteps.CHOOSE_LESSON_REMOVE_TYPE);
    };

    const onRemoveError = () => {
        displayError(commonTexts.errorMessages.actionExecution);
        onCancel();
        resetSteps();
    };

    const { mutate: removeLesson, isLoading: isRemoveLessonLoading } = useAppMutation(
        StaffHTTPService.employeeSchedule.removeLesson,
        {
            key: ['REMOVE_LESSON'],
            onSuccess: () => {
                displaySuccess(timetableTexts.lessons.successfullyRemovedLessonsMessage);
                onFinish();
                resetSteps();
            },
            onError: onRemoveError,
            invalidateQueryKeys: [
                ['EMPLOYEE_SCHEDULE'],
                ['LESSONS'],
                ['LESSON'],
                ['SPECIAL_DAILY_ATTENDANCE'],
                ['SPECIAL_TOPICS', lesson.journal.id],
                ['LESSONS_WITHOUT_TOPIC', lesson.journal.id],
            ],
        },
    );

    const onOkClick = () => {
        removeLesson({ id: lesson.id, affectNext });
    };

    const onConfirmStepCancelClick = () => {
        onCancel();
        resetSteps();
    };

    const buttons = (
        <>
            <ModalButton type="default" onClick={onCancel}>
                {commonTexts.actionLabels.cancel}
            </ModalButton>
            <ModalButton type="primary" onClick={goToConfirmRemoveStep} danger>
                {commonTexts.actionLabels.delete}
            </ModalButton>
        </>
    );

    return (
        <>
            <RemoveModal
                open={inProgress && removeStep === RemoveLessonsSteps.CONFIRM_LESSON_REMOVE}
                title={timetableTexts.lessons.removeModalTitle}
                confirmActionLoading={isRemoveLessonLoading}
                onCancel={onConfirmStepCancelClick}
                onOk={onOkClick}
                text={timetableTexts.lessons.removeModalText}
            />
            <AppModal
                icon={
                    <IconContainer>
                        <TrashIcon />
                    </IconContainer>
                }
                open={inProgress && removeStep === RemoveLessonsSteps.CHOOSE_LESSON_REMOVE_TYPE}
                width={theme.sizes.modalWidth.small}
                title={timetableTexts.lessons.removeAffectNextModalTitle}
                titleLevel={5}
                footer={buttons}
                onCancel={onCancel}
                closable={false}
                isMobileFullScreenMode={false}
            >
                <AppRadioGroup
                    value={affectNext}
                    fullWidth={false}
                    onChange={(event) => setAffectNext(event.target.value)}
                >
                    <Space direction="vertical" size={0}>
                        <AppRadio value={false}>{timetableTexts.lessons.currentLesson}</AppRadio>
                        <AppRadio value>{timetableTexts.lessons.currentAndNextLessons}</AppRadio>
                    </Space>
                </AppRadioGroup>
            </AppModal>
        </>
    );
};
