import { ErrorDisplay } from 'components/molecules';
import { LessonAddEditForm } from 'components/templates';
import { appPaths } from 'consts/paths/paths';
import { timetableTexts } from 'consts/text';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useEffect } from 'react';
import { isAxiosError } from 'axios';
import styled from 'styled-components';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { EmployeeId, LessonFormFields, LessonId } from 'types';
import { parseLessonDTOIntoLessonFormFields } from 'utils/parsers/lesson/parseLessonDTOIntoLessonFormFields';
import { parseLessonFormFieldsIntoUpdateLessonDTO } from 'utils/parsers/lesson/parseLessonFormFieldsIntoUpdateLessonDTO';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

const StyledErrorDisplay = styled(ErrorDisplay)`
    max-width: 300px;
`;

export const LessonEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<LessonFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const navigate = useNavigate();
    const { statefulNavigate } = useNavigateWithState();
    const params = useMatch(appPaths.app.timetable.employeeSchedule.edit.pickedLesson);
    const location = useLocation();
    const employeeId = location.state.employeeId as { employeeId?: EmployeeId };
    const lessonId = (params?.params.lessonId || '') as LessonId;

    const onEditLessonSuccess = () => {
        displaySuccess(timetableTexts.lessons.successfullyEditedLessonsMessage);
        if (employeeId) {
            navigate(-1);
        } else {
            statefulNavigate(appPaths.app.timetable.employeeSchedule.base, { replace: true });
        }
    };

    const {
        data: lessonData,
        error: lessonDataError,
        isFetching: isLessonDataFetching,
    } = useAppQuery(
        'LESSON',
        [lessonId],
        () => StaffHTTPService.employeeSchedule.getLesson(lessonId),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    useEffect(() => {
        if (lessonData) {
            setFormData(parseLessonDTOIntoLessonFormFields(lessonData));
        }
    }, [lessonData, form, setFormData]);

    const {
        mutate: editLesson,
        error: editLessonError,
        isLoading: isEditLessonLoading,
    } = useAppMutation(StaffHTTPService.employeeSchedule.editLesson, {
        key: ['EDIT_LESSON'],
        onSuccess: onEditLessonSuccess,
        invalidateQueryKeys: [
            ['EMPLOYEE_SCHEDULE'],
            ['LESSONS'],
            ['LESSONS_WITHOUT_TOPIC'],
            ['LESSON', lessonId],
            ['SPECIAL_DAILY_ATTENDANCE'],
            ['SETTLEMENT_EXTRA_LESSONS'],
        ],
    });

    const onEditLessonFinish = (lessonFormData: LessonFormFields, affectNext: boolean) =>
        editLesson({
            id: lessonId,
            editLessonDTO: parseLessonFormFieldsIntoUpdateLessonDTO(lessonFormData),
            affectNext,
        });

    if (!lessonId) {
        statefulNavigate(appPaths.app.timetable.employeeSchedule.base, { replace: true });
    }

    if (lessonDataError) {
        const isNoLessonDataPermissionError =
            isAxiosError(lessonDataError) &&
            lessonDataError.response?.data.code === apiErrorCodes.LESSON_ACCESS_FORBIDDEN;

        return (
            <StyledErrorDisplay
                title={
                    isNoLessonDataPermissionError
                        ? timetableTexts.lessons.lessonAccessForbiddenErrorMessage
                        : undefined
                }
                hideSubTitle={isNoLessonDataPermissionError}
            />
        );
    }

    return (
        <LessonAddEditForm
            mode="edit"
            form={form}
            initialValues={formInitialData}
            axiosError={editLessonError}
            isLoading={isEditLessonLoading || isLessonDataFetching}
            onFinish={onEditLessonFinish}
            lessonData={lessonData}
        />
    );
};
