import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import { AppTable } from 'components/organisms';
import { commonTexts, settlementTexts } from 'consts/text';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { parsePaginationDataToTablePaginationData } from 'utils/parsers/table/parsePaginationDataToTablePaginationData';
import { DatePickerWithArrows, Filter } from 'components/molecules';
import { FULL_MONTH_FORMAT } from 'consts/dateFormats';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { useSearchParamsBasedPagination } from 'hooks/useSearchParamsBasedPagination/useSearchParamsBasedPagination';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { SettlementChildDTO } from 'types/settlementChildren/settlementChildren';
import { ChildId, ChildSettlementId, SettlementChildrenDtoFieldWithSorting } from 'types';
import { AppButton, Option } from 'components/atoms';
import {
    IconCalendarTime,
    IconChecks,
    IconEye,
    IconRefresh,
    IconTransferOut,
} from '@tabler/icons-react';
import { ChildAddEditBillModal } from 'components/templates/settlement/ChildAddBillModal/ChildAddEditBillModal';
import { generatePath, useOutlet } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useSearchFilter } from 'hooks/useSearchFilter/useSearchFilter';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useTableSorter } from 'hooks/useTableSorter/useTableSorter';
import { settlementChildrenDtoFieldIntoSettlementChildrenSortByMapper } from 'consts/settlementChildren/settlementChildrenDtoFieldIntoSettlementChildrenSortByMapper';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { useTheme } from 'styled-components';
import { useSettlementAbilities } from 'abilities';
import { sortOrderIntoApiSortDirectionMapper } from 'consts/api/sortOrderIntoApiSortDirectionMapper';
import { VerifySettlementsModal, VerifySettlementsModalMode } from 'components/templates';
import { settlementChildrenDTOFields } from 'consts/settlementChildren/settlementChildrenDTOFields';
import { AxiosError } from 'axios';
import { settlementsVerifyErrorParser } from 'utils/errorHandlers/settlement/settlementsVerifyErrorParser';
import {
    sorterOptions,
    sorterResultIntoSortVariantMapper,
    sortVariantIntoSorterResultMapper,
} from './ChildrenSettlementPage.consts';
import { SortVariant } from './ChildrenSettlementPage.types';
import { getColumns } from './ChildrenSettlementPage.utils';

export const ChildrenSettlementPage = () => {
    const outlet = useOutlet();
    const { displaySuccess, displayError } = useSimpleNotification();

    const [addBillModalOpen, setAddBillModalOpen] = useState<ChildId | null>(null);
    const [verifySettlementsModalOpen, setVerifySettlementsModalOpen] = useState(false);

    const { currentPage, currentPageSize, handlePaginationValuesChange } =
        useSearchParamsBasedPagination();
    const { searchFilterValue, handleSearchInput } = useSearchFilter({
        currentPage,
    });
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
        currentPage,
    });
    const { sortData, handleSorterChange } = useTableSorter<SettlementChildDTO>();

    const { statefulNavigate } = useNavigateWithState();

    const isMobile = useIsMobile();
    const theme = useTheme();

    const { settlementAbilities } = useSettlementAbilities();

    const canVerifySettlement = settlementAbilities.checkIfCanVerifySettlement();

    const { sortBy, sortDirection } = sortData;
    const mappedSortDirection = sortDirection
        ? sortOrderIntoApiSortDirectionMapper[sortDirection]
        : undefined;
    const mappedSortBy = sortBy
        ? settlementChildrenDtoFieldIntoSettlementChildrenSortByMapper[
              sortBy as SettlementChildrenDtoFieldWithSorting
          ]
        : undefined;
    const mappedSortVariant =
        sortBy && sortDirection
            ? sorterResultIntoSortVariantMapper[sortBy as SettlementChildrenDtoFieldWithSorting][
                  sortDirection
              ]
            : undefined;

    const selectedDate = dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs();
    const selectedYear = selectedDate.year();
    const selectedMonth = getMonthFromDayjsMonth(selectedDate.month());

    const {
        data: settlementsData,
        isInitialLoading: isSettlementsDataInitialFetching,
        isRefetching: isSettlementsDataRefetching,
        isError: isSettlementsDataError,
    } = useAppQuery(
        'SETTLEMENTS',
        [
            searchFilterValue,
            currentPage,
            currentPageSize,
            dateFilterValue,
            mappedSortBy,
            mappedSortDirection,
        ],
        () =>
            StaffHTTPService.settlements.getMonthlySettlements({
                search: searchFilterValue,
                month: selectedMonth,
                year: selectedYear,
                paginationParams: { size: currentPageSize, page: currentPage },
                sorterParams: { sortBy: mappedSortBy, sortDirection: mappedSortDirection },
            }),
    );

    const {
        data: verificationStatusData,
        isFetching: isVerificationStatusFetching,
        isError: isVerificationStatusError,
    } = useAppQuery('VERIFICATION_STATUS', [selectedMonth, selectedYear], () =>
        StaffHTTPService.settlements.getVerificationStatus({
            month: selectedMonth,
            year: selectedYear,
        }),
    );

    useEffect(() => {
        if (isVerificationStatusError) {
            displayError(commonTexts.errorMessages.unknown);
        }
    }, [displayError, isVerificationStatusError]);

    const { mutate: recalculateSettlement, isLoading: isRecalculateSettlementLoading } =
        useAppMutation(StaffHTTPService.settlements.recalculateSettlement, {
            key: ['SETTLEMENT_RECALCULATE'],
            onSuccess: () =>
                displaySuccess(
                    settlementTexts.children.recalculate.successfullyRecalculatedSettlementMessage,
                ),
            onError: () => displayError(commonTexts.errorMessages.actionExecution),
            invalidateQueryKeys: [
                ['SETTLEMENTS'],
                ['CHILD_SETTLEMENT'],
                ['CHILD_SETTLEMENT_PAYMENT'],
            ],
        });

    const verifySettlementsModalClose = () => setVerifySettlementsModalOpen(false);

    const handleVerifyAllSettlementsFromMonthSuccess = () => {
        displaySuccess(
            settlementTexts.children.verifySettlements.multipleSettlements
                .verifySettlementsSuccessMessage,
        );
        verifySettlementsModalClose();
    };

    const handleVerifyAllSettlementsFromMonthError = (error: AxiosError) => {
        displayError(
            settlementsVerifyErrorParser(error, commonTexts.errorMessages.actionExecution),
        );
        verifySettlementsModalClose();
    };

    const {
        mutate: verifyAllSettlementsFromMonth,
        isLoading: isVerifyAllSettlementsFromMonthLoading,
    } = useAppMutation(StaffHTTPService.settlements.verifyAllSettlementsFromMonth, {
        key: ['VERIFY_SETTLEMENTS'],
        onSuccess: handleVerifyAllSettlementsFromMonthSuccess,
        onError: handleVerifyAllSettlementsFromMonthError,
        invalidateQueryKeys: [
            ['SETTLEMENTS'],
            ['CHILD_SETTLEMENT'],
            ['VERIFICATION_STATUS', selectedMonth, selectedYear],
        ],
    });

    const onRecalculateClick = (id: ChildSettlementId, childId: ChildId) => {
        recalculateSettlement({ childId, settlementId: id });
    };

    const handleMobileSorterChange = (sortVariant: SortVariant) => {
        const sorterResult = sortVariantIntoSorterResultMapper[sortVariant];
        handleSorterChange(sorterResult);
    };

    const columns = useMemo(() => {
        if (!sortBy || !sortDirection) {
            return getColumns();
        }
        return getColumns({
            column: sortBy as SettlementChildrenDtoFieldWithSorting,
            order: sortDirection,
        });
    }, [sortBy, sortDirection]);

    const options: Option<SettlementChildDTO>[] = [
        {
            label: settlementTexts.children.actions.billPreview,
            onClick: (record) =>
                statefulNavigate(
                    generatePath(appPaths.app.settlement.children.pickedChild.details.base, {
                        childId: record.child.id,
                    }),
                ),
            Icon: <IconEye />,
        },
        {
            label: settlementTexts.children.actions.addBill,
            onClick: (record) => setAddBillModalOpen(record.child.id),
            Icon: <IconTransferOut />,
        },
        {
            label: settlementTexts.children.actions.recalculateBill,
            onClick: (record) => onRecalculateClick(record.id, record.child.id),
            checkIfVisible: (settlementChildDTO: SettlementChildDTO) =>
                !settlementChildDTO[settlementChildrenDTOFields.verified],
            Icon: <IconRefresh />,
        },
    ];

    const filters = (
        <DatePickerWithArrows
            label={commonTexts.actionLabels.selectMonth}
            value={selectedDate}
            picker="month"
            format={FULL_MONTH_FORMAT}
            onChange={handleChangeDateFilter}
            onArrowButtonClick={(arrowButtonType) =>
                handleClickNextOrPrevDate(arrowButtonType, 'month')
            }
        />
    );

    const sorter = (
        <Filter
            input={
                <Filter.Select
                    defaultValue={SortVariant.NAME_ASC}
                    value={mappedSortVariant}
                    options={sorterOptions}
                    allowClear={false}
                    onChange={handleMobileSorterChange}
                />
            }
            label={settlementTexts.children.sortingLabel}
        />
    );

    const verifySettlementsButton = (
        <AppButton
            type="primary"
            margin={0}
            marginBottom={isMobile ? theme.sizes.marginNormal : 0}
            fullWidth={isMobile}
            icon={
                verificationStatusData?.allSettlementsVerified ? (
                    <IconChecks />
                ) : (
                    <IconCalendarTime />
                )
            }
            disabled={
                isVerificationStatusFetching ||
                isVerificationStatusError ||
                verificationStatusData?.allSettlementsVerified
            }
            loading={isVerificationStatusFetching}
            onClick={() => setVerifySettlementsModalOpen(true)}
        >
            {verificationStatusData?.allSettlementsVerified
                ? settlementTexts.children.verifiedSettlementsLabel
                : settlementTexts.children.verifySettlementsLabel}
        </AppButton>
    );

    return (
        <>
            {outlet}
            <ChildAddEditBillModal
                childId={addBillModalOpen}
                onCancel={() => setAddBillModalOpen(null)}
            />
            <VerifySettlementsModal
                open={verifySettlementsModalOpen}
                mode={VerifySettlementsModalMode.MULTIPLE_SETTLEMENTS}
                monthNumber={selectedMonth - 1}
                confirmActionLoading={isVerifyAllSettlementsFromMonthLoading}
                onOk={() =>
                    verifyAllSettlementsFromMonth({
                        year: selectedYear,
                        month: selectedMonth,
                    })
                }
                onCancel={verifySettlementsModalClose}
            />
            <AppTable<SettlementChildDTO>
                isError={isSettlementsDataError}
                options={options}
                title={settlementTexts.children.tableTitle}
                emptyInfo={settlementTexts.children.emptyInfo}
                columns={columns}
                rowKey={({ id }) => id}
                isDataInitialLoading={isSettlementsDataInitialFetching}
                isDataRefetching={isSettlementsDataRefetching || isRecalculateSettlementLoading}
                dataSource={settlementsData?.content}
                additionalRightTableHeaderContent={
                    canVerifySettlement ? verifySettlementsButton : undefined
                }
                tableManageUtilsProps={{
                    searchInputProps: {
                        defaultValue: searchFilterValue,
                        onInput: debounce(handleSearchInput, DEBOUNCE_DELAY),
                    },
                    additionalFilters: (
                        <>
                            {filters}
                            {isMobile && sorter}
                        </>
                    ),
                }}
                pagination={{
                    ...parsePaginationDataToTablePaginationData({
                        currentPage,
                        currentPageSize,
                    }),
                    total: settlementsData?.totalElements || 0,
                    onChange: handlePaginationValuesChange,
                }}
                onSorterChange={handleSorterChange}
            />
        </>
    );
};
