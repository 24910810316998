import { InstitutionAddEditFormFields, InstitutionCreateData, InstitutionIdentifier } from 'types';

export const parseInstitutionCreateDataIntoInstitutionAddFormFields: (
    institutionCreateData: InstitutionCreateData,
) => InstitutionAddEditFormFields = (institutionCreateData) => {
    const {
        publicInstitution,
        city,
        street,
        zipCode,
        name,
        rspo,
        type,
        nurseryRegistryNumber,
        regon,
    } = institutionCreateData;

    return {
        regonNumber: regon,
        institutionName: name,
        rspoNumber: rspo || '',
        street,
        zipCode,
        city,
        institutionAffiliation: publicInstitution ? 'public' : 'private',
        institutionType: type,
        nurseryRegistryNumber: nurseryRegistryNumber || '',
        institutionIdentifier: rspo
            ? InstitutionIdentifier.RSPO
            : InstitutionIdentifier.NURSERY_REGISTER_NUMBER,
    };
};
