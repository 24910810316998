import { PageBaseLayout } from 'components/layouts';
import { useEffect } from 'react';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { SettlementVatRatesFormFields } from 'types';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { settingsTexts } from 'consts/text';
import { SettlementVatRatesEditForm } from 'components/templates';

export const SettlementVatRatesEditPage = () => {
    const { form, formInitialData, setFormData } = useEditForm<SettlementVatRatesFormFields>();

    const { displaySuccess } = useSimpleNotification();

    const {
        data: settlementVatRatesData,
        isError: isSettlementVatRatesError,
        isFetching: isSettlementVatRatesFetching,
    } = useAppQuery('SETTLEMENT_VAT_RATES', [], StaffHTTPService.settings.getSettlementVatRates, {
        retry: false,
        staleTime: FIVE_MINS_IN_MILLIS,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (settlementVatRatesData) {
            setFormData(settlementVatRatesData);
        }
    }, [setFormData, settlementVatRatesData]);

    const handleUpdateSettlementVatRatesSuccess = () =>
        displaySuccess(settingsTexts.settlements.vatRates.notifications.successfullyEdited);

    const {
        mutate: updateSettlementVatRates,
        error: updateSettlementVatRatesError,
        isLoading: isUpdateSettlementVatRatesLoading,
    } = useAppMutation(StaffHTTPService.settings.updateSettlementVatRates, {
        key: ['UPDATE_VAT_RATES'],
        onSuccess: handleUpdateSettlementVatRatesSuccess,
        invalidateQueryKeys: [['SETTLEMENT_VAT_RATES']],
    });

    const handleEditSettlementVatRatesFinish = (
        settlementVatRatesFormFields: SettlementVatRatesFormFields,
    ) =>
        updateSettlementVatRates({
            updateSettlementVatRatesSettingsDto: settlementVatRatesFormFields,
        });

    return (
        <PageBaseLayout isError={isSettlementVatRatesError}>
            <SettlementVatRatesEditForm
                form={form}
                initialValues={formInitialData}
                axiosError={updateSettlementVatRatesError}
                isLoading={isUpdateSettlementVatRatesLoading || isSettlementVatRatesFetching}
                onFinish={handleEditSettlementVatRatesFinish}
            />
        </PageBaseLayout>
    );
};
