import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { calendarTexts, commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const calendarEventAddEditErrorParser = (error: unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.FREE_FROM_WORK_CALENDAR_EVENT_LESSONS_CONFLICT) {
        return calendarTexts.freeFromWorkCalendarEventLessonsConflictErrorMessage;
    }

    return commonTexts.errorMessages.unknown;
};
