import { AppTableProps } from 'components/organisms';
import { JournalChildDTO } from 'types';
import { childrenTexts, journalTexts } from 'consts/text';
import { journalChildDTOFields } from 'consts/child/journalChildDTOFields';
import {
    AddressDisplay,
    ChildFirstAndLastNameDisplay,
    ChildGroupDisplay,
    ChildSexDisplay,
    ParentsNamesDisplay,
    ParentsPhoneNumbersDisplay,
} from 'components/molecules';
import { colors } from 'theme/styledComponents/colors';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

const columnsWithoutSensitiveDataLeftPart: AppTableProps<JournalChildDTO>['columns'] = [
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.firstAndLastName,
        dataIndex: journalChildDTOFields.firstName,
        key: journalChildDTOFields.firstName,
        render: (_, child) => <ChildFirstAndLastNameDisplay {...child} />,
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: childrenTexts.childrenListColumns.sex,
        dataIndex: journalChildDTOFields.sex,
        key: journalChildDTOFields.sex,
        render: (sex: JournalChildDTO['sex']) => <ChildSexDisplay sex={sex} />,
        align: 'center',
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.group,
        dataIndex: journalChildDTOFields.groups,
        key: journalChildDTOFields.groups,
        render: (groups: JournalChildDTO['groups']) => (
            <ChildGroupDisplay
                childGroups={groups}
                textType="BodyMediumSemiBold"
                color={colors.grayscale.gray9}
            />
        ),
        isVisibleAsExtendableInMobile: true,
    },
];

export const columnsWithoutSensitiveDataRightPart: AppTableProps<JournalChildDTO>['columns'] = [
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.joinDate,
        dataIndex: journalChildDTOFields.joinDate,
        key: journalChildDTOFields.joinDate,
        render: (joinDate: JournalChildDTO['joinDate']) =>
            parseStringIntoFormattedStringDate(joinDate),
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.leaveDate,
        dataIndex: journalChildDTOFields.leaveDate,
        key: journalChildDTOFields.leaveDate,
        render: (leaveDate: JournalChildDTO['leaveDate']) =>
            leaveDate ? parseStringIntoFormattedStringDate(leaveDate) : '',
        isVisibleAsExtendableInMobile: true,
    },
];

export const columnsWithoutSensitiveData: AppTableProps<JournalChildDTO>['columns'] = [
    ...columnsWithoutSensitiveDataLeftPart,
    ...columnsWithoutSensitiveDataRightPart,
];

export const columnsWithSensitiveData: AppTableProps<JournalChildDTO>['columns'] = [
    ...columnsWithoutSensitiveDataLeftPart,
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.parentName,
        dataIndex: journalChildDTOFields.parents,
        key: `${journalChildDTOFields.parents}/name`,
        render: (parents: JournalChildDTO['parents']) =>
            parents ? <ParentsNamesDisplay parents={parents} /> : EMPTY_VALUE_LABEL,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.phoneNumber,
        dataIndex: journalChildDTOFields.parents,
        key: `${journalChildDTOFields.parents}/phoneNumber`,
        render: (parents: JournalChildDTO['parents']) =>
            parents ? <ParentsPhoneNumbersDisplay parents={parents} /> : EMPTY_VALUE_LABEL,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: journalTexts.journalDetails.groupJournal.children.columns.address,
        dataIndex: journalChildDTOFields.address,
        key: journalChildDTOFields.address,
        render: (address: JournalChildDTO['address']) => <AddressDisplay address={address} />,
        isVisibleAsExtendableInMobile: true,
    },
    ...columnsWithoutSensitiveDataRightPart,
];
