import { AxiosError } from 'axios';
import { CommonFormProps, FormModeDependedTexts, MenuFormFields } from 'types';
import { AppForm, AppFormProps } from 'components/organisms';
import {
    AttachmentsUploadElement,
    DayPeriodPickerElement,
    FormNavButtons,
    SimpleInputElement,
} from 'components/molecules';
import { commonTexts, menusTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import { AppFormItem } from 'components/atoms';
import { menuAddEditErrorParser } from 'utils/errorHandlers/menu/menuAddEditErrorParser';
import { max300LengthRule } from 'consts/rules/textRules';
import { MENU_ATTACHMENT_FORMATS } from 'consts/file/fileFormats';

const addModeTexts: FormModeDependedTexts = {
    name: 'menuAdd',
    title: menusTexts.addMenu,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'menuEdit',
    title: menusTexts.editMenu,
    buttonText: commonTexts.actionLabels.save,
};

type MenuAddEditFormProps = Omit<
    AppFormProps<MenuFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (menuData: MenuFormFields) => void;
    };
export const MenuAddEditForm = ({
    mode,
    initialValues,
    axiosError,
    ...props
}: MenuAddEditFormProps) => {
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const menuAddEditError = axiosError ? menuAddEditErrorParser(axiosError) : '';

    return (
        <AppForm<MenuFormFields>
            name={name}
            title={title}
            error={menuAddEditError}
            withGoBack
            {...props}
        >
            <SimpleInputElement
                name="name"
                label={menusTexts.menuName}
                rules={[max300LengthRule]}
            />
            <AppFormItem justifyContent="space-between" doubleItem>
                <DayPeriodPickerElement
                    mode="start"
                    endField={formFields.dateTo}
                    startField={formFields.dateFrom}
                    label={menusTexts.appliesFrom}
                />
                <DayPeriodPickerElement
                    mode="finish"
                    endField={formFields.dateTo}
                    startField={formFields.dateFrom}
                    label={menusTexts.appliesTo}
                />
            </AppFormItem>
            <AttachmentsUploadElement
                uploadDirectory="MENU"
                accept={MENU_ATTACHMENT_FORMATS}
                optional
            />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
                marginBottom={0}
            />
        </AppForm>
    );
};
