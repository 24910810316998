import { JournalChildWithParentsBasicInfoDto, ParentId, SelectOptionType } from 'types';
import { journalChildDTOFields } from 'consts/child/journalChildDTOFields';
import { parseParentBaseIntoSelectOptionType } from 'utils/parsers/parent/parseParentBaseIntoSelectOptionType';

export const getParentsSelectOptionsFromJournalChildren = (
    journalChildren: JournalChildWithParentsBasicInfoDto[],
) =>
    journalChildren.reduce<SelectOptionType<ParentId>[]>((allParentsOptions, currentChild) => {
        const currentChildParentsOptions: SelectOptionType<ParentId>[] = currentChild[
            journalChildDTOFields.parents
        ].map(parseParentBaseIntoSelectOptionType);
        return allParentsOptions.concat(currentChildParentsOptions);
    }, []);
