import {
    ChildCostAllocation,
    MonthlySettlementSimpleDTO,
    CostAllocationId,
    ChildId,
    ChildSettlementId,
    ChildBillComponent,
} from 'types';
import { DetailsColumn, DetailsFrameBase, DetailsRow, Option } from 'components/atoms';
import { commonTexts, settlementTexts } from 'consts/text';
import {
    GridItem,
    ParentGrid,
} from 'components/templates/child/ChildDetails/InfoContainerBase.styled';
import { IconArrowsSplit, IconEdit, IconRefresh, IconTrash } from '@tabler/icons-react';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { ChildrenBillComponentsTable } from 'components/templates/settlement/ChildrenBillPartsTable/ChildrenBillComponentsTable';
import styled, { useTheme } from 'styled-components';
import { generatePath, useParams } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { Dispatch, useMemo } from 'react';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { AppCardList } from 'components/organisms';
import { referToMapper } from 'consts/settlementChildren/referToMapper';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { parseObjectsIntoSettlementArray } from 'utils/parsers/settlementChildren/parseObjectsIntoSettelmentArray';
import { getBillTitle } from 'utils/getBillTitle';
import { getDateRangeText } from 'utils/getDateRangeText';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';
import { childSettlementRecalculateErrorParser } from 'utils/errorHandlers/childSettlement/childSettlementRecalculateErrorParser';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const ItemContainer = styled.div`
    margin-block-start: 32px;
`;

export const ChildSettlementItem = ({
    removeObject,
    id,
    startDate,
    endDate,
    verified,
    totalGrossCost,
    mealSettlement,
    lessonSettlement,
    staySettlement,
    costSplits,
    child,
    isChildCostSplitsError,
}: MonthlySettlementSimpleDTO & {
    costSplits?: ChildCostAllocation[];
    isChildCostSplitsError: boolean;
    removeObject: Dispatch<{
        removeTarget: 'costAllocation' | 'splitPayment';
        removeId: ChildSettlementId | CostAllocationId;
    }>;
}) => {
    const theme = useTheme();
    const { displaySuccess, displayError } = useSimpleNotification();
    const { childId } = useParams<{ childId: ChildId }>();

    const { statefulNavigate } = useNavigateWithState();

    const settlementCalculated =
        mealSettlement.mealCosts.length ||
        lessonSettlement.lessonCosts.length ||
        staySettlement.daysPriceSummaries?.length ||
        staySettlement.hoursPriceSummaries?.length;

    const costAllocationOptions: Option<ChildCostAllocation>[] = [
        {
            label: commonTexts.actionLabels.edit,
            onClick: (record) =>
                statefulNavigate(
                    generatePath(
                        appPaths.app.settlement.children.pickedChild['cost-allocation'].edit
                            .pickedCostAllocation,
                        { childId, costAllocationId: record.id },
                    ),
                ),
            checkIfVisible: () => !verified,
            Icon: <IconEdit />,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: (record) => {
                removeObject({ removeTarget: 'costAllocation', removeId: record.id });
            },
            checkIfVisible: () => !verified,
            Icon: <IconTrash />,
        },
    ];

    const billComponents: ChildBillComponent[] | null = useMemo(
        () =>
            parseObjectsIntoSettlementArray({
                staySettlement,
                lessonSettlement,
                mealSettlement,
            }),
        [staySettlement, mealSettlement, lessonSettlement],
    );

    const { mutate: recalculateSettlement } = useAppMutation(
        StaffHTTPService.settlements.recalculateSettlement,
        {
            key: ['SETTLEMENT_RECALCULATE'],
            onSuccess: () =>
                displaySuccess(
                    settlementTexts.children.recalculate.successfullyRecalculatedSettlementMessage,
                ),
            onError: (error) => displayError(childSettlementRecalculateErrorParser(error)),
            invalidateQueryKeys: [
                ['SETTLEMENTS'],
                ['CHILD_SETTLEMENT'],
                ['CHILD_SETTLEMENT_PAYMENT'],
            ],
        },
    );

    const optionButtonOptions = [
        {
            label: settlementTexts.children.actions.splitCost,
            onClick: () =>
                statefulNavigate(
                    generatePath(
                        appPaths.app.settlement.children.pickedChild['cost-allocation'].add.base,
                        {
                            childId,
                        },
                    ),
                    { state: { monthlySettlementId: id } },
                ),
            checkIfVisible: () => !verified,
            Icon: <IconArrowsSplit />,
        },
        {
            label: settlementTexts.children.actions.recalculateBill,
            onClick: () => recalculateSettlement({ settlementId: id, childId: childId as ChildId }),
            checkIfVisible: () => !verified,
            Icon: <IconRefresh />,
        },
    ];

    const parsedStartDate = parseStringIntoFormattedStringDate(
        startDate,
        'YYYY-MM-DD',
        'DD/MM/YYYY',
    );

    const parsedToMonthDate = parseStringIntoFormattedStringDate(
        startDate,
        'YYYY-MM-DD',
        'MM/YYYY',
    );
    const parsedEndDate = parseStringIntoFormattedStringDate(endDate, 'YYYY-MM-DD', 'DD/MM/YYYY');
    const amountToPayInfo = settlementCalculated
        ? getFormattedPrice(totalGrossCost)
        : EMPTY_VALUE_LABEL;

    return (
        <ItemContainer>
            <DetailsFrameBase
                title={getDateRangeText(parsedStartDate, parsedEndDate)}
                smallHeaderMargin
                options={optionButtonOptions}
                record={{ id }}
            >
                <ParentGrid>
                    <GridItem width={12}>
                        <DetailsFrameBase
                            title={settlementTexts.children.details.monthlyBill}
                            headerColor={theme.colors.primary.primary9}
                            noFrame
                            smallHeaderMargin
                            noMarginLeft
                        >
                            <DetailsRow noMarginTop>
                                <DetailsColumn
                                    title={settlementTexts.children.details.billTitle}
                                    span={8}
                                >
                                    {getBillTitle(parsedToMonthDate, child)}
                                </DetailsColumn>
                                <DetailsColumn
                                    title={settlementTexts.children.details.issueData}
                                    span={4}
                                >
                                    {settlementCalculated ? parsedStartDate : EMPTY_VALUE_LABEL}
                                </DetailsColumn>
                                <DetailsColumn
                                    title={settlementTexts.children.details.amountToPay}
                                    span={4}
                                >
                                    {amountToPayInfo}
                                </DetailsColumn>
                            </DetailsRow>
                        </DetailsFrameBase>
                    </GridItem>
                    <GridItem width={12}>
                        <DetailsFrameBase
                            title={settlementTexts.children.details.billComponents}
                            headerColor={theme.colors.primary.primary9}
                            noFrame
                            smallHeaderMargin
                            noMarginLeft
                        >
                            <ChildrenBillComponentsTable billComponents={billComponents} />
                        </DetailsFrameBase>
                    </GridItem>
                    <GridItem width={12}>
                        <AppCardList
                            isHiddenAddButton
                            isHiddenHeader
                            isError={isChildCostSplitsError}
                            marginTop={16}
                            bordered={false}
                            emptyInfo={settlementTexts.children.costAllocation.emptyInfo}
                            dataSource={costSplits}
                            renderItem={(item, index) => (
                                <DetailsFrameBase
                                    key={index}
                                    smallHeaderMargin
                                    title={settlementTexts.children.details.costSplit}
                                    options={costAllocationOptions}
                                    record={item}
                                    noMarginLeft
                                    headerColor={theme.colors.primary.primary9}
                                >
                                    <DetailsRow>
                                        <DetailsColumn
                                            title={settlementTexts.children.details.foundationName}
                                            span={4}
                                        >
                                            {item.foundationName}
                                        </DetailsColumn>
                                        <DetailsColumn
                                            title={settlementTexts.children.details.nip}
                                            span={4}
                                        >
                                            {item.nip}
                                        </DetailsColumn>
                                        <DetailsColumn
                                            title={settlementTexts.children.details.refersTo}
                                            span={4}
                                        >
                                            {referToMapper[item.refersTo]}
                                        </DetailsColumn>
                                        <DetailsColumn
                                            title={settlementTexts.children.details.amount}
                                            span={4}
                                        >
                                            {item.specificAmount
                                                ? getFormattedPrice(item.specificAmount)
                                                : `${item.percentageAmount}%`}
                                        </DetailsColumn>
                                    </DetailsRow>
                                </DetailsFrameBase>
                            )}
                        />
                    </GridItem>
                </ParentGrid>
            </DetailsFrameBase>
        </ItemContainer>
    );
};
