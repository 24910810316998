import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { AppActions } from 'types';
import { useAppAbility } from '../useAppAbility';

export const useChildAbilities = () => {
    const ability = useAppAbility();

    const childAbilities = {
        checkIfCanViewChildrenList: () =>
            ability.can(AppActions.VIEW, PERMISSIONS_NAME.CHILDREN_LIST),
        checkIfCanManageChildrenList: () =>
            ability.can(AppActions.EDIT_AND_ADD, PERMISSIONS_NAME.CHILDREN_LIST),
        checkIfCanArchiveChildren: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.CHILDREN_ARCHIVING),
        checkIfCanSeeSensitiveData: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.CHILDREN_LIST_VIEW_SENSITIVE),
    };

    return { childAbilities };
};
