import type { HistoryChangeRefersToSub, LabelMapper } from 'types';
import { settingsTexts } from 'consts/text';

export const historyChangeRefersToSubLabels: LabelMapper<HistoryChangeRefersToSub> = {
    CHILD_ABSENCE: settingsTexts.historyOfChanges.refersToSubLabels.childAbsence,
    DAILY_SCHEDULE: settingsTexts.historyOfChanges.refersToSubLabels.dailySchedule,
    EMPLOYEE_ABSENCE: settingsTexts.historyOfChanges.refersToSubLabels.employeeAbsence,
    GENERAL: settingsTexts.historyOfChanges.refersToSubLabels.general,
    GROUP_CONSULTATION: settingsTexts.historyOfChanges.refersToSubLabels.groupConsultation,
    GROUP_TOPIC: settingsTexts.historyOfChanges.refersToSubLabels.groupTopic,
    IMPORTANT_EVENT: settingsTexts.historyOfChanges.refersToSubLabels.importantEvent,
    INSPECTION: settingsTexts.historyOfChanges.refersToSubLabels.inspection,
    LESSON: settingsTexts.historyOfChanges.refersToSubLabels.lesson,
    NONE: settingsTexts.historyOfChanges.refersToSubLabels.none,
    MEAL_SETTINGS: settingsTexts.historyOfChanges.refersToSubLabels.mealSettings,
    PARENT_TEACHER_CONFERENCE:
        settingsTexts.historyOfChanges.refersToSubLabels.parentTeacherConference,
    PSYCHOLOGICAL_HELP: settingsTexts.historyOfChanges.refersToSubLabels.psychologicalHelp,
    QUALIFICATION_BASIS: settingsTexts.historyOfChanges.refersToSubLabels.qualificationBasis,
    SPECIAL_ATTENDANCE: settingsTexts.historyOfChanges.refersToSubLabels.specialAttendance,
    SPECIAL_CONSULTATION: settingsTexts.historyOfChanges.refersToSubLabels.specialConsultation,
    TRIP: settingsTexts.historyOfChanges.refersToSubLabels.trip,
    SPECIAL_JOURNAL: settingsTexts.historyOfChanges.refersToSubLabels.specialJournal,
    SPECIAL_TOPIC: settingsTexts.historyOfChanges.refersToSubLabels.specialTopic,
    WEEKLY_SCHEDULE: settingsTexts.historyOfChanges.refersToSubLabels.weeklySchedule,
    WORKING_TIME: settingsTexts.historyOfChanges.refersToSubLabels.workingTime,
    INVOICE_DATA: settingsTexts.historyOfChanges.refersToSubLabels.invoiceData,
    STAY_SETTINGS: settingsTexts.historyOfChanges.refersToSubLabels.staySettings,
    COURSES_CONFIG: settingsTexts.historyOfChanges.refersToSubLabels.coursesConfig,
    TRANSACTION: settingsTexts.historyOfChanges.refersToSubLabels.transaction,
    GENERAL_SETTINGS: settingsTexts.historyOfChanges.refersToSubLabels.generalSettings,
    COST_SPLIT: settingsTexts.historyOfChanges.refersToSubLabels.costSplit,
    VAT_RATES_SETTINGS: settingsTexts.historyOfChanges.refersToSubLabels.vatRatesSettings,
};
