import { IconDeviceFloppy } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import { FormNavButtons, VatRatePickerElement } from 'components/molecules';
import { AppForm, AppFormProps } from 'components/organisms';
import { commonTexts, settingsTexts } from 'consts/text';
import { SettlementVatRatesFormFields } from 'types';
import { formFields } from 'consts/form/formFields';

export type SettlementVatRatesEditFormProps = Omit<
    AppFormProps<SettlementVatRatesFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> & {
    axiosError?: AxiosError | null;
    isLoading?: boolean;
    onFinish: (settlementsStayData: SettlementVatRatesFormFields) => void;
};

export const SettlementVatRatesEditForm = ({
    form,
    axiosError,
    initialValues,
    ...props
}: SettlementVatRatesEditFormProps) => {
    const settlementVatRatesEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<SettlementVatRatesFormFields>
            maxWidth={350}
            withHeader={false}
            form={form}
            name="vatRatesEdit"
            error={settlementVatRatesEditError}
            {...props}
        >
            <VatRatePickerElement
                name={formFields.mealVatRateType}
                label={settingsTexts.settlements.vatRates.formLabels.vatRateForMeal}
                allowClear={false}
            />
            <VatRatePickerElement
                name={formFields.stayVatRateType}
                label={settingsTexts.settlements.vatRates.formLabels.vatRateForStay}
                allowClear={false}
            />
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={commonTexts.actionLabels.save}
                leftIcon={<IconDeviceFloppy size={20} />}
            />
        </AppForm>
    );
};
