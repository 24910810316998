import {
    AppFormItem,
    AppFormItemProps,
    AppInput,
    FetchByField,
    FetchGUSInformationButton,
} from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { commonTexts, settingsTexts } from 'consts/text/index';
import { nipNumberRegExp } from 'consts/regexps';
import { validatePolish } from 'validate-polish';
import { CostAllocationFormFields, GUSInformationDTO } from 'types';
import { InvoiceEditFormFields } from 'types/forms/invoice';

export type NipInputWithFetchElementProps = AppFormItemProps & {
    parseInstitutionGusDataIntoFormData: (
        data: GUSInformationDTO,
    ) => Partial<InvoiceEditFormFields> | Partial<CostAllocationFormFields>;
};

export const NipInputWithFetchElement = ({
    parseInstitutionGusDataIntoFormData,
    ...props
}: NipInputWithFetchElementProps) => (
    <>
        <AppFormItem
            label={settingsTexts.invoiceData.form.nipNumberLabel}
            name={formFields.nip}
            marginBottom={6}
            rules={[
                {
                    pattern: nipNumberRegExp,
                    message: commonTexts.errorMessages.invalidNipNumber,
                    required: true,
                },
                {
                    validator: (_, value) =>
                        validatePolish.nip(value)
                            ? Promise.resolve()
                            : Promise.reject(new Error(commonTexts.errorMessages.invalidNipNumber)),
                    message: commonTexts.errorMessages.invalidNipNumber,
                },
            ]}
            {...props}
        >
            <AppInput autoComplete="off" />
        </AppFormItem>
        <FetchGUSInformationButton
            fetchByField={FetchByField.NIP}
            parseInstitutionGusDataIntoFormData={parseInstitutionGusDataIntoFormData}
        />
    </>
);
