import { SimpleSelectElement, SimpleSelectElementProps } from 'components/molecules';
import { journalTexts } from 'consts/text';
import { LessonDTO } from 'types';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { parseStringIntoDayjsTime } from 'utils/parsers/dateTime/parseStringIntoDayjsTime';
import { formFields } from 'consts/form/formFields';
import { useMemo } from 'react';
import { parseStringIntoDayName } from 'utils/parsers/dateTime/parseStringIntoDayName';
import { getFullName } from 'utils/getFullName';

type SpecialTopicPickerProps = {
    specialLessonsData?: LessonDTO[];
    onChange: (topic?: LessonDTO) => void;
    isLoading: boolean;
    disabled?: boolean;
} & Pick<SimpleSelectElementProps, 'hidden'>;

export const SpecialTopicPicker = ({
    specialLessonsData,
    onChange,
    isLoading,
    disabled,
    hidden,
}: SpecialTopicPickerProps) => {
    const { setFieldsValue } = useFormInstance();

    const topicOptions = useMemo(
        () =>
            specialLessonsData?.map((lesson) => {
                const formattedTime = lesson.timeFrom.slice(0, -3);
                return {
                    value: lesson.id,
                    label: `${lesson.name} ${parseStringIntoDayName(lesson.date)}
                 ${formattedTime}`,
                };
            }),
        [specialLessonsData],
    );

    const topicSelectHandle = (topicId: string) => {
        const selectedTopic = specialLessonsData?.find((lesson) => lesson.id === topicId);

        if (!selectedTopic) {
            onChange(undefined);
            return;
        }

        setFieldsValue({
            timeFrom: parseStringIntoDayjsTime(selectedTopic.timeFrom),
            timeTo: parseStringIntoDayjsTime(selectedTopic.timeTo),
            teacherFullName: selectedTopic.teacher
                ? getFullName(selectedTopic.teacher.firstName, selectedTopic.teacher.lastName)
                : '',
        });
        onChange(selectedTopic);
    };
    return (
        <SimpleSelectElement
            name={formFields.lessonId}
            label={journalTexts.journalDetails.groupJournal.topics.topicLabel}
            options={topicOptions}
            loading={isLoading}
            disabled={disabled}
            hidden={hidden}
            onChange={topicSelectHandle}
        />
    );
};
