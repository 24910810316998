import {
    AppFormItem,
    AppFormItemProps,
    AppInput,
    FetchByField,
    FetchGUSInformationButton,
} from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { authTexts } from 'consts/text/index';
import { regonValidator } from 'utils/validators';
import { parseGUSInformationDTOIntoInstitutionAddEditFormFields } from 'utils/parsers/gus/parseGUSInformationDTOIntoInstitutionAddEditFormFields';

export type RegonInputWithFetchElementProps = AppFormItemProps;

export const RegonInputWithFetchElement = ({ ...props }: RegonInputWithFetchElementProps) => (
    <>
        <AppFormItem
            label="Numer REGON"
            name={formFields.regonNumber}
            marginBottom={6}
            rules={[
                {
                    validator: (_, value) =>
                        !value || regonValidator(value)
                            ? Promise.resolve()
                            : Promise.reject(new Error(authTexts.wrongRegonFormatMessage)),
                    message: authTexts.wrongRegonFormatMessage,
                },
            ]}
            {...props}
        >
            <AppInput autoComplete="off" />
        </AppFormItem>
        <FetchGUSInformationButton
            fetchByField={FetchByField.REGON}
            parseInstitutionGusDataIntoFormData={
                parseGUSInformationDTOIntoInstitutionAddEditFormFields
            }
        />
    </>
);
