import { colors } from 'theme/styledComponents/colors';
import { AppText } from 'components/atoms/CommonAppComponents';

type PersonAbsenceInfoProps = { fullName: string; absentInfo: string; isAbsent: boolean };

export const PersonAbsenceInfo = ({ fullName, absentInfo, isAbsent }: PersonAbsenceInfoProps) => {
    if (!isAbsent) {
        return fullName;
    }

    return (
        <>
            {fullName}
            <AppText textType="BodySmall" margin={0} color={colors.red.red6} marginLeft={12}>
                {absentInfo}
            </AppText>
        </>
    );
};
