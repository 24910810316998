import { useLocation, useNavigate } from 'react-router-dom';
import { LessonAddEditForm } from 'components/templates';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { timetableTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { EmployeeId, LessonFormFields } from 'types';
import { parseLessonFormFieldsIntoAddLessonDTO } from 'utils/parsers/lesson/parseLessonFormFieldsIntoAddLessonDTO';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const LessonAddPage = () => {
    const { displaySuccess } = useSimpleNotification();
    const navigate = useNavigate();
    const { statefulNavigate } = useNavigateWithState();
    const location = useLocation();
    const employeeId = location.state.employeeId as { employeeId?: EmployeeId };

    const onAddLessonSuccess = () => {
        displaySuccess(timetableTexts.lessons.successfullyAddedLessonsMessage);
        if (employeeId) {
            navigate(-1);
        } else {
            statefulNavigate(appPaths.app.timetable.employeeSchedule.base, { replace: true });
        }
    };

    const {
        mutate: addLesson,
        error: addLessonError,
        isLoading: isAddLessonLoading,
    } = useAppMutation(StaffHTTPService.employeeSchedule.addLesson, {
        key: ['ADD_LESSON'],
        onSuccess: onAddLessonSuccess,
        invalidateQueryKeys: [
            ['LESSONS'],
            ['LESSONS_WITHOUT_TOPIC'],
            ['EMPLOYEE_SCHEDULE'],
            ['SPECIAL_DAILY_ATTENDANCE'],
            ['SETTLEMENT_EXTRA_LESSONS'],
        ],
    });

    const onAddLessonFinish = (lessonData: LessonFormFields) =>
        addLesson(parseLessonFormFieldsIntoAddLessonDTO(lessonData));

    return (
        <LessonAddEditForm
            mode="add"
            axiosError={addLessonError}
            isLoading={isAddLessonLoading}
            onFinish={onAddLessonFinish}
        />
    );
};
