import { AppActions } from 'types';
import { PERMISSIONS_NAME } from 'consts/permissions/permissions';
import { useAppAbility } from '../useAppAbility';

export const useSettlementAbilities = () => {
    const ability = useAppAbility();

    const settlementAbilities = {
        checkIfCanVerifySettlement: () =>
            ability.can(AppActions.DO_ACTION, PERMISSIONS_NAME.SETTLEMENT_VERIFICATION),
    };

    return {
        settlementAbilities,
    };
};
