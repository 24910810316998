import { ReactNode, useEffect } from 'react';
import { AppFormItem, AppRadioButton, AppRadioGroup, ConditionalRender } from 'components/atoms';
import {
    CityInputElement,
    InstitutionAffiliationPickerElement,
    InstitutionNameInputElement,
    InstitutionTypePickerElement,
    NurseryRegisterNumberInputElement,
    RegonInputWithFetchElement,
    RspoNumberInputElement,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { InstitutionAddEditFormFields, InstitutionIdentifier } from 'types';
import { institutionTexts } from 'consts/text';
import { formFields } from 'consts/form/formFields';
import useForm from 'antd/es/form/hooks/useForm';
import { useWatch } from 'antd/es/form/Form';
import { AppForm, AppFormProps } from '../AppForm/AppForm';

export type InstitutionAddFormProps = AppFormProps<InstitutionAddEditFormFields> & {
    formNavButtons: ReactNode;
    mode: 'add' | 'edit';
};

const DEFAULT_INSTITUTION_IDENTIFIER = InstitutionIdentifier.RSPO;

export const InstitutionAddEditForm = ({
    formNavButtons,
    mode,
    form,
    initialValues,
    ...props
}: InstitutionAddFormProps) => {
    const [formInstance] = useForm(form);

    const institutionIdentifier = useWatch(formFields.institutionIdentifier, formInstance);

    useEffect(() => {
        if (initialValues) {
            return;
        }
        formInstance.setFieldValue(
            formFields.institutionIdentifier,
            DEFAULT_INSTITUTION_IDENTIFIER,
        );
    }, [formInstance, initialValues]);

    const isEditMode = mode === 'edit';
    const formTitle = isEditMode ? 'Edytuj placówkę' : 'Dodaj placówkę';

    return (
        <AppForm<InstitutionAddEditFormFields> title={formTitle} form={formInstance} {...props}>
            <RegonInputWithFetchElement />
            <InstitutionNameInputElement />
            <AppFormItem
                label={institutionTexts.institutionIdentifier}
                name={formFields.institutionIdentifier}
            >
                <AppRadioGroup>
                    <AppRadioButton value={InstitutionIdentifier.RSPO}>
                        {institutionTexts.form.rspoNumber}
                    </AppRadioButton>
                    <AppRadioButton value={InstitutionIdentifier.NURSERY_REGISTER_NUMBER}>
                        {institutionTexts.form.nurseryRegistryNumber}
                    </AppRadioButton>
                </AppRadioGroup>
            </AppFormItem>
            <ConditionalRender condition={institutionIdentifier === InstitutionIdentifier.RSPO}>
                <RspoNumberInputElement />
            </ConditionalRender>
            <ConditionalRender
                condition={institutionIdentifier === InstitutionIdentifier.NURSERY_REGISTER_NUMBER}
            >
                <NurseryRegisterNumberInputElement />
            </ConditionalRender>
            <StreetAddressInputElement />
            <AppFormItem justifyContent="space-between" doubleItem>
                <ZipCodeInputElement />
                <CityInputElement />
            </AppFormItem>
            <InstitutionAffiliationPickerElement />
            <InstitutionTypePickerElement />
            {formNavButtons}
        </AppForm>
    );
};
