import { AppConfirmModal, AppConfirmModalProps } from 'components/organisms';
import { commonTexts, settlementTexts } from 'consts/text';
import dayjs from 'dayjs';
import { UserDollar } from 'assets';
import { FULL_MONTH_FORMAT } from 'consts/dateFormats';
import {
    MULTIPLE_SETTLEMENTS_TEXTS,
    ONE_SETTLEMENT_TEXTS,
    VerifySettlementsModalMode,
} from './VerifySettlementsModal.consts';

type VerifySettlementsModalProps = Pick<
    AppConfirmModalProps,
    'onCancel' | 'onOk' | 'confirmActionLoading' | 'open'
> & {
    mode: VerifySettlementsModalMode;
    monthNumber: number;
};

export const VerifySettlementsModal = ({
    mode,
    monthNumber,
    confirmActionLoading,
    open,
    onCancel,
    onOk,
}: VerifySettlementsModalProps) => {
    const monthName = dayjs().set('month', monthNumber).format(FULL_MONTH_FORMAT);

    const { titleFirstPart, description } =
        mode === VerifySettlementsModalMode.ONE_SETTLEMENT
            ? ONE_SETTLEMENT_TEXTS
            : MULTIPLE_SETTLEMENTS_TEXTS;
    const fullTitle = `${titleFirstPart} ${monthName} ${settlementTexts.children.verifySettlements.verifySettlementsTitleQuestionMark}`;

    return (
        <AppConfirmModal
            open={open}
            width={420}
            icon={<UserDollar />}
            title={fullTitle}
            text={description}
            okButtonProps={{
                title: settlementTexts.children.verifySettlements.verifySettlementsLabel,
                danger: true,
            }}
            cancelButtonProps={{ title: commonTexts.actionLabels.goBack }}
            confirmActionLoading={confirmActionLoading}
            onOk={onOk}
            onCancel={onCancel}
        />
    );
};
