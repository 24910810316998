import { generatePath } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { AppTableProps } from 'components/organisms';
import { SettlementChildDTO, SettlementChildrenDtoFieldWithSorting } from 'types';
import { settlementTexts } from 'consts/text';
import { settlementChildrenDTOFields } from 'consts/settlementChildren/settlementChildrenDTOFields';
import { BalanceTag, FirstAndLastNameDisplay } from 'components/molecules';
import { appPaths } from 'consts/paths/paths';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { AccountActiveMarkIcon, AccountInactiveMarkIcon } from 'assets';

export const getColumns: (defaultSortOrder?: {
    column: SettlementChildrenDtoFieldWithSorting;
    order: SortOrder;
}) => AppTableProps<SettlementChildDTO>['columns'] = (sortOrder) => {
    const { column, order } = sortOrder || { order: undefined, column: undefined };

    return [
        {
            title: settlementTexts.children.columnLabels.fullName,
            dataIndex: settlementChildrenDTOFields.lastName,
            key: settlementChildrenDTOFields.lastName,
            sortOrder: column === settlementChildrenDTOFields.lastName ? order : undefined,
            sorter: true,
            render: (_, record) => (
                <FirstAndLastNameDisplay
                    firstName={record.child.firstName}
                    lastName={record.child.lastName}
                    to={generatePath(appPaths.app.settlement.children.pickedChild.details.base, {
                        childId: record.child.id,
                    })}
                />
            ),
        },
        {
            title: settlementTexts.children.columnLabels.group,
            dataIndex: settlementChildrenDTOFields.mainGroup,
            key: settlementChildrenDTOFields.mainGroup,
            align: 'center',
            isVisibleAsExtendableInMobile: true,
            sortOrder: column === settlementChildrenDTOFields.mainGroup ? order : undefined,
            sorter: true,
        },
        {
            title: settlementTexts.children.columnLabels.endOfContract,
            dataIndex: settlementChildrenDTOFields.settlementPeriodEnd,
            key: settlementChildrenDTOFields.settlementPeriodEnd,
            align: 'center',
            isVisibleAsExtendableInMobile: true,
            sortOrder:
                column === settlementChildrenDTOFields.settlementPeriodEnd ? order : undefined,
            sorter: true,
            render: (_, record) =>
                parseStringIntoFormattedStringDate(
                    record.settlementPeriodEnd,
                    'YYYY-MM-DD',
                    'DD/MM/YYYY',
                ),
        },
        {
            title: settlementTexts.children.columnLabels.stay,
            dataIndex: settlementChildrenDTOFields.stayGrossCost,
            key: settlementChildrenDTOFields.stayGrossCost,
            align: 'center',
            isVisibleAsExtendableInMobile: true,
            render: (_, record) => getFormattedPrice(record.stayGrossCost),
        },
        {
            title: settlementTexts.children.columnLabels.diet,
            dataIndex: settlementChildrenDTOFields.mealGrossCost,
            key: settlementChildrenDTOFields.mealGrossCost,
            align: 'center',
            isVisibleAsExtendableInMobile: true,
            render: (_, record) => getFormattedPrice(record.mealGrossCost),
        },
        {
            title: settlementTexts.children.columnLabels.additionalLessons,
            dataIndex: settlementChildrenDTOFields.lessonsGrossCost,
            key: settlementChildrenDTOFields.lessonsGrossCost,
            align: 'center',
            isVisibleAsExtendableInMobile: true,
            render: (_, record) => getFormattedPrice(record.lessonsGrossCost),
        },
        {
            title: settlementTexts.children.columnLabels.summary,
            dataIndex: settlementChildrenDTOFields.totalGrossCost,
            key: settlementChildrenDTOFields.totalGrossCost,
            align: 'center',
            isInvisibleInMobile: true,
            render: (_, record) => getFormattedPrice(record.totalGrossCost),
        },
        {
            title: settlementTexts.children.columnLabels.balance,
            dataIndex: settlementChildrenDTOFields.balance,
            key: settlementChildrenDTOFields.balance,
            align: 'center',
            render: (_, record) => <BalanceTag amount={record.balance} />,
        },
        {
            title: settlementTexts.children.columnLabels.verified,
            dataIndex: settlementChildrenDTOFields.verified,
            key: settlementChildrenDTOFields.verified,
            align: 'center',
            render: (verified: SettlementChildDTO['verified']) =>
                verified ? <AccountActiveMarkIcon /> : <AccountInactiveMarkIcon />,
        },
    ];
};
