import {
    AddChildCostAllocation,
    CostAllocationRefersTo,
    CostAllocationType,
} from 'types/settlementChildren/settlementChildren';
import { CostAllocationFormFields } from 'types';
import pick from 'lodash/pick';
import { parseFormattedBankAccountNumberIntoBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseFormattedBankAccountNumberIntoBankAccountNumber';

export const parseCostAllocationFormFieldsToAddCostAllocationDTO = (
    costAllocation: CostAllocationFormFields,
): AddChildCostAllocation => ({
    ...pick(costAllocation, ['foundationName', 'type', 'nip']),
    type: costAllocation.type as CostAllocationType,
    refersTo: costAllocation.refersTo as CostAllocationRefersTo,
    bankAccountNumber: costAllocation.bankAccountNumber
        ? parseFormattedBankAccountNumberIntoBankAccountNumber(costAllocation.bankAccountNumber)
        : null,
    percentageAmount: costAllocation.percentageAmount ? costAllocation.percentageAmount : null,
    specificAmount: costAllocation.specificAmount ? costAllocation.specificAmount : null,
    address: {
        ...pick(costAllocation, ['city', 'street', 'zipCode']),
    },
});
