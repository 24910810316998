import { CourseId } from 'types/lesson';
import { JournalId } from 'types/journal';
import { VatRate } from 'types/settlementVatRates';

export enum PaymentConfiguration {
    EACH_LESSON = 'EACH_LESSON',
    MONTHLY = 'MONTHLY',
}

export enum SettlementConfiguration {
    PREPAID = 'PREPAID',
    POSTPAID = 'POSTPAID',
}

export type AdditionalLessonDTO = {
    configured: boolean;
    courseName: string;
    price: number | null;
    paymentType: PaymentConfiguration | null;
    settlementType: SettlementConfiguration | null;
    id: CourseId;
    free: boolean | null;
    vatRateType: VatRate | null;
};

export type EditAdditionalLessonDTO = Pick<
    AdditionalLessonDTO,
    'settlementType' | 'paymentType' | 'price'
> &
    Required<Pick<AdditionalLessonDTO, 'free'>> & {
        vatRateType?: VatRate | null;
    };

export type AdditionalLessonJournalDTO = {
    journalId: JournalId;
    journalName: string;
};
