import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { AppActions, AppSubjects } from 'types';
import { useAppAbility } from 'abilities/useAppAbility';
import { appPaths } from 'consts/paths/paths';

type CanConfig = { action: AppActions; subject: AppSubjects };

export enum CanArrayMode {
    ALL = 'ALL',
    ANY = 'ANY',
}

type AbilityRouteProps = PropsWithChildren<{
    can: CanConfig | CanConfig[];
    canArrayMode?: CanArrayMode;
}>;

export const AbilityRoute = ({
    can,
    canArrayMode = CanArrayMode.ANY,
    children,
}: AbilityRouteProps) => {
    const ability = useAppAbility();

    if (Array.isArray(can)) {
        let hasNoPermission = true;
        if (canArrayMode === CanArrayMode.ALL) {
            hasNoPermission = can.some(({ action, subject }) => ability.cannot(action, subject));
        } else {
            hasNoPermission = can.every(({ action, subject }) => ability.cannot(action, subject));
        }
        if (hasNoPermission) {
            return <Navigate to={appPaths.error['not-found']} />;
        }
    } else {
        const { action, subject } = can;
        if (ability.cannot(action, subject)) {
            return <Navigate to={appPaths.error['not-found']} />;
        }
    }

    return children;
};
