import { settingsTexts } from 'consts/text';

export type LabelsOption = 'default' | 'month10day';

const LABELS: Record<LabelsOption, Record<'on' | 'off', string>> = {
    default: {
        on: settingsTexts.rolesAndPermissions.permissionsOnOff.on,
        off: settingsTexts.rolesAndPermissions.permissionsOnOff.off,
    },
    month10day: {
        on: settingsTexts.rolesAndPermissions.permissionSwitchLabels.EDIT_TO_10TH_DAY_OF_MONTH,
        off: settingsTexts.rolesAndPermissions.permissionSwitchLabels.EDIT_ALL_THE_TIME,
    },
};

export const getElements: (labelOptions: LabelsOption) => { label: string; value: boolean }[] = (
    labelOptions,
) => [
    {
        label: LABELS[labelOptions].off,
        value: false,
    },
    {
        label: LABELS[labelOptions].on,
        value: true,
    },
];
