import {
    AppModal,
    CurrencyInputElement,
    FormHeader,
    FormNavButtons,
    VatRatePickerElement,
} from 'components/molecules';
import { AppModalForm } from 'components/organisms';
import { HeaderContainer } from 'components/organisms/AppForm/AppForm.styled';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { commonTexts, settlementTexts } from 'consts/text';
import { AppCheckbox, AppFormItem, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { additionalLessonPaymentSelectMapper } from 'consts/additionalLessons/additionalLessonPaymentMapper';
import { additionalLessonSettlementMapper } from 'consts/additionalLessons/additionalLessonSettlementMapper';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useEffect } from 'react';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useWatch } from 'antd/es/form/Form';
import { AdditionalLessonsFormFields } from 'types/forms/additionalLessons';
import { AdditionalLessonDTO, PaymentConfiguration, SettlementConfiguration } from 'types';
import { parseAdditionalLessonsFormFieldsIntoEditAdditionalLessonDTO } from 'utils/parsers/additionalLesson/parseAdditionalLessonsFormFieldsIntoEditAdditionalLessonDTO';
import { parseAdditionalLessonDTOIntoAdditionalLessonsFormFields } from 'utils/parsers/additionalLesson/parseAdditionalLessonDTOIntoAdditionalLessonsFormFields';

type EditAdditionalLessonModalProps = {
    extraLesson: AdditionalLessonDTO;
    onClose: () => void;
};

export const EditAdditionalLessonModal = ({
    extraLesson,
    onClose,
}: EditAdditionalLessonModalProps) => {
    const { form, setFormData, formInitialData } = useEditForm<AdditionalLessonsFormFields>();
    const freeValue = useWatch(formFields.free, form);
    const { displaySuccess } = useSimpleNotification();

    useEffect(() => {
        if (!extraLesson) {
            return;
        }
        setFormData(parseAdditionalLessonDTOIntoAdditionalLessonsFormFields(extraLesson));
    }, [extraLesson, setFormData]);

    useEffect(() => {
        if (freeValue) {
            form.setFieldsValue({
                settlementType: null,
                additionalLessonPaymentType: null,
                price: null,
                vatRateType: null,
            });
        }
    }, [form, freeValue, setFormData]);

    const onEditExtraLessonsSuccess = () => {
        displaySuccess(settlementTexts.extraLessons.form.successfullyEditedExtraLessonsMessage);
        onClose();
    };

    const {
        mutate: editExtraLessons,
        error: editExtraLessonsError,
        isLoading: isEditExtraLessonsLoading,
    } = useAppMutation(StaffHTTPService.settlements.editExtraLessons, {
        key: ['EDIT_EXTRA_LESSONS'],
        onSuccess: onEditExtraLessonsSuccess,
        invalidateQueryKeys: [['SETTLEMENT_EXTRA_LESSONS']],
    });

    const extraLessonsEditErrorMessage = editExtraLessonsError
        ? commonTexts.errorMessages.unknown
        : '';

    const onFormSubmit = (values: AdditionalLessonsFormFields) => {
        editExtraLessons({
            id: extraLesson.id,
            editAdditionalLessonDTO:
                parseAdditionalLessonsFormFieldsIntoEditAdditionalLessonDTO(values),
        });
    };

    return (
        <AppModal width={440} open onCancel={onClose} isLoading={isEditExtraLessonsLoading}>
            <AppModalForm<AdditionalLessonsFormFields>
                name="extraLessonEdit"
                form={form}
                onFinish={onFormSubmit}
            >
                <HeaderContainer isFormHeaderAtBottom={false}>
                    <FormHeader
                        title={settlementTexts.extraLessons.formTitle}
                        desktopTitleLevel={4}
                        error={extraLessonsEditErrorMessage}
                    />
                </HeaderContainer>
                <AppFormItem
                    valuePropName="checked"
                    name={formFields.free}
                    marginBottom={12}
                    optional
                >
                    <AppCheckbox value>{settlementTexts.extraLessons.form.free}</AppCheckbox>
                </AppFormItem>
                <AppFormItem
                    label={settlementTexts.extraLessons.columnLabels.settlementConfig}
                    name={formFields.settlementType}
                    disabled={!!freeValue}
                    optional={!!freeValue}
                >
                    <AppRadioGroup disabled={!!freeValue}>
                        <AppRadioButton value={SettlementConfiguration.PREPAID}>
                            {additionalLessonSettlementMapper[SettlementConfiguration.PREPAID]}
                        </AppRadioButton>
                        <AppRadioButton value={SettlementConfiguration.POSTPAID}>
                            {additionalLessonSettlementMapper[SettlementConfiguration.POSTPAID]}
                        </AppRadioButton>
                    </AppRadioGroup>
                </AppFormItem>
                <AppFormItem
                    label={settlementTexts.extraLessons.columnLabels.paymentConfig}
                    name={formFields.additionalLessonPaymentType}
                    disabled={!!freeValue}
                    optional={!!freeValue}
                >
                    <AppRadioGroup disabled={!!freeValue}>
                        <AppRadioButton value={PaymentConfiguration.MONTHLY}>
                            {additionalLessonPaymentSelectMapper[PaymentConfiguration.MONTHLY]}
                        </AppRadioButton>
                        <AppRadioButton value={PaymentConfiguration.EACH_LESSON}>
                            {additionalLessonPaymentSelectMapper[PaymentConfiguration.EACH_LESSON]}
                        </AppRadioButton>
                    </AppRadioGroup>
                </AppFormItem>
                <VatRatePickerElement
                    disabled={!!freeValue}
                    optional={!!freeValue}
                    name={formFields.vatRateType}
                    label={settlementTexts.extraLessons.form.vatRate}
                    allowClear={false}
                />
                <CurrencyInputElement
                    name={formFields.price}
                    disabled={!!freeValue}
                    optional={!!freeValue}
                    isHalfParentWidth
                />
                <FormNavButtons
                    formValuesBeforeChanges={formInitialData}
                    buttonText={commonTexts.actionLabels.save}
                    withDeviceFloppy
                />
            </AppModalForm>
        </AppModal>
    );
};
