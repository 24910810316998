import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ChildAddEditCostAllocationForm } from 'components/templates/settlement/ChildAddEditCostAllocationForm/ChildAddEditCostAllocationForm';
import { ChildId, ChildSettlementId, CostAllocationFormFields } from 'types';
import { appPaths } from 'consts/paths/paths';
import { settlementTexts } from 'consts/text';
import useForm from 'antd/es/form/hooks/useForm';
import { parseCostAllocationFormFieldsToAddCostAllocationDTO } from 'utils/parsers/costAllocation/parseCostAllocationFormFieldsToAddCostAllocationDTO';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useCallback, useEffect } from 'react';

export const ChildrenAddCostAllocationPage = () => {
    const [form] = useForm<CostAllocationFormFields>();
    const { displaySuccess } = useSimpleNotification();
    const { childId } = useParams<{ childId: ChildId }>();
    const { state } = useLocation();
    const { statefulNavigate } = useNavigateWithState();

    const monthlySettlementId = state?.monthlySettlementId as ChildSettlementId | undefined;

    const goToChildSettlementDetails = useCallback(
        () =>
            statefulNavigate(
                generatePath(appPaths.app.settlement.children.pickedChild.details.base, {
                    childId: childId as ChildId,
                }),
            ),
        [childId, statefulNavigate],
    );

    useEffect(() => {
        if (!monthlySettlementId) {
            goToChildSettlementDetails();
        }
    }, [childId, monthlySettlementId, goToChildSettlementDetails]);

    const onAddCostAllocationSuccess = () => {
        displaySuccess(settlementTexts.children.costAllocation.addSuccess);
        goToChildSettlementDetails();
    };

    const {
        mutate: addCostAllocation,
        error: addCostAllocationError,
        isLoading: isAddCostAllocationLoading,
    } = useAppMutation(StaffHTTPService.settlements.addCostAllocation, {
        key: ['ADD_COST_ALLOCATION'],
        onSuccess: onAddCostAllocationSuccess,
        invalidateQueryKeys: [
            ['CHILD_SETTLEMENT', childId],
            ['CHILD_SETTLEMENT_COST_SPLIT', childId],
        ],
    });

    const onAddCostAllocationFinish = (costAllocationData: CostAllocationFormFields) =>
        addCostAllocation({
            costAllocation: parseCostAllocationFormFieldsToAddCostAllocationDTO(costAllocationData),
            monthlySettlementId: monthlySettlementId as ChildSettlementId,
        });

    return (
        <ChildAddEditCostAllocationForm
            mode="add"
            form={form}
            axiosError={addCostAllocationError}
            isLoading={isAddCostAllocationLoading}
            onFinish={onAddCostAllocationFinish}
        />
    );
};
