export const includesCapitalLetter = /([A-Z])/;
export const includesLowerCaseLetter = /([a-z])/;
export const isNumberOnly = /^[0-9]*$/;
export const zipCodeRegExp = /^\d{2}-\d{3}$/;
export const peselNumberRegExp = /^\d{11}$/;
export const idDocumentNumberRegExp = /^[A-Z]{3}\d{6}$/;
export const appLinkRegExp = /(https?:\/\/[^\s]+)/g;
export const spacesAndNewLinesRegExp = /[\s\n]+/g;
export const nipNumberRegExp = /^\d{10}$/;
export const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const nurseryRegisterNumberRegExp = /^\d+\/Z$/;
