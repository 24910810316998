import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ChildId, ChildSettlementDetails } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { getFullName } from 'utils/getFullName';
import { AddressData, ErrorDisplay, LoadingDisplay } from 'components/molecules';
import { AppGoBackButton } from 'components/atoms';
import { childrenTexts } from 'consts/text';
import { ChildAddressDataIcon } from 'assets';
import { childFields } from 'consts/child/childFields';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { Header } from './components/Header/Header';
import { DobleRow, GridItem, ParentGrid } from './InfoContainerBase.styled';
import {
    AdditionalInformation,
    Agreements,
    Discounts,
    Parents,
    PersonalData,
    Settlements,
} from './components';
import { Guardians } from './components/Guardians/StaffGuardians/Guardians';

type ChildDetailsProps = {
    pageRoute: string;
    childEditPagePath: string;
    canSeeSensitiveData: boolean;
};

export const ChildDetails = ({
    pageRoute,
    childEditPagePath,
    canSeeSensitiveData,
}: ChildDetailsProps) => {
    const [_, setSearchParams] = useSearchParams();
    const { childId: childIdParam } = useParams();

    const childId = (childIdParam || '') as ChildId;

    const {
        data: childData,
        isFetching: isChildFetching,
        isError,
    } = useAppQuery('CHILD', [childId], () => StaffHTTPService.children.getChild(childId), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    useEffect(() => {
        if (!childData) {
            return;
        }
        const params = new URLSearchParams(window.location.search);
        params.set(pageRoute, getFullName(childData.child.firstName, childData.child.lastName));
        setSearchParams(params, { replace: true });
    }, [childData, pageRoute, setSearchParams]);

    if (isChildFetching) {
        return <LoadingDisplay />;
    }

    if (isError || !childData) {
        return <ErrorDisplay />;
    }

    const { child: childDetails, settlementDetails, diet: dietDetails } = childData;
    const childDetailsWithDietDetails = { ...childDetails, dietDetails };

    const parents = childDetails[childFields.parents];
    const agreements = childDetails[childFields.agreements];
    const guardians = childDetails[childFields.guardians];

    const fullySensitiveDataContent = (
        <>
            <GridItem>
                <AddressData
                    heading={childrenTexts.childDetails.addressData.addressData}
                    icon={<ChildAddressDataIcon />}
                    {...childDetails}
                />
            </GridItem>
            <GridItem width={7}>
                <AdditionalInformation {...childDetailsWithDietDetails} />
            </GridItem>
            <GridItem width={7}>
                <Settlements {...(settlementDetails as ChildSettlementDetails)} />
            </GridItem>
            <DobleRow>{agreements && <Agreements agreements={agreements} />}</DobleRow>
            <GridItem width={9}>
                <Discounts />
            </GridItem>
            <GridItem width={11}>
                {parents && <Parents {...childDetails} parents={parents} />}
            </GridItem>
            <GridItem width={6}>
                {guardians && <Guardians {...childDetails} guardians={guardians} />}
            </GridItem>
        </>
    );

    return (
        <div>
            <AppGoBackButton />
            <Header
                {...childDetails}
                childEditPagePath={childEditPagePath}
                httpMethod={{
                    ...StaffHTTPService.files,
                    ...StaffHTTPService.children.profilePhoto,
                }}
            />
            <ParentGrid>
                <GridItem>
                    <PersonalData {...childDetails} canSeeSensitiveData={canSeeSensitiveData} />
                </GridItem>
                {canSeeSensitiveData && fullySensitiveDataContent}
            </ParentGrid>
        </div>
    );
};
