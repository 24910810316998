import { CostAllocationFormFields, GUSInformationDTO } from 'types';
import { parseGUSStreetDataIntoStreetFormField } from 'utils/parsers/gus/parseGUSStreetDataIntoStreetFormField';

export const parseGUSInformationDTOIntoCostAllocationFormFields: (
    gusData: GUSInformationDTO,
) => Partial<CostAllocationFormFields> = (gusData) => {
    const { name, city, mailCity, zipCode, street, buildingNumber, premisesNumber } = gusData;

    const parsedStreetFormFieldValue = parseGUSStreetDataIntoStreetFormField({
        street,
        buildingNumber,
        premisesNumber,
    });

    return {
        foundationName: name,
        city: city || mailCity,
        zipCode,
        street: parsedStreetFormFieldValue,
    };
};
