import { useContext, useMemo } from 'react';
import { formFields } from 'consts/form/formFields';
import { journalTexts } from 'consts/text';
import { SimpleSelectElement, SimpleSelectElementProps } from 'components/molecules';
import { AppFormContext } from 'contexts/AppFormContext';
import { useDisplayFetchDataError } from 'hooks/useDisplayFetchDataError/useDisplayFetchDataError';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ChildBase, LessonId } from 'types';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { parseChildIntoSelectOptionType } from 'utils/parsers/child/parseChildIntoSelectOptionType';

export type UnassignedChildrenPickerProps = Pick<
    SimpleSelectElementProps,
    'disabled' | 'hidden'
> & {
    lessonId?: LessonId;
    selectedUnassignedInThePastChildren?: ChildBase[];
};

export const UnassignedChildrenPicker = ({
    lessonId,
    selectedUnassignedInThePastChildren,
    disabled,
    hidden,
}: UnassignedChildrenPickerProps) => {
    const { formError, setFormError } = useContext(AppFormContext);

    const {
        data: unassignedChildrenData,
        isFetching: isUnassignedChildrenDataFetching,
        isError: isUnassignedChildrenDataError,
    } = useAppQuery(
        'UNASSIGNED_CHILDREN',
        [lessonId],
        () => StaffHTTPService.employeeSchedule.getUnassignedChildren(lessonId as LessonId),
        {
            enabled: !!lessonId,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    useDisplayFetchDataError({
        isFetching: isUnassignedChildrenDataFetching,
        isError: isUnassignedChildrenDataError,
        actualError: formError,
        errorMessage:
            journalTexts.journalDetails.specialJournal.topic.fetchingUnassignedChildrenDataError,
        setError: setFormError,
    });

    const unassignedChildrenOptions = useMemo(() => {
        if (!unassignedChildrenData) {
            return [];
        }
        const parsedUnassignedChildrenSelectOptions = unassignedChildrenData.map(
            parseChildIntoSelectOptionType,
        );
        if (!selectedUnassignedInThePastChildren) {
            return parsedUnassignedChildrenSelectOptions;
        }
        const parsedUnassignedInThePastChildrenSelectOptions =
            selectedUnassignedInThePastChildren.map(parseChildIntoSelectOptionType);
        return parsedUnassignedInThePastChildrenSelectOptions.concat(
            parsedUnassignedChildrenSelectOptions,
        );
    }, [selectedUnassignedInThePastChildren, unassignedChildrenData]);

    return (
        <SimpleSelectElement
            mode="multiple"
            name={formFields.notInLessonChildren}
            label={journalTexts.journalDetails.specialJournal.topic.selectChildren}
            options={unassignedChildrenOptions}
            loading={isUnassignedChildrenDataFetching}
            disabled={!lessonId || disabled}
            hidden={hidden}
        />
    );
};
