import React, { useCallback } from 'react';
import { IconMenu } from '@tabler/icons-react';
import { LogoFullNameRow } from 'assets';
import { useAtom } from 'jotai';
import { isLoggedUserDrawerMenuOpenAtom } from 'jotaiAtoms/isLoggedUserDrawerMenuOpenAtom';
import { useLocationChange } from 'hooks/useLocationChange/useLocationChange';
import { AppDrawer } from 'components/atoms/CommonAppComponents';
import { Container, MenuButton } from './UpperBarDrawerWithSideMenu.styled';

export type UpperBarDrawerWithSideMenuProps = { children: React.ReactNode };
export const UpperBarDrawerWithSideMenu = ({ children }: UpperBarDrawerWithSideMenuProps) => {
    const [isDrawerOpen, setIsDrawerOpen] = useAtom(isLoggedUserDrawerMenuOpenAtom);

    const hideDrawer = useCallback(() => setIsDrawerOpen(false), [setIsDrawerOpen]);

    useLocationChange({ action: hideDrawer });

    return (
        <Container>
            <LogoFullNameRow height={18} width={116} />
            <MenuButton margin={0} type="link" onClick={() => setIsDrawerOpen(true)}>
                <IconMenu />
            </MenuButton>
            <AppDrawer onClose={() => setIsDrawerOpen(false)} open={isDrawerOpen}>
                {children}
            </AppDrawer>
        </Container>
    );
};
