import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts, settlementTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const settlementsVerifyErrorParser: (error: unknown, defaultMessage?: string) => string = (
    error,
    defaultMessage = commonTexts.errorMessages.unknown,
) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.PREVIOUS_MONTHLY_SETTLEMENT_NOT_VERIFIED) {
        return settlementTexts.children.verifySettlements.verifySettlementsErrorMessages
            .previousMonthlySettlementNotVerified;
    }

    return defaultMessage;
};
