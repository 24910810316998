import styled, { css } from 'styled-components';
import { Layout } from 'antd';
import { LogoEmblemOnly, LogoFullNameRow } from 'assets';
import { AppButton } from '../../atoms';

export const ColumnContainer = styled(Layout.Sider)`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        background-color: ${theme.colors.grayscale.gray1};
        z-index: 100;
        overflow-x: hidden;
        overflow-y: auto;

        .ant-layout-sider-children {
            display: flex;
            flex: 1;
            flex-direction: column;
        }

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            top: 0;
            left: 0;
            bottom: 0;
            position: fixed;
            border-right: 1px solid ${theme.colors.primary.primary4};
            .ant-layout-sider-children {
                padding: 24px 16px;
            }
        }

        .collapsedIndicator {
            display: flex;
            align-self: flex-end;
            background-color: ${theme.colors.primary.primary9};
            color: white;
            border-radius: 4px;
            padding: 2px;
            align-items: center;
            justify-content: center;
            transition: 0.3s ease-out;
            transform: rotate(0deg);
        }

        &.ant-layout-sider-collapsed {
            .collapsedIndicator {
                transform: rotate(180deg);
            }
            .side-menu-button-label {
                display: none;
            }
            .ant-select {
                .ant-select-selection-item {
                    display: none;
                }
                .ant-select-arrow,
                .ant-select-clear {
                    right: 0;
                }
            }
        }
    `}
`;

export const StyledLogoEmblemOnly = styled(LogoEmblemOnly)`
    width: 24px;
    height: 24px;
    align-self: center;
    margin: 24px 0;
`;
export const StyledLogoFullNameRow = styled(LogoFullNameRow)`
    ${({ theme }) => css`
        height: 22px;
        width: 142px;
        margin-bottom: 24px;

        @media (${theme.sizes.mediaQueries.desktopMin}) {
            margin: 24px 0;
        }
    `}
`;

export const StyledCollapseButton = styled(AppButton)`
    ${({ theme }) => css`
        &.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled) {
            background-color: ${theme.colors.primary.primary9};
            &:hover {
                color: ${theme.colors.grayscale.gray1};
                background-color: ${theme.colors.primary.primary10};
            }
        }
    `}
`;
