import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { IconEdit, IconEye, IconArchive, IconCircleArrowUp } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import { appPaths } from 'consts/paths/paths';
import { AppTable, ArchiveRestoreModal } from 'components/organisms';
import { Child, ChildId } from 'types';
import { useSearchParamsBasedPagination } from 'hooks/useSearchParamsBasedPagination/useSearchParamsBasedPagination';
import { DEBOUNCE_DELAY } from 'consts/debounceDelay';
import { AppText, Option } from 'components/atoms';
import { useChildAbilities } from 'abilities';
import { commonTexts, childrenTexts } from 'consts/text/index';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { useChildrenPageNetworkManage } from './hooks/useChildrenPageNetworkManage';
import { ChildrenPageFilters } from './components';
import { useChildrenPageFilters } from './hooks/useChildrenPageFilters';
import { columnsWithoutSensitiveData, columnsWithSensitiveData } from './ChildrenPage.utils';

export const ChildrenPage = () => {
    const [selectedChildId, setSelectedChildId] = useState<ChildId | null>(null);
    const [modalMode, setModalMode] = useState<'restore' | 'archive' | null>(null);
    const { statefulNavigate } = useNavigateWithState();
    const { currentPage, currentPageSize, handlePaginationValuesChange } =
        useSearchParamsBasedPagination();
    const {
        groupsFilterValue,
        statusFilterValue,
        contractValidFilterValue,
        searchFilterValue,
        handleChangeFilter,
        handleClearFilters,
        handleSearchInput,
    } = useChildrenPageFilters({ currentPage });
    const { childAbilities } = useChildAbilities();

    const canManageChildren = childAbilities.checkIfCanManageChildrenList();
    const canArchiveChildren = childAbilities.checkIfCanArchiveChildren();
    const canSeeSensitiveData = childAbilities.checkIfCanSeeSensitiveData();

    const onChildArchiveRestoreRequestFinish = () => {
        setSelectedChildId(null);
        setModalMode(null);
    };

    const {
        childrenData,
        childLimitData,
        isChildrenDataError,
        isChildrenDataInitialLoading,
        isChildrenDataRefetching,
        isArchiveChildLoading,
        isRestoreChildLoading,
        archiveChild,
        restoreChild,
        childrenPagination,
        groupsData,
        isGroupsDataFetching,
        isGroupsDataError,
    } = useChildrenPageNetworkManage({
        searchFilterValue,
        contractValidFilterValue,
        statusFilterValue,
        groupsFilterValue,
        onChildArchiveRestoreRequestFinish,
        pagination: {
            currentPage,
            currentPageSize,
            onPaginationValuesChange: handlePaginationValuesChange,
        },
    });

    const navigateToChildAdd = () => statefulNavigate(appPaths.app.children.add);

    const handleArchiveChildClick = (record: Child) => {
        setSelectedChildId(record.id);
        setModalMode('archive');
    };

    const handleRestoreChildClick = (record: Child) => {
        setSelectedChildId(record.id);
        setModalMode('restore');
    };

    const handleArchiveRestoreChildConfirm = () => {
        if (!selectedChildId || !modalMode) {
            return;
        }
        const action = modalMode === 'archive' ? archiveChild : restoreChild;
        action(selectedChildId);
    };

    const options: Option<Child>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: (child: Child) =>
                statefulNavigate(
                    generatePath(appPaths.app.children.details.pickedChild.base, {
                        childId: child.id,
                    }),
                ),
            Icon: <IconEye />,
        },
        {
            label: commonTexts.actionLabels.edit,
            onClick: ({ id }) =>
                statefulNavigate(
                    generatePath(appPaths.app.children.edit.pickedChild, { childId: id }),
                ),
            Icon: <IconEdit />,
            checkIfVisible: () => canManageChildren,
        },
        {
            label: commonTexts.actionLabels.archive,
            onClick: handleArchiveChildClick,
            checkIfVisible: (child: Child) => canArchiveChildren && child.status !== 'INACTIVE',
            Icon: <IconArchive />,
        },
        {
            label: commonTexts.actionLabels.restore,
            onClick: handleRestoreChildClick,
            checkIfVisible: (child: Child) => canArchiveChildren && child.status === 'INACTIVE',
            Icon: <IconCircleArrowUp />,
        },
    ];

    const isLoading = isArchiveChildLoading || isRestoreChildLoading;

    const ChildLimitElement =
        childLimitData?.activeChildren && childLimitData?.childLimit ? (
            <AppText textType="BodyMediumSemiBold" margin="marginSmall">
                {childLimitData.activeChildren}/{childLimitData.childLimit}
            </AppText>
        ) : null;

    const columns = canSeeSensitiveData ? columnsWithSensitiveData : columnsWithoutSensitiveData;

    return (
        <>
            <ArchiveRestoreModal
                open={!!selectedChildId && !!modalMode}
                title={
                    modalMode === 'archive'
                        ? childrenTexts.archiveChildrenModalTitle
                        : childrenTexts.restoreChildrenModalTitle
                }
                confirmActionLoading={isLoading}
                onOk={handleArchiveRestoreChildConfirm}
                onCancel={onChildArchiveRestoreRequestFinish}
                isArchive={modalMode === 'archive'}
            />
            <AppTable
                additionalLeftTableHeaderContent={ChildLimitElement}
                title={childrenTexts.childrenList}
                addButtonLabel={childrenTexts.addChildrenButtonLabel}
                emptyInfo={childrenTexts.noChildrenMessage}
                options={options}
                columns={columns}
                dataSource={childrenData?.content || []}
                rowKey={({ id }) => id}
                onAddButtonClick={navigateToChildAdd}
                isError={isChildrenDataError || isGroupsDataError}
                isOptionsLoading={isLoading}
                isDataInitialLoading={isChildrenDataInitialLoading}
                isDataRefetching={isChildrenDataRefetching}
                isHiddenAddButton={!canManageChildren}
                pagination={childrenPagination}
                tableManageUtilsProps={{
                    searchInputProps: {
                        defaultValue: searchFilterValue,
                        onInput: debounce(handleSearchInput, DEBOUNCE_DELAY),
                    },
                    additionalFilters: (
                        <ChildrenPageFilters
                            status={statusFilterValue}
                            contractValid={contractValidFilterValue}
                            selectedGroupIds={groupsFilterValue}
                            groups={groupsData}
                            isGroupsLoading={isGroupsDataFetching}
                            onChangeFilter={handleChangeFilter}
                        />
                    ),
                    isDisplayedAsSeparateMenuOnMobile: true,
                    onClearFilters: handleClearFilters,
                }}
            />
        </>
    );
};
