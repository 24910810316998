import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { AxiosError } from 'axios';
import { SpecialTopicDTO, SpecialTopicId } from 'types';
import { useJournalId } from 'hooks/useJournalId/useJournalId';
import { useJournalDetailsBreadcrumb } from 'pages/app/staff/journal/journalDetails/hooks';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import dayjs from 'dayjs';
import { appPaths } from 'consts/paths/paths';
import { commonTexts, journalTexts } from 'consts/text';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { Option } from 'components/atoms';
import { AppCardList, RemoveModal } from 'components/organisms';
import {
    DatePickerArrowButtonType,
    DatePickerWithArrows,
    SpecialJournalTopicCard,
} from 'components/molecules';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { useJournalAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { institutionNonWorkingDayErrorStateConfig } from 'consts/journal/institutionNonWorkingDayErrorStateConfig';
import { customWeekStartEndFormat } from 'utils/customWeekStartEndFormat';
import { useSpecialJournalTopicsPageNetworkManage } from './hooks/useSpecialJournalTopicsPageNetworkManage';

export const SpecialJournalTopicsPage = () => {
    const [selectedToRemoveTopicId, setSelectedToRemoveTopicId] = useState<
        SpecialTopicId | undefined
    >(undefined);
    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const { journalId } = useJournalId();
    const { statefulNavigate } = useNavigateWithState();
    const {
        specialJournalsAbilities: { checkIfCanManageTopics, checkIfCanAddOutOfTimetableTopics },
    } = useJournalAbilities();

    useJournalDetailsBreadcrumb({
        journalId,
        journalType: 'SPECIAL',
    });

    const canManageTopics = checkIfCanManageTopics();
    const canAddOutOfTimetableTopics = checkIfCanAddOutOfTimetableTopics();

    const handleTopicRemoveRequestFinishOrCancel = () => setSelectedToRemoveTopicId(undefined);

    const {
        specialTopicsData,
        isSpecialTopicsDataInitialLoading,
        isSpecialTopicsDataRefetching,
        isRemoveSpecialTopicLoading,
        specialTopicsDataError,
        removeSpecialTopic,
    } = useSpecialJournalTopicsPageNetworkManage({
        journalId,
        date: dateFilterValue || parseDayjsDateIntoString(dayjs(), 'YYYY-MM-DD'),
        onTopicRemoveRequestFinish: handleTopicRemoveRequestFinishOrCancel,
    });

    const handleRemoveTopicClick = (topic: SpecialTopicDTO) =>
        setSelectedToRemoveTopicId((topic.id || '') as SpecialTopicId);

    const navigateToEditTopic = (topic: SpecialTopicDTO) =>
        statefulNavigate(
            generatePath(
                appPaths.app.journals.specialJournalDetails.pickedJournal.topics.edit.pickedTopic,
                { journalId, topicId: topic.id },
            ),
        );

    const handleRemoveTopicConfirm = () => {
        if (!selectedToRemoveTopicId) {
            return;
        }
        removeSpecialTopic({ journalId, specialTopicId: selectedToRemoveTopicId });
    };

    const navigateToAddTopic = () => {
        const addTopicPagePathBase = canAddOutOfTimetableTopics
            ? appPaths.app.journals.specialJournalDetails.pickedJournal.topics[
                  'add-out-of-timetable'
              ]
            : appPaths.app.journals.specialJournalDetails.pickedJournal.topics.add;
        statefulNavigate(
            generatePath(addTopicPagePathBase, {
                journalId,
            }),
        );
    };

    const options: Option<SpecialTopicDTO>[] = [
        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToEditTopic,
            Icon: <IconEdit />,
            checkIfVisible: (specialTopicDTO: SpecialTopicDTO) =>
                canManageTopics && !!specialTopicDTO.id,
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveTopicClick,
            Icon: <IconTrash />,
            checkIfVisible: (specialTopicDTO: SpecialTopicDTO) =>
                canManageTopics && !!specialTopicDTO.id,
        },
    ];

    const specialTopicsDataErrorCode =
        specialTopicsDataError instanceof AxiosError
            ? specialTopicsDataError?.response?.data?.code
            : '';
    const isInstitutionNonWorkingDayError =
        specialTopicsDataErrorCode === apiErrorCodes.INSTITUTION_NON_WORKING_DAY;

    return (
        <>
            <RemoveModal
                open={!!selectedToRemoveTopicId}
                title={journalTexts.journalDetails.groupJournal.topics.topicRemoveModalTitle}
                confirmActionLoading={isRemoveSpecialTopicLoading}
                onOk={handleRemoveTopicConfirm}
                onCancel={handleTopicRemoveRequestFinishOrCancel}
            />
            <AppCardList
                title={journalTexts.journalDetails.groupJournal.topics.topicsListTitle}
                addButtonLabel={
                    journalTexts.journalDetails.groupJournal.topics.topicsListAddButtonLabel
                }
                onAddButtonClick={navigateToAddTopic}
                emptyInfo={journalTexts.journalDetails.groupJournal.topics.topicsListEmptyState}
                dataSource={specialTopicsData}
                renderItem={(topic, index) => (
                    <SpecialJournalTopicCard
                        index={index}
                        specialTopicData={topic}
                        options={options}
                        isOptionsLoading={isRemoveSpecialTopicLoading}
                        specialTopicLessonId={topic.lesson.id}
                    />
                )}
                isError={!!specialTopicsDataError}
                isDataInitialLoading={isSpecialTopicsDataInitialLoading}
                isDataRefetching={isSpecialTopicsDataRefetching}
                isHiddenAddButton={!canManageTopics || isInstitutionNonWorkingDayError}
                errorStateConfig={
                    isInstitutionNonWorkingDayError
                        ? institutionNonWorkingDayErrorStateConfig
                        : undefined
                }
                filters={
                    <DatePickerWithArrows
                        picker="week"
                        format={customWeekStartEndFormat}
                        value={
                            dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs()
                        }
                        onChange={handleChangeDateFilter}
                        onArrowButtonClick={(arrowButtonType: DatePickerArrowButtonType) =>
                            handleClickNextOrPrevDate(arrowButtonType, 'week')
                        }
                    />
                }
            />
        </>
    );
};
