import { LabelMapper, VatRate } from 'types';
import { settingsTexts } from 'consts/text';

export const settlementVatRateTypeLabels: LabelMapper<VatRate> = {
    [VatRate.VAT_0]: settingsTexts.settlements.vatRates.vatRateLabels.vat0,
    [VatRate.VAT_5]: settingsTexts.settlements.vatRates.vatRateLabels.vat5,
    [VatRate.VAT_8]: settingsTexts.settlements.vatRates.vatRateLabels.vat8,
    [VatRate.VAT_23]: settingsTexts.settlements.vatRates.vatRateLabels.vat23,
    [VatRate.NOT_SUBJECT]: settingsTexts.settlements.vatRates.vatRateLabels.notSubject,
    [VatRate.EXEMPT]: settingsTexts.settlements.vatRates.vatRateLabels.exempt,
};
