import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: ${theme.sizes.marginNormal};
    `}
`;

export const IconContainer = styled.div`
    margin-right: 8px;
`;
