export const settlementChildrenDTOFields = {
    child: 'child',
    lastName: 'lastName',
    firstName: 'firstName',
    mainGroup: 'mainGroup',
    id: 'id',
    settlementPeriodEnd: 'settlementPeriodEnd',
    stayGrossCost: 'stayGrossCost',
    mealGrossCost: 'mealGrossCost',
    lessonsGrossCost: 'lessonsGrossCost',
    balance: 'balance',
    totalGrossCost: 'totalGrossCost',
    institutionId: 'institutionId',
    verified: 'verified',
} as const;

export const settlementChildrenBillComponentDTOFields = {
    name: 'name',
    amountString: 'amountString',
    discountString: 'discountString',
    children: 'children',
};

export const settlementChildPaymentDTOFields = {
    date: 'date',
    transactionType: 'transactionType',
    paymentFormType: 'paymentFormType',
    amount: 'amount',
    description: 'description',
};
