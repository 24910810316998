import { DatePickerWithArrows, ErrorDisplay, LoadingDisplay } from 'components/molecules';
import { AppButton, AppFlex, AppGoBackButton, AppText } from 'components/atoms';
import { commonTexts, settlementTexts } from 'consts/text/index';
import { FULL_MONTH_FORMAT } from 'consts/dateFormats';
import { useDateFilter } from 'hooks/useDateFilter/useDateFilter';
import { DATE_FILTER_KEY } from 'consts/filters/common/filtersKeys';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import dayjs from 'dayjs';
import { Header } from 'components/templates/child/ChildDetails/components/Header/Header';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useOutlet, useParams, useSearchParams } from 'react-router-dom';
import { Child, ChildId, ChildSettlementId, CostAllocationId, PaymentId } from 'types';
import { useEffect, useState } from 'react';
import { getFullName } from 'utils/getFullName';
import { appRoutes } from 'consts/paths/paths';
import { BalanceTag } from 'components/molecules/BalanceTag/BalanceTag';
import { Row } from 'antd';
import { RemoveModal } from 'components/organisms';
import { ChildAddEditBillModal } from 'components/templates/settlement/ChildAddBillModal/ChildAddEditBillModal';
import { SummaryText } from 'components/atoms/SummaryText/SummaryText';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { ChildSettlementPaymentTable } from 'components/templates/settlement/ChildSettlementPaymentTable/ChildSettlementPaymentTable';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { ChildSettlementItem } from 'components/templates/settlement/ChildSettlementItem/ChildSettlementItem';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { useSettlementAbilities } from 'abilities';
import { IconCalendarTime, IconChecks } from '@tabler/icons-react';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import {
    VerifySettlementsModal,
    VerifySettlementsModalMode,
} from 'components/templates/settlement/VerifySettlementsModal';
import { settlementsVerifyErrorParser } from 'utils/errorHandlers/settlement/settlementsVerifyErrorParser';
import { AxiosError } from 'axios';
import { FiltersContainer, StyledRow } from './ChildSettlementDetails.styled';

export const ChildSettlementDetails = () => {
    const outlet = useOutlet();
    const { displaySuccess, displayError } = useSimpleNotification();

    const [removeObject, setRemoveObject] = useState<{
        removeTarget: 'costAllocation' | 'splitPayment';
        removeId: ChildSettlementId | CostAllocationId;
    } | null>(null);

    const pageRoute = appRoutes.app.settlement.children.pickedChild.base;
    const [_, setSearchParams] = useSearchParams();
    const { childId } = useParams<{ childId: ChildId }>();

    const { dateFilterValue, handleChangeDateFilter, handleClickNextOrPrevDate } = useDateFilter({
        filterKey: DATE_FILTER_KEY,
    });
    const [addBillModalOpen, setAddBillModalOpen] = useState<{
        childId: ChildId;
        transactionId: PaymentId | null;
    } | null>(null);
    const [verifySettlementModalOpen, setVerifySettlementModalOpen] = useState(false);

    const isMobile = useIsMobile();

    const { settlementAbilities } = useSettlementAbilities();

    const canVerifySettlement = settlementAbilities.checkIfCanVerifySettlement();

    const selectedDate = dateFilterValue ? parseStringIntoDayjsDate(dateFilterValue) : dayjs();
    const selectedYear = selectedDate.year();
    const selectedMonth = getMonthFromDayjsMonth(selectedDate.month());

    const {
        data: childSettlementData,
        isFetching: isChildSettlementFetching,
        isError: isChildSettlementError,
    } = useAppQuery(
        'CHILD_SETTLEMENT',
        [childId, selectedMonth, selectedYear],
        () =>
            StaffHTTPService.settlements.getChildSettlement({
                childId: childId as ChildId,
                month: selectedMonth,
                year: selectedYear,
            }),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const {
        data: childCostSplits,
        isFetching: isChildCostSplitsFetching,
        isError: isChildCostSplitsError,
    } = useAppQuery(
        'CHILD_SETTLEMENT_COST_SPLIT',
        [childId, childSettlementData?.id],
        () =>
            StaffHTTPService.settlements.getChildCostAllocations({
                settlementId: childSettlementData?.id as ChildSettlementId,
            }),
        {
            staleTime: FIVE_MINS_IN_MILLIS,
            enabled: !!childSettlementData?.id,
        },
    );

    useEffect(() => {
        if (!childSettlementData) {
            return;
        }
        const params = new URLSearchParams(window.location.search);
        params.set(
            pageRoute,
            getFullName(
                childSettlementData?.child?.firstName,
                childSettlementData?.child?.lastName,
            ),
        );
        setSearchParams(params, { replace: true });
    }, [childSettlementData, pageRoute, setSearchParams]);

    const onRemoveCostAllocationSuccess = () => {
        displaySuccess(settlementTexts.children.costAllocation.removeSuccess);
        setRemoveObject(null);
    };

    const onRemoveCostAllocationError = () => {
        displayError(commonTexts.errorMessages.unknown);
    };

    const { mutate: removeCostAllocation, isLoading: isRemoveCostAllocationLoading } =
        useAppMutation(StaffHTTPService.settlements.removeCostAllocation, {
            key: ['REMOVE_COST_ALLOCATION'],
            onSuccess: onRemoveCostAllocationSuccess,
            onError: onRemoveCostAllocationError,
            invalidateQueryKeys: [
                ['COST_ALLOCATION'],
                ['CHILD_SETTLEMENT', childId],
                ['CHILD_SETTLEMENT_COST_SPLIT', childId],
            ],
        });

    const verifySettlementModalClose = () => setVerifySettlementModalOpen(false);

    const handleVerifySettlementFromMonthSuccess = () => {
        displaySuccess(
            settlementTexts.children.verifySettlements.singleSettlement
                .verifySettlementSuccessMessage,
        );
        verifySettlementModalClose();
    };

    const handleVerifySettlementFromMonthError = (error: AxiosError) => {
        displayError(
            settlementsVerifyErrorParser(error, commonTexts.errorMessages.actionExecution),
        );
        verifySettlementModalClose();
    };

    const { mutate: verifyChildSettlement, isLoading: isVerifyChildSettlementLoading } =
        useAppMutation(StaffHTTPService.settlements.verifyChildSettlement, {
            key: ['VERIFY_SETTLEMENT'],
            onSuccess: handleVerifySettlementFromMonthSuccess,
            onError: handleVerifySettlementFromMonthError,
            invalidateQueryKeys: [
                ['SETTLEMENTS'],
                ['CHILD_SETTLEMENT', childId, selectedMonth, selectedYear],
                ['VERIFICATION_STATUS', selectedMonth, selectedYear],
            ],
        });

    const handleVerifySettlementClick = () => setVerifySettlementModalOpen(true);

    const childSettlementDataHeader = (
        <FiltersContainer justify="space-between" align="bottom">
            <DatePickerWithArrows
                label={commonTexts.actionLabels.selectMonth}
                value={selectedDate}
                picker="month"
                format={FULL_MONTH_FORMAT}
                onChange={handleChangeDateFilter}
                onArrowButtonClick={(arrowButtonType) =>
                    handleClickNextOrPrevDate(arrowButtonType, 'month')
                }
            />
            <AppFlex align="center" gap="small" vertical={isMobile} flex={isMobile ? 1 : 0}>
                <SummaryText
                    text={
                        childSettlementData
                            ? getFormattedPrice(childSettlementData?.totalGrossCost)
                            : getFormattedPrice(0)
                    }
                />
                {canVerifySettlement && (
                    <AppButton
                        type="primary"
                        margin={0}
                        fullWidth={isMobile}
                        icon={childSettlementData?.verified ? <IconChecks /> : <IconCalendarTime />}
                        disabled={childSettlementData?.verified}
                        onClick={handleVerifySettlementClick}
                    >
                        {childSettlementData?.verified
                            ? settlementTexts.children.details.verifiedChildSettlement
                            : settlementTexts.children.details.verifyChildSettlement}
                    </AppButton>
                )}
            </AppFlex>
        </FiltersContainer>
    );

    if (isChildSettlementFetching || isChildCostSplitsFetching) {
        return <LoadingDisplay />;
    }

    if (!childSettlementData || !childId || isChildSettlementError) {
        return <ErrorDisplay />;
    }

    const removeTitle =
        removeObject?.removeTarget === 'costAllocation'
            ? settlementTexts.children.costAllocationRemove
            : settlementTexts.children.paymentRemove;

    const onRemoveCancel = () => {
        setRemoveObject(null);
    };

    const onRemoveOk = () => {
        if (removeObject?.removeTarget === 'costAllocation' && removeObject?.removeId) {
            removeCostAllocation({ costAllocationId: removeObject.removeId as CostAllocationId });
        }
    };

    return (
        <>
            {outlet}
            <RemoveModal
                open={!!removeObject}
                title={removeTitle}
                onOk={onRemoveOk}
                onCancel={onRemoveCancel}
                confirmActionLoading={isRemoveCostAllocationLoading}
            />
            <VerifySettlementsModal
                mode={VerifySettlementsModalMode.ONE_SETTLEMENT}
                monthNumber={selectedMonth - 1}
                open={verifySettlementModalOpen}
                confirmActionLoading={isVerifyChildSettlementLoading}
                onOk={() =>
                    verifyChildSettlement({
                        childId,
                        monthlySettlementId: childSettlementData.id,
                    })
                }
                onCancel={verifySettlementModalClose}
            />
            <ChildAddEditBillModal
                mode={addBillModalOpen?.transactionId ? 'edit' : 'add'}
                childId={addBillModalOpen?.childId}
                transactionId={addBillModalOpen?.transactionId}
                onCancel={() => setAddBillModalOpen(null)}
            />
            <Row justify="space-between" align="middle">
                <AppGoBackButton />
            </Row>
            <Header
                {...(childSettlementData?.child as Child)}
                canManageChildAvatar={false}
                httpMethod={{
                    ...StaffHTTPService.files,
                    ...StaffHTTPService.children.profilePhoto,
                }}
                additionalElement={
                    <StyledRow>
                        <AppText marginLeft={0}>{settlementTexts.children.details.balance}</AppText>
                        <BalanceTag amount={childSettlementData.balance} />
                    </StyledRow>
                }
            />
            <ChildSettlementPaymentTable
                onAddClick={() =>
                    setAddBillModalOpen({ childId: childId as ChildId, transactionId: null })
                }
                onEditClick={(transactionId: PaymentId) =>
                    setAddBillModalOpen({ childId: childId as ChildId, transactionId })
                }
            />
            {childSettlementDataHeader}
            <ChildSettlementItem
                removeObject={setRemoveObject}
                costSplits={childCostSplits}
                isChildCostSplitsError={isChildCostSplitsError}
                {...childSettlementData}
            />
        </>
    );
};
