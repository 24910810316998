import { useContext, useEffect } from 'react';
import { AppButton, AppLoader } from 'components/atoms/CommonAppComponents';
import { IconDownload } from '@tabler/icons-react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { formFields } from 'consts/form/formFields';
import { AppFormContext } from 'contexts/AppFormContext';
import { authTexts, commonTexts } from 'consts/text/index';
import { CostAllocationFormFields, GUSInformationDTO, InstitutionAddEditFormFields } from 'types';
import { InvoiceEditFormFields } from 'types/forms/invoice';
import { gusInformationGetErrorParser } from 'utils/errorHandlers/gus/gusInformationGetErrorParser';
import { ButtonContainer, IconContainer } from './FetchGUSInformationButton.styled';

export enum FetchByField {
    NIP = 'NIP',
    REGON = 'REGON',
}

type FetchGUSInformationButtonProps = {
    fetchByField: FetchByField;
    parseInstitutionGusDataIntoFormData: (
        data: GUSInformationDTO,
    ) =>
        | Partial<InvoiceEditFormFields>
        | Partial<CostAllocationFormFields>
        | Partial<InstitutionAddEditFormFields>;
};

export const FetchGUSInformationButton = ({
    fetchByField,
    parseInstitutionGusDataIntoFormData,
}: FetchGUSInformationButtonProps) => {
    const { formError, setFormError } = useContext(AppFormContext);

    const { getFieldValue, validateFields, setFieldsValue } = useFormInstance();

    const isNipFetch = fetchByField === FetchByField.NIP;
    const formFieldToFetchBy = isNipFetch ? formFields.nip : formFields.regonNumber;

    const getFormFieldValueToFetchBy = () => getFieldValue(formFieldToFetchBy);

    const { data, error, isFetching, isFetchedAfterMount, refetch } = useAppQuery(
        'GUS_INFORMATION',
        [],
        () =>
            StaffHTTPService.gus.getGUSInformation({
                [isNipFetch ? 'nip' : 'regon']: getFormFieldValueToFetchBy(),
            }),
        {
            enabled: false,
        },
    );

    useEffect(() => {
        if (error && !isFetching) {
            const gusInformationGetError = gusInformationGetErrorParser(
                error,
                commonTexts.errorMessages.gusDataFetching,
            );
            setFormError(gusInformationGetError);
        } else if (
            formError === commonTexts.errorMessages.gusDataFetching ||
            commonTexts.errorMessages.gusCompanyNotFound
        ) {
            setFormError('');
        }
        // it should change on error, isFetching only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, isFetching]);

    useEffect(() => {
        if (data && isFetchedAfterMount) {
            setFieldsValue(parseInstitutionGusDataIntoFormData(data));
        }
    }, [data, isFetchedAfterMount, parseInstitutionGusDataIntoFormData, setFieldsValue]);

    const fetchInstitutionDetails = () =>
        validateFields([formFieldToFetchBy])
            .then(() => {
                setFormError('');
                refetch();
            })
            // Intentional, I just don't want to do anything if there is an error
            .catch(() => {});

    const icon = isFetching ? <AppLoader size={20} /> : <IconDownload size={20} />;

    return (
        <ButtonContainer>
            <AppButton
                disabled={isFetching}
                onClick={fetchInstitutionDetails}
                type="link"
                margin={0}
            >
                <IconContainer>{icon}</IconContainer>
                {authTexts.getDataButtonLabel}
            </AppButton>
        </ButtonContainer>
    );
};
