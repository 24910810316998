import { ChildPersonalDataIcon } from 'assets';
import { Child } from 'types';
import { ChildGroupDisplay } from 'components/molecules';
import { childSexLabels } from 'consts/child/childSexLabels';
import { parseStringIntoFormattedStringDate } from 'utils/parsers/dateTime/parseStringIntoFormattedStringDate';
import { childrenTexts } from 'consts/text';
import { DetailsColumn, DetailsRow, DetailsFrameBase } from 'components/atoms';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export type PersonalDataProps = Pick<
    Child,
    'sex' | 'pesel' | 'groups' | 'birthPlace' | 'birthDate'
> & { canSeeSensitiveData: boolean };

export const PersonalData = ({
    sex,
    pesel,
    groups,
    birthPlace,
    birthDate,
    canSeeSensitiveData,
}: PersonalDataProps) => (
    <DetailsFrameBase
        icon={<ChildPersonalDataIcon />}
        title={childrenTexts.childDetails.personalData.personalData}
    >
        <DetailsRow>
            <DetailsColumn title={childrenTexts.childDetails.personalData.sex} span={8}>
                {childSexLabels[sex]}
            </DetailsColumn>
            {canSeeSensitiveData && (
                <DetailsColumn title={childrenTexts.childDetails.personalData.pesel} span={8}>
                    {pesel}
                </DetailsColumn>
            )}
            <DetailsColumn title={childrenTexts.childDetails.personalData.group} span={8}>
                <ChildGroupDisplay childGroups={groups} />
            </DetailsColumn>
        </DetailsRow>
        {canSeeSensitiveData && (
            <DetailsRow>
                <DetailsColumn title={childrenTexts.childDetails.personalData.birthPlace} span={12}>
                    {birthPlace}
                </DetailsColumn>
                <DetailsColumn title={childrenTexts.childDetails.personalData.birthDate} span={12}>
                    {birthDate ? parseStringIntoFormattedStringDate(birthDate) : EMPTY_VALUE_LABEL}
                </DetailsColumn>
            </DetailsRow>
        )}
    </DetailsFrameBase>
);
