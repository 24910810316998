import { formFields } from 'consts/form/formFields';
import { GUSInformationDTO, InstitutionAddEditFormFields } from 'types';

export const parseGUSInformationDTOIntoInstitutionAddEditFormFields: (
    gusData: GUSInformationDTO,
) => Partial<InstitutionAddEditFormFields> = (gusData) => ({
    [formFields.institutionName]: gusData.name,
    [formFields.city]: gusData.city || gusData.mailCity,
    [formFields.zipCode]: gusData.zipCode,
    [formFields.regonNumber]: gusData.regon,
    [formFields.rspoNumber]: gusData.rspo,
    [formFields.street]: `${gusData.street} ${gusData.buildingNumber}${
        gusData.premisesNumber ? `/${gusData.premisesNumber}` : ''
    }`,
});
