import { useMemo } from 'react';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { ChildContractStatus, ChildStatus, GroupId, Pagination } from 'types';
import { childFilterFields } from 'consts/filters/child/childFilterFields';
import { contractValidFilterValueMapper } from 'consts/filters/child/contractValidFilterValueMapper';
import { parsePaginationDataToTablePaginationData } from 'utils/parsers/table/parsePaginationDataToTablePaginationData';
import { childrenTexts, commonTexts } from 'consts/text';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { childArchiveErrorParser } from 'utils/errorHandlers/child/childArchiveErrorParser';

type ChildrenPageNetworkManageParams = {
    searchFilterValue: string;
    contractValidFilterValue: ChildContractStatus | null;
    statusFilterValue: ChildStatus | null;
    groupsFilterValue: GroupId[] | null;
    onChildArchiveRestoreRequestFinish: () => void;
    pagination: Pagination;
};

export const useChildrenPageNetworkManage = ({
    searchFilterValue,
    contractValidFilterValue,
    statusFilterValue,
    groupsFilterValue,
    onChildArchiveRestoreRequestFinish,
    pagination: { currentPage, currentPageSize, onPaginationValuesChange },
}: ChildrenPageNetworkManageParams) => {
    const { displaySuccess, displayError } = useSimpleNotification();

    const filters = useMemo(() => {
        const filtersValues: Record<string, ChildStatus | GroupId[] | boolean> = {};
        if (statusFilterValue) {
            filtersValues[childFilterFields.status] = statusFilterValue;
        }
        if (groupsFilterValue) {
            filtersValues[childFilterFields.groupIds] = groupsFilterValue;
        }
        if (contractValidFilterValue) {
            filtersValues[childFilterFields.contractValid] =
                contractValidFilterValueMapper[contractValidFilterValue];
        }
        return filtersValues;
    }, [statusFilterValue, groupsFilterValue, contractValidFilterValue]);

    const {
        data: childrenData,
        isInitialLoading: isChildrenDataInitialLoading,
        isRefetching: isChildrenDataRefetching,
        isError: isChildrenDataError,
    } = useAppQuery(
        'CHILDREN',
        [
            searchFilterValue,
            currentPage,
            currentPageSize,
            statusFilterValue,
            contractValidFilterValue,
            groupsFilterValue,
        ],
        () =>
            StaffHTTPService.children.getChildren({
                search: searchFilterValue,
                filters,
                paginationParams: { size: currentPageSize, page: currentPage },
            }),
        { staleTime: FIVE_MINS_IN_MILLIS },
    );

    const { data: childLimitData } = useAppQuery(
        'CHILD_LIMIT',
        [],
        StaffHTTPService.children.getChildLimit,
        {
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const { mutate: archiveChild, isLoading: isArchiveChildLoading } = useAppMutation(
        StaffHTTPService.children.archiveChild,
        {
            key: ['ARCHIVE_CHILD'],
            onSuccess: () => {
                onChildArchiveRestoreRequestFinish();
                displaySuccess(childrenTexts.successfullyArchiveChildMessage);
            },
            onError: (error) => {
                onChildArchiveRestoreRequestFinish();
                displayError(childArchiveErrorParser(error));
            },
            invalidateQueryKeys: [
                ['CHILDREN'],
                ['JOURNAL_CHILDREN'],
                ['JOURNAL_CHILDREN_PARENTS'],
                ['MAIL_THREAD_RECEIVERS'],
                ['CHILD_LIMIT'],
            ],
        },
    );

    const { mutate: restoreChild, isLoading: isRestoreChildLoading } = useAppMutation(
        StaffHTTPService.children.restoreChild,
        {
            key: ['RESTORE_CHILD'],
            onSuccess: () => {
                onChildArchiveRestoreRequestFinish();
                displaySuccess(childrenTexts.successfullyRestoredChildMessage);
            },
            onError: () => {
                onChildArchiveRestoreRequestFinish();
                displayError(commonTexts.errorMessages.actionExecution);
            },
            invalidateQueryKeys: [
                ['CHILDREN'],
                ['JOURNAL_CHILDREN'],
                ['JOURNAL_CHILDREN_PARENTS'],
                ['MAIL_THREAD_RECEIVERS'],
                ['CHILD_LIMIT'],
            ],
        },
    );

    const {
        data: groupsData,
        isFetching: isGroupsDataFetching,
        isError: isGroupsDataError,
    } = useAppQuery('GROUPS', [], () => StaffHTTPService.groups.getGroups(), {
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    return {
        childrenData,
        childLimitData,
        isChildrenDataInitialLoading,
        isChildrenDataRefetching,
        isChildrenDataError,
        isArchiveChildLoading,
        isRestoreChildLoading,
        archiveChild,
        restoreChild,
        childrenPagination: {
            ...parsePaginationDataToTablePaginationData({
                currentPage,
                currentPageSize,
            }),
            total: childrenData?.totalElements || 0,
            onChange: onPaginationValuesChange,
        },
        groupsData,
        isGroupsDataFetching,
        isGroupsDataError,
    };
};
