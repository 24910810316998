import { InstitutionAddEditFormFields } from 'types/forms/institution';
import { InstitutionCreateData, InstitutionIdentifier } from '../../../types';

export const parseInstitutionAddFormFieldsIntoInstitutionCreateData: (
    institutionAddFormFields: InstitutionAddEditFormFields,
) => InstitutionCreateData = (institutionAddFormFields) => {
    const {
        street,
        institutionIdentifier,
        institutionAffiliation,
        institutionName,
        institutionType,
        zipCode,
        city,
        nurseryRegistryNumber,
        rspoNumber,
        regonNumber,
    } = institutionAddFormFields;

    return {
        regon: regonNumber,
        name: institutionName,
        street,
        zipCode,
        city,
        publicInstitution: institutionAffiliation === 'public',
        type: institutionType,
        nurseryRegistryNumber:
            institutionIdentifier === InstitutionIdentifier.NURSERY_REGISTER_NUMBER
                ? nurseryRegistryNumber
                : undefined,
        rspo: institutionIdentifier === InstitutionIdentifier.RSPO ? rspoNumber : undefined,
    };
};
