import { apiErrorCodes } from 'consts/api/apiErrorCodes';
import { commonTexts } from 'consts/text';
import { getErrorMessageAndCode } from 'utils/errorHandlers/getErrorMessageAndCode';

export const gusInformationGetErrorParser: (
    error: unknown,
    defaultErrorMessage?: string,
) => string = (error, defaultErrorMessage = commonTexts.errorMessages.unknown) => {
    const { code } = getErrorMessageAndCode(error);

    if (code === apiErrorCodes.GUS_COMPANY_NOT_FOUND) {
        return commonTexts.errorMessages.gusCompanyNotFound;
    }

    return defaultErrorMessage;
};
