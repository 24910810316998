import { ChildCostAllocation, CostAllocationFormFields } from 'types';
import omit from 'lodash/omit';
import { parseBankAccountNumberIntoFormattedBankAccountNumber } from 'utils/parsers/bankAccountNumber/parseBankAccountNumberIntoFormattedBankAccountNumber';

export const parseCostAllocationDTOToCostAllocationFormFields = (
    costAllocation: ChildCostAllocation,
): CostAllocationFormFields => ({
    ...omit(costAllocation, 'address'),
    ...costAllocation.address,
    bankAccountNumber: costAllocation.bankAccountNumber
        ? parseBankAccountNumberIntoFormattedBankAccountNumber(costAllocation.bankAccountNumber)
        : '',
});
