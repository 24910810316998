import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { ChildId, EditChildFormFields } from 'types';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { parseChildIntoChildEditFormFields } from 'utils/parsers/child/parseChildIntoChildEditFormFields';
import { parseEditChildFormFieldsIntoUpdateChildDTO } from 'utils/parsers/child/parseChildFormFieldsIntoUpdateChildDTO';
import { ErrorDisplay } from 'components/molecules';
import { childrenTexts } from 'consts/text/index';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { ChildEditForm } from '../../ChildEditForm/ChildEditForm';

type ChildEditProps = {
    childrenPagePath: string;
};

export const ChildEdit = ({ childrenPagePath }: ChildEditProps) => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { childId: childIdParam } = useParams();

    const childId = (childIdParam || '') as ChildId;

    const {
        data: childData,
        isFetching: isChildDataFetching,
        isError: isFetchChildDataError,
    } = useAppQuery('CHILD', [childId], () => StaffHTTPService.children.getChild(childId), {
        refetchOnWindowFocus: false,
        staleTime: FIVE_MINS_IN_MILLIS,
    });

    const redirectToChildrenPage = (replace?: boolean) =>
        statefulNavigate(childrenPagePath, { replace });

    const onChildEditSuccess = () => {
        displaySuccess(childrenTexts.successfullyUpdateChildrenMessage);
        redirectToChildrenPage(true);
    };

    const {
        mutate: updateChild,
        error: updateChildError,
        isLoading: isUpdateChildLoading,
    } = useAppMutation(StaffHTTPService.children.updateChild, {
        key: ['CHILD', childId],
        onSuccess: onChildEditSuccess,
        invalidateQueryKeys: [
            ['CHILDREN'],
            ['JOURNAL_CHILDREN'],
            ['JOURNAL_CHILDREN_PARENTS'],
            ['CHILD', childId],
            ['CHILD_QUALIFICATION'],
            ['MAIL_THREAD_RECEIVERS'],
        ],
    });

    const childEditStepsFormInitialStates = useMemo(() => {
        if (!childData) {
            return null;
        }
        return parseChildIntoChildEditFormFields(childData);
    }, [childData]);

    const onFinish = (childUpdateData: EditChildFormFields) => {
        const updateChildDTO = parseEditChildFormFieldsIntoUpdateChildDTO(childUpdateData);
        updateChild({ childId, updateChildDTO });
    };

    if (isFetchChildDataError) {
        return <ErrorDisplay />;
    }

    return (
        <ChildEditForm
            initialValues={childEditStepsFormInitialStates}
            isLoading={isChildDataFetching || isUpdateChildLoading}
            axiosError={updateChildError}
            onFinish={onFinish}
        />
    );
};
