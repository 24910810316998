import { axiosErrors } from 'consts/errors/axiosErrors';
import { commonTexts, institutionTexts } from 'consts/text';
import { getErrorMessageAndCode } from '../getErrorMessageAndCode';

export const institutionAddErrorHandler = (error: unknown) => {
    const { message, code } = getErrorMessageAndCode(error);

    if (code === axiosErrors.TIMEOUT_AXIOS_ERROR_CODE) {
        return commonTexts.errorMessages.timeout;
    }
    if (message.includes('Institution with that REGON already exists')) {
        return institutionTexts.institutionWithThatRegonAlreadyExistsErrorMessage;
    }
    if (message.includes('Institution with that RSPO already exists')) {
        return institutionTexts.institutionWithThatRspoAlreadyExistsErrorMessage;
    }
    if (message.includes('Institution with that nursery registry number already exists')) {
        return institutionTexts.institutionWithThatNurseryRegistryNumberAlreadyExistsErrorMessage;
    }
    return commonTexts.errorMessages.unknown;
};
