import { SettlementVatRatesSettingsDto } from 'types';
import { jsonObjectsTexts } from 'consts/text';
import { settlementVatRateTypeLabels } from 'consts/settlementVatRates/settlementVatRateLabels';

export const parseSettlementVatRatesSettingsDTOIntoJsonObject: (
    settlementVatRatesSettingsDto: SettlementVatRatesSettingsDto,
) => object = (settlementVatRatesSettingsDto) => {
    const { stayVatRateType, mealVatRateType } = settlementVatRatesSettingsDto;

    const stayVatRateTypeLabel = settlementVatRateTypeLabels[stayVatRateType];
    const mealVatRateTypeLabel = settlementVatRateTypeLabels[mealVatRateType];

    return {
        [jsonObjectsTexts.settlementVatRatesSettingsDto.fields.stayVatRateType]:
            stayVatRateTypeLabel,
        [jsonObjectsTexts.settlementVatRatesSettingsDto.fields.mealVatRateType]:
            mealVatRateTypeLabel,
    };
};
