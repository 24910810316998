import { AppFormItem, AppFormItemProps, AppSelect, AppSelectProps } from 'components/atoms';
import { SelectOptionType, VatRate } from 'types';
import { settlementVatRateTypeLabels } from 'consts/settlementVatRates/settlementVatRateLabels';

type VatRatePickerElementProps = AppFormItemProps & Pick<AppSelectProps, 'allowClear'>;

const options: SelectOptionType<VatRate>[] = [
    {
        label: settlementVatRateTypeLabels.VAT_23,
        value: VatRate.VAT_23,
    },
    {
        label: settlementVatRateTypeLabels.VAT_8,
        value: VatRate.VAT_8,
    },
    {
        label: settlementVatRateTypeLabels.VAT_5,
        value: VatRate.VAT_5,
    },
    {
        label: settlementVatRateTypeLabels.VAT_0,
        value: VatRate.VAT_0,
    },
    {
        label: settlementVatRateTypeLabels.EXEMPT,
        value: VatRate.EXEMPT,
    },
    {
        label: settlementVatRateTypeLabels.NOT_SUBJECT,
        value: VatRate.NOT_SUBJECT,
    },
];

export const VatRatePickerElement = ({
    allowClear,
    disabled,
    ...props
}: VatRatePickerElementProps) => (
    <AppFormItem disabled={disabled} {...props}>
        <AppSelect options={options} disabled={disabled} allowClear={allowClear} />
    </AppFormItem>
);
