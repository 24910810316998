export enum VatRate {
    VAT_0 = 'VAT_0',
    VAT_5 = 'VAT_5',
    VAT_8 = 'VAT_8',
    VAT_23 = 'VAT_23',
    EXEMPT = 'EXEMPT',
    NOT_SUBJECT = 'NOT_SUBJECT',
}

export type SettlementVatRatesSettingsDto = {
    stayVatRateType: VatRate;
    mealVatRateType: VatRate;
};

export type UpdateSettlementVatRatesSettingsDto = SettlementVatRatesSettingsDto;
