import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';
import { AppFormProps } from 'components/organisms';
import { Nullable, ParentFormFields } from 'types';
import { isEmptyString } from 'utils/isEmptyString';

export const checkIfParentModalEditFormDataAreEqualAfterChanges = (
    actualParentFormFields: ParentFormFields,
    parentFormFieldsBeforeChanges: Nullable<AppFormProps<ParentFormFields>['initialValues']>,
) => {
    const formattedActualParentFormFields = omitBy(actualParentFormFields, isEmptyString);
    const formattedParentFormFieldsBeforeChanges = omitBy(
        parentFormFieldsBeforeChanges,
        isEmptyString,
    );
    return isEqual(formattedActualParentFormFields, formattedParentFormFieldsBeforeChanges);
};
