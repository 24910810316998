import { AppTableProps } from 'components/organisms';
import { Child } from 'types';
import { childrenTexts, commonTexts } from 'consts/text';
import { childFields } from 'consts/child/childFields';
import {
    AddressDisplay,
    ChildFirstAndLastNameDisplay,
    ChildGroupDisplay,
    ChildSexDisplay,
    ContractValidDisplay,
    ParentsAccountsCount,
    ParentsNamesDisplay,
    ParentsPhoneNumbersDisplay,
    TableTagDisplay,
} from 'components/molecules';
import { colors } from 'theme/styledComponents/colors';
import { childStatusesLabels } from 'consts/child/childStatusesLabels';
import { tagColorsByChildStatus } from 'consts/child/tagColorsByChildStatus';
import { EMPTY_VALUE_LABEL } from 'consts/labels/common';

export const columnsWithoutSensitiveDataRightPart: AppTableProps<Child>['columns'] = [
    {
        title: childrenTexts.childrenListColumns.agreement,
        dataIndex: childFields.contractValid,
        key: childFields.contractValid,
        render: (contractValid: Child['contractValid']) => (
            <ContractValidDisplay contractValid={contractValid} />
        ),
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: commonTexts.dataLabels.status,
        dataIndex: childFields.status,
        key: childFields.status,
        render: (status: Child['status']) => (
            <TableTagDisplay
                value={status}
                labelMapper={childStatusesLabels}
                colorMapper={tagColorsByChildStatus}
            />
        ),
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
];

export const columnsWithoutSensitiveDataLeftPart: AppTableProps<Child>['columns'] = [
    {
        title: childrenTexts.childrenListColumns.firstAndLastName,
        dataIndex: childFields.firstName,
        key: childFields.firstName,
        render: (_, child) => <ChildFirstAndLastNameDisplay {...child} />,
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: childrenTexts.childrenListColumns.sex,
        dataIndex: childFields.sex,
        key: childFields.sex,
        render: (sex: Child['sex']) => <ChildSexDisplay sex={sex} />,
        align: 'center',
        isVisibleAsExtendableInMobile: false,
    },
    {
        title: childrenTexts.childrenListColumns.group,
        dataIndex: childFields.groups,
        key: childFields.groups,
        render: (groups: Child['groups']) => (
            <ChildGroupDisplay
                childGroups={groups}
                textType="BodyMediumSemiBold"
                color={colors.grayscale.gray9}
            />
        ),
        isVisibleAsExtendableInMobile: true,
    },
];

export const columnsWithoutSensitiveData: AppTableProps<Child>['columns'] = [
    ...columnsWithoutSensitiveDataLeftPart,
    ...columnsWithoutSensitiveDataRightPart,
];

export const columnsWithSensitiveData: AppTableProps<Child>['columns'] = [
    ...columnsWithoutSensitiveDataLeftPart,
    {
        title: childrenTexts.childrenListColumns.parentName,
        dataIndex: childFields.parents,
        key: `${childFields.parents}/name`,
        render: (parents: Child['parents']) =>
            parents ? <ParentsNamesDisplay parents={parents} /> : EMPTY_VALUE_LABEL,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: childrenTexts.childrenListColumns.phoneNumber,
        dataIndex: childFields.parents,
        key: `${childFields.parents}/phoneNumber`,
        render: (parents: Child['parents']) =>
            parents ? <ParentsPhoneNumbersDisplay parents={parents} /> : EMPTY_VALUE_LABEL,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: childrenTexts.childrenListColumns.address,
        dataIndex: childFields.address,
        key: childFields.address,
        render: (address: Child['address']) => <AddressDisplay address={address} />,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: childrenTexts.childrenListColumns.bankAccount,
        dataIndex: childFields.parents,
        key: `${childFields.parents}/account`,
        render: (parents: Child['parents']) =>
            parents ? <ParentsAccountsCount parents={parents} /> : EMPTY_VALUE_LABEL,
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    ...columnsWithoutSensitiveDataRightPart,
];
