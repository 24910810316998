import { AxiosResponse } from 'axios';
import { RcFile } from 'antd/es/upload/interface';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { formFields } from 'consts/form/formFields';
import {
    AppFormItem,
    AppFormItemProps,
    AppUpload,
    AppUploadFile,
    AppUploadProps,
    AppUploadRequestOptions,
} from 'components/atoms';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { FileDirectory, AttachmentDTO, FileMetaDTO } from 'types';
import { commonTexts } from 'consts/text';
import { fileUploadErrorParser } from 'utils/errorHandlers/file/fileUploadErrorParser';
import { MAX_FILE_AMOUNT, MAX_FILE_SIZE } from 'consts/file/maxFileSize';
import { useState } from 'react';
import debounce from 'lodash/debounce';

export type AttachmentsUploadElementProps = AppFormItemProps &
    Required<Pick<AppUploadProps, 'accept'>> & {
        uploadDirectory: FileDirectory;
        optional?: boolean;
        appFormItemLabel?: string;
        uploadAttachmentFunction?: ({
            directory,
            file,
        }: {
            directory: FileDirectory;
            file: string | Blob | RcFile;
        }) => Promise<AxiosResponse<FileMetaDTO>>;
    };

export const AttachmentsUploadElement = ({
    uploadDirectory,
    appFormItemLabel,
    optional = true,
    className,
    uploadAttachmentFunction = StaffHTTPService.files.addFile,
    accept,
    ...props
}: AttachmentsUploadElementProps) => {
    const { displayError } = useSimpleNotification();
    const { setFieldValue, getFieldValue } = useFormInstance();
    const attachmentsFormData = useWatch<AttachmentDTO[] | undefined>(formFields.attachments);

    const [activeUploadFileRequestsCount, setActiveUploadFileRequestsCount] = useState(0);

    const { mutateAsync: uploadFile } = useAppMutation(uploadAttachmentFunction, {
        key: ['ADD_FILE'],
        onMutate: () =>
            setActiveUploadFileRequestsCount(
                (prevActiveUploadFileRequestsCount) => prevActiveUploadFileRequestsCount + 1,
            ),
        onSettled: () =>
            setActiveUploadFileRequestsCount(
                (prevActiveUploadFileRequestsCount) => prevActiveUploadFileRequestsCount - 1,
            ),
    });

    const handleUploadFile = async ({ file }: AppUploadRequestOptions) => {
        try {
            const { data: uploadFileResponseData } = await uploadFile({
                directory: uploadDirectory,
                file,
            });
            const prevAttachmentsData = getFieldValue(formFields.attachments) as
                | AttachmentDTO[]
                | undefined;
            const newAttachmentsData: AttachmentDTO[] = [
                ...(prevAttachmentsData || []),
                {
                    fileName: uploadFileResponseData.fileName,
                    fileUri: uploadFileResponseData.fileUri,
                },
            ];
            setFieldValue(formFields.attachments, newAttachmentsData);
        } catch (error) {
            const fileUploadError = fileUploadErrorParser(error);
            displayError(fileUploadError);
        }
    };

    const debouncedDisplayTooManyFilesError = debounce(() => {
        displayError(commonTexts.errorMessages.tooManyFiles);
    }, 300);

    const handleBeforeUpload = (fileToUpload: RcFile, fileListToUpload: RcFile[]) => {
        if (fileToUpload.size > MAX_FILE_SIZE) {
            displayError(commonTexts.errorMessages.tooLargeFile);
            return false;
        }
        const attachmentsData = getFieldValue(formFields.attachments) as
            | AttachmentDTO[]
            | undefined;
        const attachmentsCountAfterUpload = attachmentsData
            ? attachmentsData.length + fileListToUpload.length
            : fileListToUpload.length;
        if (attachmentsCountAfterUpload > MAX_FILE_AMOUNT) {
            debouncedDisplayTooManyFilesError();
            return false;
        }
        return true;
    };

    const handleRemoveFile = (fileToDelete: AppUploadFile) => {
        const newAttachmentsData = attachmentsFormData?.filter(
            (attachmentData) => attachmentData.fileUri !== fileToDelete.uid,
        );
        setFieldValue(formFields.attachments, newAttachmentsData);
    };

    const fileList: AppUploadFile[] | undefined = attachmentsFormData?.map(
        ({ fileName, fileUri }) => ({
            uid: fileUri,
            name: fileName,
            status: 'done',
        }),
    );

    return (
        <div className={className}>
            <AppFormItem
                label={appFormItemLabel || commonTexts.actionLabels.addDocument}
                name={formFields.attachments}
                optional={optional}
                marginBottom={0}
                hasOptionalText
                {...props}
            >
                <input type="hidden" />
            </AppFormItem>
            <AppUpload
                multiple
                accept={accept}
                fileList={fileList || []}
                beforeUpload={handleBeforeUpload}
                customRequest={handleUploadFile}
                onRemove={handleRemoveFile}
            >
                <AppUpload.Button loading={!!activeUploadFileRequestsCount} />
            </AppUpload>
        </div>
    );
};
