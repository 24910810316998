import styled, { useTheme } from 'styled-components';
import { Col, Row } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import { AppFlex, AppGoBackButton, AppTitle } from 'components/atoms';
import { subscriptionsTexts } from 'consts/text';
import { AddressData, CRMData, InstitutionData } from 'components/molecules';
import { InstitutionAddressDataIcon } from 'assets';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { SubscriptionInstitutionAndOwnerDTO } from 'types';

type InstitutionDetailsPageContentProps = {
    institutionAndOwnerData?: SubscriptionInstitutionAndOwnerDTO;
};

const StyledInstitutionData = styled(InstitutionData)`
    height: 100%;
`;

export const InstitutionDetailsPageContent = ({
    institutionAndOwnerData,
}: InstitutionDetailsPageContentProps) => {
    const theme = useTheme();
    const isMobile = useIsMobile();

    const mainContainerGutter: [Gutter, Gutter] = isMobile
        ? [0, theme.sizes.marginNormal]
        : [theme.sizes.marginNormal, 0];

    if (!institutionAndOwnerData) {
        return null;
    }

    const { institutionOwner, institution, subscriptionBasicData } = institutionAndOwnerData;
    const { firstName, lastName, phoneNumber } = institutionOwner;
    const {
        regon,
        rspo,
        nurseryRegistryNumber,
        name,
        publicInstitution,
        type,
        city,
        street,
        zipCode,
    } = institution;
    const { price, activeChildren, childLimit } = subscriptionBasicData;

    return (
        <>
            <AppGoBackButton />
            <AppTitle level={4} margin={0} marginTop={10} marginBottom={theme.sizes.marginLarge}>
                {subscriptionsTexts.details.heading}
            </AppTitle>
            <Row gutter={mainContainerGutter} justify="space-between">
                <Col xs={24} md={12}>
                    <AppFlex vertical gap={theme.sizes.marginNormal}>
                        <CRMData
                            amount={price}
                            childrenCount={activeChildren}
                            childrenLimit={childLimit}
                            firstName={firstName}
                            lastName={lastName}
                            phoneNumber={phoneNumber}
                        />
                        <AddressData
                            heading={subscriptionsTexts.details.address}
                            icon={<InstitutionAddressDataIcon />}
                            address={{ city, street, zipCode }}
                        />
                    </AppFlex>
                </Col>
                <Col xs={24} md={12}>
                    <StyledInstitutionData
                        regon={regon}
                        rspo={rspo || undefined}
                        nurseryRegisterNumber={nurseryRegistryNumber || undefined}
                        name={name}
                        publicInstitution={publicInstitution}
                        type={type}
                    />
                </Col>
            </Row>
        </>
    );
};
