import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { JournalId } from 'types';
import { parseDayjsDateIntoString } from 'utils/parsers/dateTime/parseDayjsDateIntoString';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type SpecialJournalTopicAddPageNetworkManageProps = {
    onAddTopicSuccess: () => void;
    journalId: JournalId;
    initialTopicDate?: string;
};

export const useSpecialJournalTopicAddPageNetworkManage = ({
    onAddTopicSuccess,
    journalId,
    initialTopicDate,
}: SpecialJournalTopicAddPageNetworkManageProps) => {
    const [selectedFormTopicDate, setSelectedFormTopicDate] = useState<string | undefined>(
        initialTopicDate,
    );

    const handleSelectFormTopicDate = (date: Dayjs | null) => {
        if (date) {
            const parsedDate = parseDayjsDateIntoString(date, 'YYYY-MM-DD');
            setSelectedFormTopicDate(parsedDate);
            return;
        }
        setSelectedFormTopicDate(undefined);
    };

    useEffect(() => {
        if (initialTopicDate) {
            setSelectedFormTopicDate(initialTopicDate);
        }
    }, [initialTopicDate]);

    const filters = {
        date: selectedFormTopicDate || '',
        journalId,
    };

    const {
        data: specialLessonsData,
        isFetching: isSpecialLessonsDataFetching,
        isError: isSpecialLessonsDataError,
    } = useAppQuery(
        'LESSONS_WITHOUT_TOPIC',
        [journalId, selectedFormTopicDate],
        () => StaffHTTPService.employeeSchedule.getLessonsWithoutTopic(filters),
        {
            enabled: !!selectedFormTopicDate,
            staleTime: FIVE_MINS_IN_MILLIS,
        },
    );

    const {
        mutate: addSpecialTopic,
        error: addSpecialTopicError,
        isLoading: isAddSpecialTopicLoading,
    } = useAppMutation(StaffHTTPService.specialTopics.addSpecialTopic, {
        key: ['ADD_SPECIAL_TOPIC'],
        onSuccess: onAddTopicSuccess,
        invalidateQueryKeys: [
            ['SPECIAL_TOPICS', journalId],
            ['LESSONS_WITHOUT_TOPIC', journalId, selectedFormTopicDate],
            ['SPECIAL_ATTENDANCE', journalId],
            ['SPECIAL_DAILY_ATTENDANCE', journalId],
            ['SPECIAL_MONTHLY_ATTENDANCE', journalId],
            ['EMPLOYEE_SCHEDULE'],
            ['UNASSIGNED_CHILDREN'],
        ],
    });

    return {
        addSpecialTopic,
        addSpecialTopicError,
        isAddSpecialTopicLoading,
        specialLessonsData,
        isSpecialLessonsDataFetching,
        isSpecialLessonsDataError,
        handleSelectFormTopicDate,
    };
};
