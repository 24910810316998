import { Permission, PermissionAction, PermissionName } from 'types';

const handleAddSpecialJournalAddOutOfTimetablePermission = (permissionsResult: Permission[]) => {
    const specialJournalsTopicsPermission = permissionsResult.find(
        ({ name }) => name === 'SPECIAL_JOURNALS_TOPICS',
    );
    if (!specialJournalsTopicsPermission) {
        permissionsResult.push({
            name: 'SPECIAL_JOURNALS_TOPICS',
            actions: ['VIEW', 'EDIT_AND_ADD'],
        });
        return;
    }
    const isSpecialJournalsTopicsPermissionAddAndEditActionIncluded =
        specialJournalsTopicsPermission.actions?.includes('EDIT_AND_ADD');
    if (!isSpecialJournalsTopicsPermissionAddAndEditActionIncluded) {
        const permissionToUpdate = permissionsResult.find(
            (permission) => permission.name === 'SPECIAL_JOURNALS_TOPICS',
        );
        if (permissionToUpdate) {
            permissionToUpdate.actions = ['VIEW', 'EDIT_AND_ADD'];
        }
    }
};

const handleAddChildrenListAddEditPermission = (permissionsResult: Permission[]) => {
    const childrenListViewSensitivePermission = permissionsResult.find(
        ({ name }) => name === 'CHILDREN_LIST_VIEW_SENSITIVE',
    );
    if (!childrenListViewSensitivePermission) {
        permissionsResult.push({
            name: 'CHILDREN_LIST_VIEW_SENSITIVE',
            actions: [],
        });
    }
};

const handleRemoveChildrenListViewSensitivePermission = (permissionsResult: Permission[]) => {
    const childrenListPermissionIndex = permissionsResult.findIndex(
        ({ name }) => name === 'CHILDREN_LIST',
    );
    if (!childrenListPermissionIndex) {
        return;
    }
    const hasAddEditAction =
        permissionsResult[childrenListPermissionIndex].actions?.includes('EDIT_AND_ADD');
    if (hasAddEditAction) {
        permissionsResult.splice(childrenListPermissionIndex, 1, {
            name: 'CHILDREN_LIST',
            actions: ['VIEW'],
        });
    }
};

export const addDependentPermissions: (
    currentPermissionName: PermissionName,
    permissionAction: PermissionAction | null,
    permissionsResult: Permission[],
) => void = (currentPermissionName, permissionAction, permissionsResult) => {
    if (currentPermissionName === 'SPECIAL_JOURNALS_ADD_OUT_OF_TIMETABLE') {
        handleAddSpecialJournalAddOutOfTimetablePermission(permissionsResult);
    }
    if (currentPermissionName === 'CHILDREN_LIST' && permissionAction === 'EDIT_AND_ADD') {
        handleAddChildrenListAddEditPermission(permissionsResult);
    }
};

export const removeDependentPermissions: (
    currentPermissionName: PermissionName,
    permissionAction: PermissionAction | null,
    permissionsResult: Permission[],
) => void = (currentPermissionName, _permissionAction, permissionsResult) => {
    if (currentPermissionName === 'CHILDREN_LIST_VIEW_SENSITIVE') {
        handleRemoveChildrenListViewSensitivePermission(permissionsResult);
    }
};
