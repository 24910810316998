import { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { AppModal, AppModalProps, ChildrenWithParentsListItem } from 'components/molecules';
import { commonTexts } from 'consts/text';
import { UserCheckBottomIcon } from 'assets';
import {
    ChildId,
    ChildWithParents,
    ChildWithParentsIds,
    JournalChildWithParentsBasicInfoDto,
    ParentId,
} from 'types';
import { countChildrenParentsAmount } from 'utils/countChildrenParentsAmount';
import { parseJournalChildDTOIntoChildWithParentsIds } from 'utils/parsers/journal/parseJournalChildDTOIntoChildWithParentsIds';
import { StyledButton, StyledCheckbox, StyledList } from './ChildrenWithParentsPickerModal.styled';
import {
    handleCheckParentOption,
    handleUncheckParentOption,
    countSelectedParentsAmount,
} from './ChildrenWithParentsPickerModal.utils';

export type ChildrenWithParentsPickerModalProps = Pick<
    AppModalProps,
    'open' | 'onCancel' | 'isLoading'
> & {
    initialSelectedChildrenWithParentsIds: ChildWithParentsIds[];
    childrenData: JournalChildWithParentsBasicInfoDto[];
    onSave: (selectedChildrenWithParentsIds: ChildWithParentsIds[]) => void;
};

export const ChildrenWithParentsPickerModal = ({
    initialSelectedChildrenWithParentsIds,
    childrenData,
    isLoading,
    open,
    onSave,
    onCancel,
}: ChildrenWithParentsPickerModalProps) => {
    const [selectedChildrenWithParentsIds, setSelectedChildrenWithParentsIds] = useState<
        ChildWithParentsIds[]
    >([]);
    const theme = useTheme();

    const isNonEmptyList = !!childrenData.length;

    useEffect(
        () => setSelectedChildrenWithParentsIds(initialSelectedChildrenWithParentsIds),
        [initialSelectedChildrenWithParentsIds],
    );

    const handleChangeSelectAllParentsCheckbox = (isChecked: boolean) => {
        if (!isChecked) {
            setSelectedChildrenWithParentsIds([]);
            return;
        }
        const allChildrenWithParentsIds: ChildWithParentsIds[] = childrenData.map(
            parseJournalChildDTOIntoChildWithParentsIds,
        );
        setSelectedChildrenWithParentsIds(allChildrenWithParentsIds);
    };

    const handleChangeParentOption = (isChecked: boolean, parentId: ParentId, childId: ChildId) => {
        setSelectedChildrenWithParentsIds((prevSelectedChildrenWithParentsIds) => {
            const foundGroup = prevSelectedChildrenWithParentsIds.find(
                (selectedChildrenWithParentsIdsItem) =>
                    selectedChildrenWithParentsIdsItem.childId === childId,
            );
            if (isChecked) {
                return handleCheckParentOption(
                    parentId,
                    childId,
                    prevSelectedChildrenWithParentsIds,
                    foundGroup,
                );
            }
            return handleUncheckParentOption(
                parentId,
                prevSelectedChildrenWithParentsIds,
                foundGroup,
            );
        });
    };

    const isCheckedSelectAllParentsCheckbox = useMemo(() => {
        const selectedParentsAmount = countSelectedParentsAmount(selectedChildrenWithParentsIds);
        const journalChildrenParentsAmount = countChildrenParentsAmount(childrenData);
        return selectedParentsAmount === journalChildrenParentsAmount;
    }, [childrenData, selectedChildrenWithParentsIds]);

    const renderListItem = (childWithParents: ChildWithParents) => (
        <ChildrenWithParentsListItem
            childWithParents={childWithParents}
            selectedChildrenWithParentsIds={selectedChildrenWithParentsIds}
            onChangeOption={handleChangeParentOption}
        />
    );

    const saveButton = (
        <StyledButton
            type="primary"
            disabled={isLoading || !isNonEmptyList}
            icon={<IconDeviceFloppy />}
            onClick={() => onSave(selectedChildrenWithParentsIds)}
        >
            {commonTexts.actionLabels.save}
        </StyledButton>
    );

    return (
        <AppModal
            open={open}
            icon={<UserCheckBottomIcon />}
            title={commonTexts.forms.selectParent}
            footer={saveButton}
            width={theme.sizes.modalWidth.large}
            onCancel={onCancel}
        >
            {isNonEmptyList && (
                <StyledCheckbox
                    checked={isCheckedSelectAllParentsCheckbox}
                    onChange={(checkboxChangeEvent) =>
                        handleChangeSelectAllParentsCheckbox(checkboxChangeEvent.target.checked)
                    }
                >
                    {commonTexts.forms.selectAll}
                </StyledCheckbox>
            )}
            <StyledList
                loading={isLoading}
                bordered
                dataSource={childrenData}
                renderItem={renderListItem}
            />
        </AppModal>
    );
};
