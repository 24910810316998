import { AbsenceFormFields, AbsenceId } from 'types';
import { AbsenceAddEditModal } from 'components/templates';
import { useAppMutation } from 'services/reactQuery/useAppMutation';
import { timetableTexts } from 'consts/text';
import { appPaths } from 'consts/paths/paths';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { useParams } from 'react-router-dom';
import { useEditForm } from 'hooks/useEditForm/useEditForm';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import { parseAbsenceDTOIntoAbsenceFormFields } from 'utils/parsers/absence/parseAbsenceDTOIntoAbsenceFormFields';
import { parseAbsenceFormFieldsIntoUpdateAbsenceDTO } from 'utils/parsers/absence/parseAbsenceFormFieldsIntoUpdateAbsenceDTO';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';

export const AbsenceEditPage = () => {
    const { displaySuccess } = useSimpleNotification();
    const { statefulNavigate } = useNavigateWithState();
    const { form, formInitialData, setFormData } = useEditForm<AbsenceFormFields>();
    const { absenceId } = useParams<{ absenceId: AbsenceId }>();
    const absenceIdParam = absenceId as AbsenceId;

    const onEditAbsenceSuccess = () => {
        displaySuccess(
            timetableTexts.absence.notyficationMessages.successfullyEditedAbsenceMessage,
        );
        statefulNavigate(appPaths.app.timetable.employeeSchedule.base, {
            replace: true,
        });
    };

    const { error: absenceDataError } = useAppQuery(
        'ABSENCE',
        [absenceIdParam],
        () => StaffHTTPService.employeeSchedule.getAbsence(absenceIdParam),
        {
            enabled: !!absenceIdParam,
            onSuccess: (data) => {
                setFormData(parseAbsenceDTOIntoAbsenceFormFields(data));
            },
        },
    );

    const {
        mutate: editAbsence,
        error: editAbsenceError,
        isLoading: isEditAbsenceLoading,
    } = useAppMutation(StaffHTTPService.employeeSchedule.editAbsence, {
        key: ['EDIT_ABSENCE'],
        onSuccess: onEditAbsenceSuccess,
        // TODO: add key from Rejestracja czasu pracy oraz Zestawienie czasu pracy when be ready backend and frontend
        invalidateQueryKeys: [
            ['EMPLOYEE_SCHEDULE'],
            ['ABSENCE', absenceIdParam],
            ['SPECIAL_TOPICS'],
        ],
    });

    const onEditAbsenceFinish = (absenceData: AbsenceFormFields) =>
        editAbsence({
            id: absenceIdParam,
            editAbsenceDTO: parseAbsenceFormFieldsIntoUpdateAbsenceDTO(absenceData),
        });

    return (
        <AbsenceAddEditModal
            onFinish={onEditAbsenceFinish}
            mode="edit"
            axiosError={editAbsenceError || absenceDataError}
            isLoading={isEditAbsenceLoading}
            initialValues={formInitialData}
            form={form}
        />
    );
};
