import {
    CommonFormProps,
    CostAllocationFormFields,
    FormModeDependedTexts,
    PriceDeductionType,
} from 'types';
import { commonTexts, settlementTexts } from 'consts/text';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { AppForm, AppFormProps } from 'components/organisms';
import { AxiosError } from 'axios';
import {
    BankAccountNumberInputElement,
    CityInputElement,
    FormNavButtons,
    NipInputWithFetchElement,
    SimpleInputElement,
    StreetAddressInputElement,
    ZipCodeInputElement,
} from 'components/molecules';
import { AppFormItem, AppFormItemGroup, AppRadioButton, AppRadioGroup } from 'components/atoms';
import { formFields } from 'consts/form/formFields';
import { formElementRequiredText } from 'consts/errors/forms';
import { CostAllocationTargetPicker } from 'components/organisms/FormItems/CostAllocationTypePicker/CostAllocationTargetPicker';
import { CurrencyInputElement } from 'components/molecules/FormElements/CurrencyInputElement/CurrencyInputElement';
import { PercentageInputElement } from 'components/molecules/FormElements/PercentageInputElement/PercentageInputElement';
import { useWatch } from 'antd/es/form/Form';
import { max300LengthRule } from 'consts/rules/textRules';
import { parseGUSInformationDTOIntoCostAllocationFormFields } from 'utils/parsers/gus/parseGUSInformationDTOIntoCostAllocationFormFields';

export type CostAllocationAddEditFormProps = Omit<
    AppFormProps<CostAllocationFormFields>,
    'width' | 'title' | 'name' | 'onFinish' | 'error' | 'withGoBack'
> &
    Pick<CommonFormProps, 'mode'> & {
        axiosError?: AxiosError | null;
        isLoading?: boolean;
        onFinish: (costAllocationData: CostAllocationFormFields) => void;
    };

const addModeTexts: FormModeDependedTexts = {
    name: 'costAllocationAdd',
    title: settlementTexts.children.costAllocation.addTitle,
    buttonText: commonTexts.actionLabels.add,
};

const editModeTexts: FormModeDependedTexts = {
    name: 'costAllocationEdit',
    title: settlementTexts.children.costAllocation.editTitle,
    buttonText: commonTexts.actionLabels.save,
    buttonIcon: <IconDeviceFloppy size={20} />,
};

export const ChildAddEditCostAllocationForm = ({
    mode,
    initialValues,
    axiosError,
    form,
    ...props
}: CostAllocationAddEditFormProps) => {
    const isAddMode = mode === 'add';
    const { name, title, buttonText, buttonIcon } = isAddMode ? addModeTexts : editModeTexts;

    const watchCostAllocationType = useWatch(formFields.type, form);

    const costAllocationAddEditError = axiosError ? commonTexts.errorMessages.unknown : '';

    return (
        <AppForm<CostAllocationFormFields>
            form={form}
            title={title}
            name={name}
            error={costAllocationAddEditError}
            withGoBack
            {...props}
        >
            <AppFormItemGroup title={settlementTexts.children.costAllocation.organisationData}>
                <NipInputWithFetchElement
                    parseInstitutionGusDataIntoFormData={
                        parseGUSInformationDTOIntoCostAllocationFormFields
                    }
                />
                <SimpleInputElement
                    name={formFields.foundationName}
                    label={commonTexts.dataLabels.nameOrganization}
                    rules={[max300LengthRule]}
                />
                <StreetAddressInputElement />
                <AppFormItem justifyContent="space-between" doubleItem>
                    <ZipCodeInputElement />
                    <CityInputElement />
                </AppFormItem>
                <BankAccountNumberInputElement optional />
            </AppFormItemGroup>
            <AppFormItemGroup title={settlementTexts.children.costAllocation.costs}>
                <CostAllocationTargetPicker />
                <AppFormItem
                    label={settlementTexts.children.costAllocation.costAllocationType}
                    name={formFields.type}
                    rules={[{ required: true, message: formElementRequiredText }]}
                >
                    <AppRadioGroup
                        onChange={() =>
                            form?.setFieldsValue({ specificAmount: null, percentageAmount: null })
                        }
                    >
                        <AppRadioButton value={PriceDeductionType.PERCENTAGE}>
                            {settlementTexts.children.costAllocation.percent}
                        </AppRadioButton>
                        <AppRadioButton value={PriceDeductionType.SPECIFIC_AMOUNT}>
                            {settlementTexts.children.costAllocation.exact}
                        </AppRadioButton>
                    </AppRadioGroup>
                </AppFormItem>
                {watchCostAllocationType === PriceDeductionType.SPECIFIC_AMOUNT ? (
                    <CurrencyInputElement name={formFields.specificAmount} label="Kwota" />
                ) : (
                    <PercentageInputElement name={formFields.percentageAmount} label="Wysokość" />
                )}
            </AppFormItemGroup>
            <FormNavButtons
                formValuesBeforeChanges={initialValues}
                buttonText={buttonText}
                leftIcon={buttonIcon}
                withPlus={isAddMode}
            />
        </AppForm>
    );
};
