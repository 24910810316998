import { AppCheckbox } from 'components/atoms';
import { useAtom } from 'jotai';
import { Permission, PermissionAction } from 'types';
import {
    addDependentPermissions,
    removeDependentPermissions,
} from 'components/templates/settings/rolesAndPermissions/RolesAndPermissionsAddEditForm/addDependentPermissions.utils';
import { commonTexts, settingsTexts } from 'consts/text';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { permissionsEditAtom } from '../../roleAndPermissionState';
import { Container } from './PermissionViewEditAdd.styled';

export type PermissionViewEditAddProps = {
    permissionName: Permission['name'];
    disabled?: boolean;
};

const availableOptions: PermissionAction[] = ['VIEW', 'EDIT_AND_ADD'];

export const PermissionViewEditAdd = ({ permissionName, disabled }: PermissionViewEditAddProps) => {
    const [permissions, setPermissions] = useAtom(permissionsEditAtom);
    const permissionActions = permissions.find(({ name }) => name === permissionName)?.actions;
    const { displayError } = useSimpleNotification();

    const onPermissionCheckboxPress: (
        optionName: Permission['name'],
        permission: PermissionAction,
        value: boolean,
    ) => void = (currentPermissionName, action, checked) => {
        setPermissions((prevPermissions) => {
            const nextState = [...prevPermissions];
            const permissionIndex = nextState.findIndex(
                (currentPermission) => currentPermission.name === currentPermissionName,
            );
            const isPermissionMissing = permissionIndex === -1;
            const isPermissionPresentButGetNewPermission = permissionIndex >= 0 && checked;
            const isPermissionPresentButPermissionWasDeleted =
                nextState[permissionIndex]?.actions?.includes?.(action) && !checked;

            if (isPermissionMissing) {
                if (!checked) {
                    console.error(
                        'Unexpected case, permission does not exist and has been disabled again',
                    );
                    displayError(commonTexts.errorMessages.actionExecution);
                    return prevPermissions;
                }
                const newPermissionActions: Permission['actions'] =
                    action === 'EDIT_AND_ADD' ? ['EDIT_AND_ADD', 'VIEW'] : [action];

                nextState.push({
                    name: currentPermissionName,
                    actions: newPermissionActions,
                });
                addDependentPermissions(currentPermissionName, action, nextState);
            } else if (isPermissionPresentButGetNewPermission) {
                nextState[permissionIndex] = {
                    name: currentPermissionName,
                    actions: nextState[permissionIndex]?.actions?.concat(action) || [action],
                };
                addDependentPermissions(currentPermissionName, action, nextState);
            } else if (isPermissionPresentButPermissionWasDeleted) {
                const updatedActions = nextState[permissionIndex].actions?.filter(
                    (currentAction) => currentAction !== action,
                );

                if (!updatedActions?.length || !updatedActions.includes('VIEW')) {
                    nextState.splice(permissionIndex, 1);
                    return nextState;
                }

                nextState[permissionIndex] = {
                    name: currentPermissionName,
                    actions:
                        nextState[permissionIndex].actions?.filter(
                            (currentAction) => currentAction !== action,
                        ) || [],
                };
                removeDependentPermissions(currentPermissionName, action, nextState);
            }

            return nextState;
        });
    };

    const checkboxes = availableOptions.map((option) => (
        <AppCheckbox
            marginRight={12}
            bordered
            checked={permissionActions?.includes(option) || false}
            key={option}
            value={option}
            onChange={({ target }) =>
                onPermissionCheckboxPress(permissionName, option, target.checked)
            }
            disabled={disabled}
        >
            {settingsTexts.rolesAndPermissions.permissionSwitchLabels[option]}
        </AppCheckbox>
    ));

    return <Container>{checkboxes}</Container>;
};
