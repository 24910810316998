import { AppTableProps } from 'components/organisms';
import { QualificationDTO } from 'types';
import { childrenTexts, commonTexts, groupsTexts } from 'consts/text';
import { qualificationFields } from 'consts/qualifications/qualificationFields';
import { getFullName } from 'utils/getFullName';
import { ChildSexDisplay, ContractValidDisplay, EmployeeGroupsDisplay } from 'components/molecules';

const columnsWithoutSensitiveDataLeftPart: AppTableProps<QualificationDTO>['columns'] = [
    {
        title: childrenTexts.childrenListColumns.firstAndLastName,
        dataIndex: qualificationFields.firstName,
        key: qualificationFields.firstName,
        render: (_, child) =>
            getFullName(child[qualificationFields.firstName], child[qualificationFields.lastName]),
    },

    {
        title: childrenTexts.childDetails.personalData.sex,
        dataIndex: qualificationFields.sex,
        key: qualificationFields.sex,
        render: (sex) => <ChildSexDisplay sex={sex} />,
        align: 'center',
    },
    {
        title: groupsTexts.groups,
        dataIndex: qualificationFields.groups,
        key: qualificationFields.groups,
        render: (groups) => <EmployeeGroupsDisplay groups={groups} />,
        isVisibleAsExtendableInMobile: true,
    },
];

const columnsWithoutSensitiveDataRightPart: AppTableProps<QualificationDTO>['columns'] = [
    {
        title: commonTexts.dataLabels.attachment,
        dataIndex: qualificationFields.hasAttachments,
        key: qualificationFields.hasAttachments,
        render: (hasAttachments) => (
            <ContractValidDisplay contractValid={hasAttachments} dataTestId="attachments" />
        ),
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: commonTexts.dataLabels.filled,
        dataIndex: qualificationFields.filled,
        key: qualificationFields.filled,
        render: (filled) => <ContractValidDisplay contractValid={filled} dataTestId="dataLabel" />,
        align: 'center',
        isVisibleAsExtendableInMobile: true,
    },
];

export const columnsWithoutSensitiveData: AppTableProps<QualificationDTO>['columns'] = [
    ...columnsWithoutSensitiveDataLeftPart,
    ...columnsWithoutSensitiveDataRightPart,
];

export const columnsWithSensitiveData: AppTableProps<QualificationDTO>['columns'] = [
    ...columnsWithoutSensitiveDataLeftPart,
    {
        title: childrenTexts.childDetails.personalData.pesel,
        dataIndex: qualificationFields.pesel,
        key: qualificationFields.pesel,
        isVisibleAsExtendableInMobile: true,
    },
    {
        title: commonTexts.dataLabels.contact,
        dataIndex: qualificationFields.contact,
        key: qualificationFields.contact,
        isVisibleAsExtendableInMobile: true,
    },
    ...columnsWithoutSensitiveDataRightPart,
];
